import React from "react";
import Sidebar from "./Sidebar";
import "./Dashboard.css";
import DashboardHeader from "./DashboardHeader";
import DashboardContent from "./DashboardContent";

const Dashboard = () => {
  return (
    <div>
      <Sidebar />
      <DashboardHeader />
      <DashboardContent />
    </div>
  );
};

export default Dashboard;
