import React, { useState } from "react";
import "./Income.css";
import cardimage from "./assets/register.png";
import { IncomeData } from "./IncomeCard";

function Income() {
  const [items, setitems] = useState(IncomeData);
  return (
    <div>
      <div className="about section " id="income">
        <div className="about__container container">
          <h2 className="about__head">
            How WELOTA let you generate <br /> <span>passive income?</span>{" "}
          </h2>
          <p style={{ color: "#193B68" }}>
            Passive income is the new strategy to earn more with minimal
            efforts.
          </p>

          <div className="income-cards">
            {items.map((item) => (
              <div
                className="card1"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <img src={require(`./assets/${item.Image}.png`)} alt="" />
                <div className="card-head">
                  <h3>{item.head}</h3>
                </div>
                <div className="card-para">
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Income;
