import React, { useState, useEffect } from "react";
import Logo from "../Navbar/assets/welotaLogo.png";
// import { BrowserRouter as Router, Link } from "react-router-dom";
import "./Navbar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import { Link } from "react-scroll";

function Navbar({ onRegisterNowClick }) {
  // modal start
  const handleRegisterNowClick = () => {
    onRegisterNowClick();
    // setShowCloseIcon(!showCloseIcon);
  };
  //  modal end
  // mobilemodal
  const handleRegisterNowClicks = () => {
    onRegisterNowClick();
    setShowCloseIcon(!showCloseIcon);
  };
  // mobilemodal

  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleClick = () => {
    setShowCloseIcon(!showCloseIcon);
  };

  const MobileNavList = () => (
    <>
      <div className="mobile-navbar-container">
        <div className="nav-list">
          <ul className="nav-ul">
            <li className="nav__item">
              <Link
                // activeClass="active-link"
                to="about"
                spy={true}
                smooth={true}
                offset={-70} // Adjust the offset as needed
                className="nav__link"
                onClick={handleClick}
              >
                About us
              </Link>
            </li>
            <li className="nav__item">
              <Link
                // activeClass="active-link"
                to="income"
                spy={true}
                smooth={true}
                offset={-70} // Adjust the offset as needed
                className="nav__link"
                onClick={handleClick}
              >
                How its work
              </Link>
            </li>
            <Link
              // activeClass="active-link"
              to="business"
              spy={true}
              smooth={true}
              offset={-70} // Adjust the offset as needed
              className="nav__link"
              onClick={handleClick}
            >
              Business
            </Link>
            <li className="nav__item">
              <Link
                // activeClass="active-link"
                to="franchise"
                spy={true}
                smooth={true}
                offset={-70} // Adjust the offset as needed
                className="nav__link"
                onClick={handleClick}
              >
                Franchise
              </Link>
            </li>
            <li className="nav__item">
              <Link
                // activeClass="active-link"
                to="footer"
                spy={true}
                smooth={true}
                offset={-70} // Adjust the offset as needed
                className="nav__link"
                onClick={handleClick}
              >
                Contact us
              </Link>
            </li>
          </ul>
        </div>
        <div className="contact_us">
          <button className="contactus" onClick={handleRegisterNowClicks}>
            Register franchies
          </button>
        </div>
      </div>
    </>
  );

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <header className={`header ${scrolled ? "scrolled" : ""}`} id="header">
        <nav className="nav container">
          <div className="navbar">
            <div className="logo_goldzin">
              <img src={Logo} alt="logo" />
            </div>
            <div className="hamburger-container">
              {showCloseIcon ? (
                <IoClose
                  fill="#fff"
                  size={30}
                  style={{ cursor: "pointer" }}
                  onClick={handleClick}
                />
              ) : (
                <GiHamburgerMenu
                  fill="#fff"
                  size={25}
                  style={{ cursor: "pointer" }}
                  onClick={handleClick}
                />
              )}
            </div>
            {showCloseIcon ? (
              <MobileNavList />
            ) : (
              <div className="parent_navlist">
                <div className="nav__menu_mobile nav__menu">
                  <ul className="nav__list">
                    <li className="nav__item">
                      <Link
                        // activeClass="active-link"
                        to="about"
                        spy={true}
                        smooth={true}
                        offset={-70} // Adjust the offset as needed
                        className="nav__link"
                      >
                        About us
                      </Link>
                    </li>
                    <li className="nav__item">
                      <Link
                        // activeClass="active-link"
                        to="income"
                        spy={true}
                        smooth={true}
                        offset={-70} // Adjust the offset as needed
                        className="nav__link"
                      >
                        How its work
                      </Link>
                    </li>
                    <Link
                      // activeClass="active-link"
                      to="business"
                      spy={true}
                      smooth={true}
                      offset={-70} // Adjust the offset as needed
                      className="nav__link"
                    >
                      Business
                    </Link>
                    <li className="nav__item">
                      <Link
                        // activeClass="active-link"
                        to="franchise"
                        spy={true}
                        smooth={true}
                        offset={-70} // Adjust the offset as needed
                        className="nav__link"
                      >
                        Franchise
                      </Link>
                    </li>
                    <li className="nav__item">
                      <Link
                        // activeClass="active-link"
                        to="footer"
                        spy={true}
                        smooth={true}
                        offset={-70} // Adjust the offset as needed
                        className="nav__link"
                      >
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="contact_us">
                  <button
                    className="contactus"
                    onClick={handleRegisterNowClick}
                  >
                    Register Franchise
                  </button>
                </div>
              </div>
            )}
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Navbar;
