import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Dashboard/Sidebar";
import DashboardHeader from "../Dashboard/DashboardHeader";
import "./Products.css";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import CreateProductModal from "../CreateProduct/CreateProductModal";
import EditProductModal from "./EditProductModal";
import DeleteProductModal from "./DeleteProductModal";
import axios from "axios";
import * as Urls from "../../Urls";
import { Loader } from "../../Components/Loader/Loader";
import Pagination from "../../Components/Pagination/Pagination";

const Products = () => {
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const location = useLocation();
  const applyPaginate = location?.state?.data?.applyPagination;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productList, setProductList] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProductList, setFilteredProductList] = useState([]);
  const [productId, setProductId] = useState(null);
  const [productName, setProductName] = useState("");
  const [productCategory, setProductCategory] = useState();
  const [productDescription, setProductDescription] = useState();
  const [quantity, setQuantity] = useState();
  const [price, setPrice] = useState();
  const [productImage, setProductImage] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedProductList, setPaginatedProductList] = useState([]);
  const [expandedDescription, setExpandedDescription] = useState(null);

  const toggleDescriptionExpansion = (productId) => {
    setExpandedDescription(productId);
  };

  useEffect(() => {
    fetchProductList();
  }, []);

  useEffect(() => {
    fetchPaginatedProductList();
  }, [currentPage]);

  // useEffect(() => {

  // }, [input])

  console.log("applyPaginate--->", applyPaginate);

  useEffect(() => {
    if (searchQuery) {
      const filteredProducts = productList.filter((product) =>
        product.product_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredProductList(filteredProducts);
    } else {
      setFilteredProductList(paginatedProductList);
    }
  }, [searchQuery, productList, paginatedProductList, currentPage]);

  const fetchPaginatedProductList = async () => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.get(
        Urls.getProductList + "?page=" + currentPage,
        config
      );
      if (response.data.results.data.length > 0) {
        setPaginatedProductList(response.data.results.data);
        setFilteredProductList(response.data.results.data);
      } else {
        navigate("/Createproduct");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    window.location.href = "/login";
    window.localStorage.clear();
  };

  const fetchProductList = async () => {
    try {
      // setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.get(Urls.getProductList, config);
      console.log(response.data.results.data.length);
      setTotalPages(response?.data?.results?.total_pages);
      if (currentPage > response.data.results?.total_pages) {
        setCurrentPage(response.data.results.total_pages);
      }
      if (response.data.results.data.length > 0) {
        setProductList(response.data.results.data);
      } else {
        navigate("/Createproduct");
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.message === "Invalid Token") {
        handleNavigate();
      }
    } finally {
      setIsLoading(false);
    }
  };

  console.log("productList---->", productList);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const editcloseModal = () => {
    setEditModalOpen(false);
  };
  const showEditModal = (
    product_id,
    product_name,
    product_image,
    product_description,
    product_category_id,
    quantity,
    price
  ) => {
    setProductId(product_id);
    setProductName(product_name);
    setProductCategory(product_category_id);
    setProductDescription(product_description);
    setQuantity(quantity);
    setPrice(price);
    setProductImage(product_image);
    setEditModalOpen(true);
  };

  const showDeleteModal = (product_id, product_name) => {
    setProductId(product_id);
    setProductName(product_name);
    setDeleteModalOpen(true);
  };
  const deletecloseModal = () => {
    setDeleteModalOpen(false);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  if (isLoading) {
    return (
      <div className="loading-spinner">
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <Sidebar />
      <DashboardHeader />

      <div className="products_main">
        <div className="products_parent">
          <div className="products_subhead">
            <div className="left-searchbox">
              <input
                type="text"
                placeholder="Search Product"
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
            <div className="right_p_buttons">
              <button style={{ display: "none" }}></button>
              <button className="create_product_btn" onClick={showModal}>
                Create Product
              </button>
            </div>
          </div>
          <div className="product_list_table">
            <table
              style={{ width: "100%", textAlign: "left", colorL: "#193B68" }}
            >
              <thead>
                <tr>
                  <th className="table-head">Name & description</th>
                  <th className="table-head">Category</th>
                  <th className="table-head">Quantity</th>
                  <th className="table-head">Price</th>
                  <th className="table-head">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredProductList.map((product) => (
                  <tr key={product.id}>
                    <td className="table-datas">
                      <div className="product_desc_img">
                        <div className="p___image">
                          <img
                            src={product.product_image}
                            alt=""
                            style={{ height: "100px", width: "100px" }}
                          />
                        </div>
                        <div className="p___description">
                          <p className="product_____name">
                            {product.product_name}
                          </p>
                          <p>
                            {expandedDescription === product.id ||
                            product.product_description.length <= 100 ? (
                              product.product_description
                            ) : (
                              <>
                                {product.product_description.slice(0, 100)}
                                <span
                                  style={{ cursor: "pointer", color: "blue" }}
                                  onClick={() =>
                                    toggleDescriptionExpansion(product.id)
                                  }
                                >
                                  {" "}
                                  ...
                                </span>
                              </>
                            )}
                            {expandedDescription === product.id && (
                              <span
                                style={{
                                  cursor: "pointer",
                                  color: "gray",
                                  marginLeft: "10px",
                                }}
                                onClick={() => toggleDescriptionExpansion(null)}
                              >
                                less
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="table-datas">
                      {product.product_category_name}
                    </td>
                    <td className="table-datas">
                      {product.balance_quantity} Nos
                    </td>
                    <td className="table-datas" style={{ fontWeight: "600" }}>
                      ₹ {product.price.toFixed(2)}
                    </td>

                    <td className="">
                      <div className="actions__icons">
                        <BiEdit
                          className="edited"
                          onClick={() => {
                            showEditModal(
                              product.id,
                              product.product_name,
                              product.product_image,
                              product.product_description,
                              product.product_category_id,
                              product.balance_quantity,
                              product.price
                            );
                          }}
                        />
                        <RiDeleteBin6Line
                          className="deleted"
                          onClick={() => {
                            showDeleteModal(product.id, product.product_name);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {!searchQuery && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
      <CreateProductModal
        isOpen={isModalOpen}
        onClose={closeModal}
        fetchProductList={fetchProductList}
        fetchPaginatedProductList={fetchPaginatedProductList}
      />
      <EditProductModal
        isOpen={editModalOpen}
        onClose={editcloseModal}
        productId={productId}
        productName={productName}
        productCategory={productCategory}
        productDescription={productDescription}
        quantity={quantity}
        price={price}
        productImage={productImage}
        fetchAllProductList={fetchProductList}
        fetchProductList={fetchPaginatedProductList}
      />
      <DeleteProductModal
        isOpen={deleteModalOpen}
        onClose={deletecloseModal}
        productId={productId}
        productName={productName}
        fetchPaginatedProductList={fetchPaginatedProductList}
        fetchProductList={fetchProductList}
      />
    </div>
  );
};

export default Products;
