export const IncomeData = [
  {
    Image: "register",
    head: "Register as franchise business",
    description:
      "Initially, You have to register with us to become an official franchise partner by giving your details. This will be the heads up for your journey",
  },
  {
    Image: "payment",
    head: "Complete payment",
    description:
      "After a minimal investment done, you will be getting an app designed for you to add your referrals and get insights of your journey as well.",
  },
  {
    Image: "approve",
    head: "Get Approval",
    description:
      "After the payment, the approval is to be done from the parent team. Later you will be able to add members from your ward and start earning simultaneously.",
  },
];
