import React, { useState, useEffect, useRef } from "react";
import { usePagination } from "../../AuthContext";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Loader } from "../../Components/Loader/Loader";
import axios from "axios";
import * as Urls from "../../Urls";
import { useNavigate, useLocation } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import refunded from "./assets/refunded.png";
import WarningModal from "./WarningModal";
import { MdOutlineFilterList, MdEditNote } from "react-icons/md";
import FilterModal from "./FilterModal";
import Pagination from "../../Components/Pagination/Pagination";

const DashboardContent = () => {
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const location = useLocation();
  const getSearch = location?.state;
  const [isLoading, setIsLoading] = useState(null);
  const [paginatedList, setPaginatedList] = useState([]);
  const [clipLoader, setClipLoader] = useState(null);
  const [franchiseList, setFranchiseList] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  // const [searchInput, setSearchInput] = useState("");
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [editStatus, setEditStatus] = useState(null);
  const [franchiseID, setFranchiseID] = useState(null);
  const [franchiseName, setFranchiseName] = useState(null);
  const [updatedSearchInput, setUpdatedSearchInput] = useState("");
  const [filter, setFilter] = useState({
    options: [],
    districtId: null,
    panchayathIds: [],
    authority: null,
  });
  const [totalPages, setTotalPages] = useState(1);
  const { paginationState, setPaginationState } = usePagination();
  const currentPage = paginationState.franchisePage;
  const searchInput = paginationState.searchQuery;

  console.log("contextsearchInput------------------------->", searchInput);

  const handlePageChange = (pageNumber) => {
    setPaginationState((prevState) => ({
      ...prevState,
      franchisePage: pageNumber,
    }));
  };

  const getRequestData = () => [
    {
      Image: "allrequest",
      head: "All Request",
      request: franchiseList.length,
    },
    {
      Image: "Acceptedrequest",
      head: "Accepted Request",
      request: countRequestStatus("Approved"),
    },
    {
      Image: "pendingrequest",
      head: "Pending Request",
      request: countRequestStatus("Pending"),
    },
    {
      Image: "rejectedrequest",
      head: "Rejected Request",
      request: countRequestStatus("Rejected"),
    },
  ];

  useEffect(() => {
    fetchFranchiseList();
  }, []);

  useEffect(() => {
    fetchPaginatedFranchiseList();
  }, [currentPage]);

  useEffect(() => {
    paginatedList && setFilteredData(paginatedList);
  }, [paginatedList]);

  useEffect(() => {
    searchInput && handleApplyFilters();
    searchInput.length < 1 && setFilteredData(paginatedList);
  }, [searchInput, franchiseList, paginatedList]);

  const handleApplyFilters = () => {
    let filteredData = [...franchiseList];
    if (filter.options.length > 0) {
      filteredData = filteredData.filter((item) =>
        filter.options.includes(item.approve_status)
      );
    }

    if (filter.panchayathIds.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          filter.panchayathIds.includes(item.panchayath_id) ||
          filter.panchayathIds.includes(item.corporation_id) ||
          filter.panchayathIds.includes(item.municipality_id)
      );
    }

    if (filter.authority) {
      if (filter.authority === "panchayath") {
        filteredData = filteredData.filter((item) => item.panchayath_name);
      } else if (filter.authority === "corporation") {
        filteredData = filteredData.filter((item) => item.corporation_name);
      } else if (filter.authority === "municipality") {
        filteredData = filteredData.filter((item) => item.municipality_name);
      }
    }

    if (filter.districtId) {
      filteredData = filteredData.filter(
        (item) => filter.districtId === item.district_id
      );
    }

    if (searchInput) {
      filteredData = filteredData.filter(
        (item) =>
          item.email.toLowerCase().includes(searchInput.toLowerCase()) ||
          item.username.toLowerCase().includes(searchInput.toLowerCase()) ||
          item.request_id?.toLowerCase().includes(searchInput?.toLowerCase()) ||
          item.district_name
            ?.toLowerCase()
            .includes(searchInput?.toLowerCase()) ||
          item.panchayath_name
            ?.toLowerCase()
            .includes(searchInput?.toLowerCase()) ||
          item.municipality_name
            ?.toLowerCase()
            .includes(searchInput?.toLowerCase()) ||
          item.corporation_name
            ?.toLowerCase()
            .includes(searchInput?.toLowerCase()) ||
          item.reject_status
            ?.toLowerCase()
            .includes(searchInput?.toLowerCase()) ||
          item.approve_status
            ?.toLowerCase()
            .includes(searchInput?.toLowerCase())
      );
    }

    if (
      filter.options.length === 0 &&
      filter.authority === null &&
      filter.districtId === null &&
      filter.panchayathIds.length === 0 &&
      !searchInput
    ) {
      fetchPaginatedFranchiseList();
      return;
    }
    setFilteredData(filteredData);
  };

  const countRequestStatus = (status) => {
    return franchiseList.filter((item) => item.approve_status === status)
      .length;
  };

  const handleNavigate = () => {
    window.location.href = "/login";
    window.localStorage.clear();
  };

  const fetchFranchiseList = async () => {
    try {
      // setClipLoader(true);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.get(Urls.franchiseList, config);
      setTotalPages(response?.data?.results?.total_pages);
      if (response?.data?.results?.data) {
        setFranchiseList(response.data.results.data);
      }
      if (currentPage > response.data.results?.total_pages) {
        setPaginationState((prevState) => ({
          ...prevState,
          franchisePage: response.data.results.total_pages,
        }));
      }
    } catch (error) {
      if (error.response.data.message === "Invalid Token") {
        handleNavigate();
      }
      console.log("Error fetching data:", error);
    } finally {
      setClipLoader(null);
    }
  };
  const fetchPaginatedFranchiseList = async () => {
    try {
      setClipLoader(true);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const paginatedResponse = await axios.get(
        Urls.franchiseList + "?page=" + currentPage,
        config
      );
      console.log(
        "pages---------------------------------------------->",
        paginatedResponse.data.results
      );
      if (paginatedResponse?.data?.results?.data) {
        setPaginatedList(paginatedResponse.data.results.data);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setClipLoader(null);
    }
  };

  const handleAproveEdit = (franchise_id, status, franchiseName) => {
    setFranchiseID(franchise_id);
    setFranchiseName(franchiseName);
    setEditStatus(status);
    setShowWarningModal(true);
  };

  const handleRefund = (franchise_id, status, franchiseName) => {
    setFranchiseID(franchise_id);
    setFranchiseName(franchiseName);
    setEditStatus(status);
    setShowWarningModal(true);
  };

  const deleteFranchise = (franchise_id, status, franchiseName) => {
    setFranchiseID(franchise_id);
    setFranchiseName(franchiseName);
    setEditStatus(status);
    setShowWarningModal(true);
  };

  const handleCloseWarningModal = () => {
    setShowWarningModal(false);
  };

  const showModal = () => {
    setIsFilterModalOpen(true);
  };

  const closeModal = () => {
    setIsFilterModalOpen(false);
  };

  const handle3dotClick = (rowId) => {
    setSelectedRowId(selectedRowId === rowId ? null : rowId);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".three-dots")) {
        console.log("Click occurred outside MyComponent.");
        setSelectedRowId(null);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleFranchiseClick = (
    franchiseId,
    orderId,
    orderDate,
    franchiseStatus
  ) => {
    // navigate("/franchise", { state: { data: { franchiseId: franchiseId } } });
    const queryParams = new URLSearchParams({
      franchiseId: franchiseId,
      orderDate: orderDate,
      orderId: orderId,
      status: franchiseStatus,
    });
    navigate(`/franchise?${queryParams.toString()}`);
  };

  const formatDate = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);

    const day = dateObject.getDate().toString().padStart(2, "0");
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObject.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  if (clipLoader) {
    return (
      <div className="loading-spinner">
        <Loader />
      </div>
    );
  }
  console.log("updatedSearchInput--------------->", updatedSearchInput);
  return (
    <div>
      <div className="dashboardcontents">
        <div className="dashboard-request">
          {getRequestData().map((item) => (
            <div className="request-card">
              <img src={require(`./assets/${item.Image}.png`)} alt="" />
              <div className="request-content">
                <p>{item.head}</p>
                <h3>{item.request}</h3>
              </div>
            </div>
          ))}
        </div>

        <div className="parent-request-table">
          <div className="franchise___parent_section">
            <div className="F__parent_left">
              <h3 className="requestlist">Request List</h3>
            </div>
            <div className="F__parent_right">
              <div className="F__inner_search">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchInput}
                  onChange={(e) =>
                    setPaginationState((prevState) => ({
                      ...prevState,
                      searchQuery: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="F__inner__filter">
                <button onClick={showModal}>
                  <MdOutlineFilterList /> filters
                </button>
                {isFilterModalOpen && (
                  <FilterModal
                    isOpen={isFilterModalOpen}
                    onClose={closeModal}
                    onApplyFilters={handleApplyFilters}
                    filter={filter}
                    setFilter={setFilter}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="request-table">
            <table
              style={{ width: "100%", textAlign: "left", colorL: "#193B68" }}
            >
              <thead>
                <tr>
                  <th className="table-head">Date</th>
                  <th className="table-head">Request ID</th>
                  <th className="table-head" style={{ width: "250px" }}>
                    Name
                  </th>
                  <th className="table-head">District</th>
                  <th className="table-head">Ward</th>
                  <th className="table-head">Status</th>
                  <th className="table-head">Action</th>
                </tr>
              </thead>
              <tbody>
                {isLoading === true
                  ? null
                  : filteredData.map((item) => (
                      <tr key={item.id}>
                        <td
                          className="table-datas"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleFranchiseClick(
                              item.id,
                              item.request_id,
                              item.date,
                              item.approve_status
                            )
                          }
                        >
                          {formatDate(item.date)}
                        </td>
                        <td
                          className="table-datas"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleFranchiseClick(
                              item.id,
                              item.request_id,
                              item.date,
                              item.approve_status
                            )
                          }
                        >
                          {item.request_id}
                        </td>
                        <td
                          onClick={() =>
                            handleFranchiseClick(
                              item.id,
                              item.request_id,
                              item.date,
                              item.approve_status
                            )
                          }
                          className="table-datas"
                          style={{ cursor: "pointer", width: "300px" }}
                        >
                          {item.username} <br />
                          <span>{item.email}</span>
                        </td>
                        <td
                          className="table-datas"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleFranchiseClick(
                              item.id,
                              item.request_id,
                              item.date,
                              item.approve_status
                            )
                          }
                        >
                          {item.district_name} <br />{" "}
                          <span>
                            {item.panchayath_name ||
                              item.municipality_name ||
                              item.corporation_name}
                          </span>
                        </td>
                        <td
                          className="table-datas"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleFranchiseClick(
                              item.id,
                              item.request_id,
                              item.date,
                              item.approve_status
                            )
                          }
                        >
                          {item.ward_number}
                        </td>
                        <td className="status_______F">
                          {item.approve_status === "Approved" ? (
                            <span className="approved_ribu">Approved</span>
                          ) : item.approve_status === "Rejected" ? (
                            <>
                              <span className="rejected__1">Rejected</span>
                              {item.reject_status === "Refund Pending" ? (
                                <span className="refunded__1">Pending</span>
                              ) : (
                                <span className="refunded__1">Refunded</span>
                              )}
                            </>
                          ) : null}
                        </td>
                        <td className="actions">
                          {isLoading === item.id ? (
                            <Loader width="25px" height="25px" />
                          ) : item.approve_status === "Pending" ? (
                            ""
                          ) : (
                            <>
                              <BsThreeDotsVertical
                                className="three-dots"
                                style={{ width: "40px", cursor: "pointer" }}
                                onClick={() => handle3dotClick(item.id)}
                              />
                              <div className="deletparent">
                                {selectedRowId === item.id && (
                                  <>
                                    {item.approve_status === "Approved" ? (
                                      <>
                                        <div
                                          className="deletechild"
                                          style={{ color: "#193B68" }}
                                          onClick={() => {
                                            handleAproveEdit(
                                              item.id,
                                              "Pending",
                                              item.username
                                            );
                                          }}
                                        >
                                          <MdEditNote
                                            size={24}
                                            style={{ color: "#EB6484" }}
                                          />{" "}
                                          Edit
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {item.reject_status === "Refunded" ? (
                                          <div
                                            className="deletechild"
                                            style={{
                                              color: "#193B68",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              deleteFranchise(
                                                item.id,
                                                "delete",
                                                item.username
                                              );
                                            }}
                                          >
                                            <RiDeleteBin6Line
                                              style={{ color: "#EB6484" }}
                                            />{" "}
                                            Delete
                                          </div>
                                        ) : (
                                          <>
                                            <div
                                              className="deletechild"
                                              style={{ color: "#193B68" }}
                                              onClick={() => {
                                                handleAproveEdit(
                                                  item.id,
                                                  "Pending",
                                                  item.username
                                                );
                                              }}
                                            >
                                              <MdEditNote
                                                size={24}
                                                style={{ color: "#EB6484" }}
                                              />{" "}
                                              Edit
                                            </div>
                                            <div
                                              className="refunded"
                                              style={{ color: "#193B68" }}
                                              onClick={() =>
                                                handleRefund(
                                                  item.id,
                                                  "refund",
                                                  item.username
                                                )
                                              }
                                            >
                                              <img src={refunded} alt="" />{" "}
                                              Refund
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {!searchInput &&
        filter.options.length === 0 &&
        filter.authority === null &&
        filter.districtId === null &&
        filter.panchayathIds.length === 0 &&
        totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      <WarningModal
        isOpen={showWarningModal}
        onClose={handleCloseWarningModal}
        approveStatus={editStatus}
        franchiseId={franchiseID}
        fetchFranchiseList={fetchFranchiseList}
        fetchPaginatedFranchiseList={fetchPaginatedFranchiseList}
        franchiseName={franchiseName}
      />
    </div>
  );
};

export default DashboardContent;
