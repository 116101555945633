import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as Urls from "../../Urls";
import DashboardHeader from "../../Dashboard/Dashboard/DashboardHeader";
import { Link, useLocation } from "react-router-dom";
import productimage from "./assets/big.png";
import successimg from "../../Components/Register Modal/assets/success.png";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BsDot } from "react-icons/bs";
import { Loader } from "../../Components/Loader/Loader";

const ProductInner = () => {
  const location = useLocation();
  const receivedData = location?.state;
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const franchiseId = localStorage.getItem("franchiseId");
  const [quantity, setQuantity] = useState(1);
  const [productDetail, setProductDetails] = useState([]);
  const [isOrderSuccess, setIsOrderSuccess] = useState(false);
  const [quantityError, setQuantityError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOrderProcessing, setIsOrderProcessing] = useState(false);

  useEffect(() => {
    productDetails();
  }, []);

  useEffect(() => {
    if (quantity > productDetail?.balance_quantity) {
      setQuantityError(
        "Only  " + productDetail?.balance_quantity + "  products are Available"
      );
    } else if (quantity <= 0) {
      setQuantityError("Can't purchase 0 quantity");
    } else {
      setQuantityError("");
    }
  }, [quantity]);

  const handleIncrement = () => {
    if (quantity < productDetail?.balance_quantity) {
      setQuantity((prevQuantity) => prevQuantity + 1);
    }
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const productDetails = async () => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.get(
        Urls.productDetail + receivedData.id,
        config
      );
      console.log("product details ", response);
      setProductDetails(response.data.results.data);
    } catch (error) {
      console.log("Error whilefetching product details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleBuy = async (event) => {
  //   event.preventDefault();
  //   if (!quantityError) {
  //     const body = {
  //       customer: franchiseId,
  //       product_id: receivedData.id,
  //       order_quantity: quantity,
  //     };
  //     try {
  //       setIsLoading(true);
  //       const config = {
  //         headers: {
  //           Authorization: `Token ${token}`,
  //         },
  //       };
  //       const response = await axios.post(Urls.franchiseBuyOrder, body, config);
  //       console.log("Buy ow ", response);
  //       if (response?.data?.message === "Order placed successfully") {
  //         setIsOrderSuccess(true);
  //         setTimeout(() => {
  //           navigate("/franchiseorder");
  //         }, 1000);
  //       }
  //     } catch (error) {
  //       console.log("Error creating product:", error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  // };

  const handleBuy = async (event) => {
    event.preventDefault();

    // Check if an order is already being processed
    if (isOrderProcessing) {
      return;
    }

    if (!quantityError) {
      const body = {
        customer: franchiseId,
        product_id: receivedData.id,
        order_quantity: quantity,
      };

      try {
        setIsOrderProcessing(true); // Set the flag to indicate order processing

        const config = {
          headers: {
            Authorization: `Token ${token}`,
          },
        };

        const response = await axios.post(Urls.franchiseBuyOrder, body, config);
        console.log("Buy ow ", response);

        if (response?.data?.message === "Order placed successfully") {
          setIsOrderSuccess(true);
          setTimeout(() => {
            navigate("/franchiseorder");
          }, 1000);
        }
      } catch (error) {
        console.log("Error creating product:", error);
      } finally {
        setIsOrderProcessing(false); // Reset the flag after order processing is complete
      }
    }
  };

  const handleChange = (e) => {
    setQuantity(parseInt(e.target.value));
  };

  if (isLoading) {
    return (
      <div className="loading-spinner">
        <Loader />
      </div>
    );
  }

  return (
    <div style={{ background: "#F8F9FB" }}>
      <DashboardHeader />
      <div className="product_innerpage">
        <div className="product__navlist">
          <Link to={"/franchiseprofile"}>
            Home <MdKeyboardArrowRight />
          </Link>
          <Link to={"/franchiseproduct"}>
            Product <MdKeyboardArrowRight />
          </Link>
          {productDetail?.product_name}
        </div>
        <div className="product__inner_parent">
          <div className="left__product_image">
            <div className="product__images">
              <img
                style={{ width: "100%", height: "auto" }}
                src={productDetail?.product_image}
                alt=""
              />
            </div>
          </div>
          <div className="right__product_inner">
            <div className="product______name">
              <h2>{productDetail?.product_name}</h2>
              <p className="p__sold">
                {productDetail?.sold_quantity} Sold |{" "}
                {productDetail?.balance_quantity} left
              </p>
              <h3 style={{ marginBottom: "10px" }}>₹ {productDetail?.price}</h3>
            </div>
            <div className="product___quantity">
              <div className="P__left_qnty">
                <p style={{ color: "#193B6899", marginBottom: "3px" }}>
                  Quantity
                </p>
                <div className="counter" style={{ padding: "0px 20px" }}>
                  <AiOutlineMinus onClick={handleDecrement} />{" "}
                  <input
                    style={{
                      margin: "0px",
                      width: "50px",
                      padding: "15px, 0px",
                      border: "none",
                      background: "transparent",
                      paddingLeft: "5px",
                      textAlign: "center",
                      paddingRight: "5px",
                    }}
                    className="custom-input"
                    type="number"
                    value={quantity}
                    onChange={handleChange}
                    min="1"
                  />
                  <AiOutlinePlus onClick={handleIncrement} />
                </div>
              </div>
              {!isOrderSuccess && (
                <div className="p__right_buy" onClick={handleBuy}>
                  Buy Now
                </div>
              )}
            </div>
            {quantityError && <p className="error">{quantityError}</p>}

            <div className="description___product">
              <h3>Description</h3>
              <li>
                {/* <span>
                  <BsDot className="dots" />
                </span>
                {productDetail.product_category_name} */}
                {/* PACKAGE CONTAIN : 1 Piece 16 Liter Bucket|MATERIAL:
                Plastic|PACKAGE DIMENPACKAGE CONTAIN : 1 Piece 16 Liter
                Bucket|MATERIAL: Plastic|PACKAGE DIMENSION: 31 cm31 cm x 31 cm x
                30 cm */}
              </li>
              <li>
                <span>
                  <BsDot className="dots" />
                </span>{" "}
                {productDetail?.product_description}
              </li>
              {/* <li>
                <span>
                  <BsDot className="dots" />
                </span>{" "}
                DURABLE STRUCTURE : This Bucket is made of plastic which makes
                it durable and long lasting, giving it enough strength to carry
                around with sturdiness. Its texture will attract you a lot.
              </li>
              <li>
                <span>
                  <BsDot className="dots" />
                </span>{" "}
                COMFORTABLE HANDLE : This Bucket is built with a comfortable
                handle which makes it easy for you to hold and carry from one
                place. This plastic bucket has a capacity of 16 liters. The
                handle is fitted in the groove of the bucket which are inbuilt.
              </li>
              <li>
                <span>
                  <BsDot className="dots" />
                </span>
                EASY TO MAINTAIN : This Shower Bucket is easy to clean and
                maintain. Simply wipe with a damp cloth to get rid of stains and
                dirt. Once washed it will give a brand new look.
              </li> */}
            </div>
          </div>
        </div>
      </div>
      {isOrderSuccess && (
        <div className="success-message">
          {/* <div
            id="lottie-container"
            style={{ width: "300px", height: "300px", zIndex: "99999" }}
          ></div> */}
          <div>
            {/* <lottie-player
              className="lottie"
              src="https://lottie.host/e79c0151-d047-46a0-ab0c-3f44393c9245/WF99IshXyK.json"
              style={{ background: "#fff" }}
              speed="1"
              loop
              controls
              autoplay
              direction="1"
              mode="normal"
            ></lottie-player> */}
            <img src={successimg} alt="" />
            {/* <div
              id="lottie-container"
              style={{ width: "300px", height: "300px" }}
            ></div> */}
          </div>
          <div style={{ color: "193B68", marginBottom: "5px" }}>
            Your Order successfully placed
          </div>
          {/* <div style={{ color: "#848590", textAlign: "center" }}>
            Welcome to WELOTA.
          </div> */}
        </div>
      )}
    </div>
  );
};

export default ProductInner;
