import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import * as Urls from "../../Urls";
import axios from "axios";
import { CgClose } from "react-icons/cg";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { BsCheckLg } from "react-icons/bs";
import { VscFilePdf } from "react-icons/vsc";
import { Loader } from "../../Components/Loader/Loader";
import Pagination from "../../Components/Pagination/Pagination";
import { GoDownload } from "react-icons/go";

const OrderListScreen = () => {
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const [paginatedOrders, setPaginatedOrders] = useState(false);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [arrowUp, setArrowUp] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [OrderListItems, setOrderListItems] = useState([]);
  const [selectedTab, setSelectedTab] = useState("all");
  const [isLoading, setIsLoading] = useState(null);
  const [clipLoading, setClipLoading] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [excelState, setExcelState] = useState("");

  useEffect(() => {
    OrderList();
  }, []);

  useEffect(() => {
    paginatedOrderList();
  }, [currentPage]);

  useEffect(() => {
    if (selectedTab === "all" && searchQuery) {
      filterOrdersBySearch(OrderListItems, searchQuery);
    } else if (selectedTab === "all") {
      filterOrdersBySearch(paginatedOrders, searchQuery);
    } else {
      const filtered = OrderListItems.filter(
        (order) => order.order_status === selectedTab
      );
      filterOrdersBySearch(filtered, searchQuery);
    }
  }, [selectedTab, OrderListItems, searchQuery]);

  const formatDate = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);
    const formattedDate = dateObject.toLocaleDateString();
    return formattedDate;
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNavigate = () => {
    window.location.href = "/login";
    window.localStorage.clear();
  };

  const OrderList = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.get(Urls.getOrderList, config);
      setTotalPages(response?.data?.results?.total_pages);
      if (response?.data?.results?.data) {
        setOrderListItems(response.data.results.data);
      }
    } catch (error) {
      console.log("Error approving franchise:", error);
      if (error.response.data.message === "Invalid Token") {
        handleNavigate();
      }
    } finally {
      setClipLoading(false);
    }
  };

  const paginatedOrderList = async () => {
    try {
      setClipLoading(true);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.get(
        Urls.getOrderList + "?page=" + currentPage,
        config
      );
      setTotalPages(response?.data?.results?.total_pages);
      if (response?.data?.results?.data) {
        setFilteredOrders(response.data.results.data);
        setPaginatedOrders(response.data.results.data);
      }
    } catch (error) {
      console.log("Error approving franchise:", error);
      if (error.response.data.message === "Invalid Token") {
        handleNavigate();
      }
    } finally {
      setClipLoading(false);
    }
  };

  const handleAprove = async (franchiseId, approveStatus) => {
    const requestBody = {
      order_status: approveStatus,
    };

    try {
      setIsLoading(franchiseId);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.put(
        Urls.orderAprove + franchiseId,
        requestBody,
        config
      );
      if (response.data.results.status_code === 200) {
        OrderList();
        paginatedOrderList();
      }
    } catch (error) {
      console.log("Error approving franchise:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filterOrdersBySearch = (orders, query) => {
    const filtered =
      orders &&
      orders?.filter(
        (order) =>
          order.orderID?.toLowerCase().includes(query.toLowerCase()) ||
          order.product_name?.toLowerCase().includes(query.toLowerCase()) ||
          order.customer_name?.toLowerCase().includes(query.toLowerCase()) ||
          order.delivery_status?.toLowerCase().includes(query.toLowerCase()) ||
          order.order_status?.toLowerCase().includes(query.toLowerCase())
      );
    setFilteredOrders(filtered);
  };

  const handleDownload = async () => {
    // try {
    //   if (!token) {
    //     console.error("No auth token found");
    //     return;
    //   }

    //   const config = {
    //     headers: {
    //       Authorization: `Token ${token}`,
    //     },
    //     responseType: "blob",
    //   };

    //   const response = await axios.get(
    //     Urls.downloadCirtificate + franchise_id,
    //     config
    //   );

    //   const url = window.URL.createObjectURL(new Blob([response.data]));

    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute("download", "certificate.pdf");
    //   document.body.appendChild(link);
    //   link.click();

    //   // Clean up
    //   window.URL.revokeObjectURL(url);
    // } catch (error) {
    //   console.error("Error while downloading:", error);
    // }
    alert("Update Soon");
  };

  const toggleExpandedRow = (index) => {
    if (expandedRowIndex === index) {
      setExpandedRowIndex(null); // Collapse if it's already expanded
    } else {
      setExpandedRowIndex(index); // Expand the selected row
    }
  };

  const handleClickUserName = (
    franchiseId,
    orderId,
    orderDate,
    franchiseStatus
  ) => {
    const queryParams = new URLSearchParams({
      franchiseId: franchiseId,
      orderDate: orderDate,
      orderId: orderId,
      status: franchiseStatus,
    });
    navigate(`/franchise?${queryParams.toString()}`);
  };

  if (clipLoading) {
    return (
      <div className="loading-spinner">
        <Loader />
      </div>
    );
  }

  // const handleExcelDownload = async () => {
  //   try {
  //     setClipLoading(true);
  //     const config = {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     };
  //     const response = await axios.get(Urls.excelDownload + excelState, config);
  //   } catch (error) {
  //     console.log("Error approving franchise:", error);
  //     if (error.response.data.message === "Invalid Token") {
  //       handleNavigate();
  //     }
  //   } finally {
  //     setClipLoading(false);
  //   }
  // };

  const handleExcelDownload = async () => {
    try {
      setClipLoading(true);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
        responseType: "blob", // Set the responseType to 'blob'
      };

      const response = await axios.get(Urls.excelDownload + excelState, config);

      // Handle the Blob response
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "excel_file.xlsx";

      // Trigger the download
      downloadLink.click();
    } catch (error) {
      console.log("Error downloading Excel:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Invalid Token"
      ) {
        handleNavigate();
      }
    } finally {
      setClipLoading(false);
    }
  };

  return (
    <div>
      <div className="parent_oredr_list_screen">
        <div className="order__tabs">
          <p
            style={{
              cursor: "pointer",
              color: selectedTab === "all" ? "#255FE8" : "#000",
              borderBottom:
                selectedTab === "all" ? "2px solid #255FE8" : "none",
            }}
            onClick={() => {
              setSelectedTab("all");
              setExcelState("");
            }}
          >
            All Orders
          </p>
          <p
            style={{
              cursor: "pointer",
              color: selectedTab === "Pending" ? "#255FE8" : "#000",
              borderBottom:
                selectedTab === "Pending" ? "2px solid #255FE8" : "none",
            }}
            onClick={() => {
              setSelectedTab("Pending");
              setExcelState("?filter=pending");
            }}
          >
            Pending
          </p>
          <p
            style={{
              cursor: "pointer",
              color: selectedTab === "Approved" ? "#255FE8" : "#000",
              borderBottom:
                selectedTab === "Approved" ? "2px solid #255FE8" : "none",
            }}
            onClick={() => {
              setSelectedTab("Approved");
              setExcelState("?filter=approved");
            }}
          >
            Approved
          </p>
          <p
            style={{
              cursor: "pointer",
              color: selectedTab === "Rejected" ? "#255FE8" : "#000",
              borderBottom:
                selectedTab === "Rejected" ? "2px solid #255FE8" : "none",
            }}
            onClick={() => {
              setSelectedTab("Rejected");
              setExcelState("?filter=cancel");
            }}
          >
            Cancelled
          </p>
        </div>
        <div className="order__search_filter">
          <div className="left__searchbox">
            <input
              type="text"
              placeholder="Search for order ID, customer , product , order status..etc"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <div className="excel_download-btn" onClick={handleExcelDownload}>
            <div>
              <GoDownload />
            </div>
            <p>Download Excel</p>
          </div>
        </div>

        {/* order list screen start */}
        <div className="order__listscreen_table">
          <table
            style={{ width: "100%", textAlign: "left", colorL: "#193B68" }}
          >
            <thead>
              <tr>
                <th className="table-head">Order ID</th>
                <th className="table-head">Date</th>
                <th className="table-head" style={{ width: "250px" }}>
                  Product Name
                </th>
                <th className="table-head">Customer Name</th>
                <th className="table-head">Ward</th>
                <th className="table-head">Qty</th>
                <th className="table-head">Total Price</th>
                <th className="table-head"></th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders &&
                filteredOrders.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <tr>
                      <td className="table-datas">{item.orderID}</td>
                      <td className="table-datas">{formatDate(item.date)}</td>
                      <td className="table-datas">
                        <div className="order__photo_desc">
                          <div className="oredr__pic">
                            <img
                              style={{
                                width: "59px",
                                height: "72px",
                                objectFit: "contain",
                              }}
                              src={item.product_image}
                              alt=""
                            />
                          </div>
                          <div className="order__desc">
                            <p>{item.product_name}</p>
                          </div>
                        </div>
                      </td>
                      <td className="table-datas">
                        <span
                          style={{ color: "mediumblue", cursor: "pointer" }}
                          onClick={() =>
                            handleClickUserName(
                              item.customer_id,
                              item.orderID,
                              item.date,
                              item.approve_status
                            )
                          }
                        >
                          {item.customer_name}
                        </span>{" "}
                        <br />
                        <span>
                          {item.district} | {item.customer_location}
                        </span>
                      </td>

                      <td className="table-datas" style={{ color: "#255FE8" }}>
                        {item.ward}
                      </td>
                      <td className="table-datas">{item.order_quantity}</td>
                      <td className="table-datas" style={{ color: "#193B68" }}>
                        ₹ {item.price}
                      </td>
                      <td className="table-datas">
                        <div className="Order__actions">
                          {isLoading === item.id ? (
                            <Loader width="25px" height="25px" />
                          ) : item.order_status === "Pending" ? (
                            <>
                              <BsCheckLg
                                className="approved"
                                onClick={() => {
                                  handleAprove(item.id, "Approved");
                                }}
                              />
                              <CgClose
                                className="closed"
                                onClick={() => {
                                  handleAprove(item.id, "Rejected");
                                }}
                              />
                            </>
                          ) : item.order_status === "Approved" ? (
                            <p className="partial_initial1">Confirmed</p>
                          ) : (
                            <p className="initiated">Cancelled</p>
                          )}
                          {expandedRowIndex === index ? (
                            <IoIosArrowUp
                              className="order__arrow"
                              onClick={() => toggleExpandedRow(index)}
                            />
                          ) : (
                            <IoIosArrowDown
                              className="order__arrow"
                              onClick={() => toggleExpandedRow(index)}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                    {expandedRowIndex === index && (
                      <tr>
                        <td colSpan="8">
                          <div className="oredr__subchild">
                            <div className="order_sub_left"></div>
                            <div className="order_sub_right">
                              <div className="first__two">
                                <div className="Order_p_id">
                                  <p className="Order_p_id_1">Product ID</p>
                                  <p style={{ color: "#193B68" }}>
                                    {item.product__id}
                                  </p>
                                </div>
                                <div className="Order_paymet_status">
                                  <p className="Order_p_id_1">Payment status</p>
                                  <div className="partial_initial">
                                    {item.payment_status && (
                                      <p className="partial_initial1">
                                        {item.payment_status}
                                      </p>
                                    )}
                                    <p className="initiated">
                                      {item.order_status}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="second__subs">
                                <div className="Order_balance_amount">
                                  <p className="Order_p_id_1">Balance Amount</p>
                                  <p className="balance_amount">
                                    ₹ {item.balance_amount}
                                  </p>
                                </div>
                                <div className="Order_paid_amount">
                                  <p className="Order_p_id_1">Paid Amount</p>
                                  <p className="paid__amount">
                                    ₹ {item.paid_amount}
                                  </p>
                                </div>
                                <div className="Order_invoice">
                                  <p
                                    className="Order_p_id_1"
                                    style={{ textAlign: "end" }}
                                  >
                                    Invoice
                                  </p>
                                  <p
                                    className="downloaded"
                                    onClick={() => handleDownload()}
                                  >
                                    <VscFilePdf style={{ color: "#E52727" }} />{" "}
                                    Download{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </div>
        {selectedTab === "all" && !searchQuery && totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
        {/* order list screen end */}
      </div>
    </div>
  );
};

export default OrderListScreen;
