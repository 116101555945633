import React from "react";
import { Modal } from "antd";
import "./TermsModal.css";

const TermsModal = ({ isOpen, onClose, onTermsChange }) => {
  const handleAcceptClick = (value) => {
    onTermsChange(value);
    onClose();
  };
  return (
    <div className="terms-condition">
      <Modal open={isOpen} onOk={onClose} onCancel={onClose}>
        <div className="parent_form">
          <div className="terms-condition_text">
            <h3>Franchise Agreement Terms and Conditions</h3>
            <h2>Grant of App Access</h2>
            <p>
              You are just one step away from joining us. Fill out this form and
              become our Franchisee Partner right away.
            </p>
            <p>
              The Franchisor will revoke the right to access and use the
              franchisor’s mobile application if the franchisee has not utilized
              the app for 3 months.
            </p>
            <h2>User Accounts</h2>
            <p>
              The franchisee agrees to create and maintain user accounts for
              authorized personnel only, as per the franchisor's guidelines.
            </p>
            <h2>App Functionality</h2>
            <p>
              The franchisor will provide the franchisee with access to specific
              features and functionalities of the App, which may include but are
              not limited to
            </p>
            <div className="list-container">
              <ul>
                <li>Order processing and fulfillment.</li>
                <li>Inventory management.</li>
                <li>Sales reporting.</li>
                <li>Marketing and promotional tools.</li>
              </ul>
            </div>
            <h2>Data Security:</h2>
            <div className="list-container">
              <ul>
                <li>
                  The franchisee is responsible for maintaining the
                  confidentiality and security of login credentials and user
                  accounts.
                </li>
                <li>
                  The franchisee agrees not to share access to the App with
                  unauthorized individuals.
                </li>
              </ul>
            </div>
            <h2>Training and Support:</h2>
            <p>
              The franchisee agrees to create and maintain user accounts for
              authorized personnel only, as per the franchisor's guidelines.
            </p>
            <h2>Data Ownership:</h2>
            <p>
              All data generated or collected through the App, including
              customer information, sales data, and operational metrics, are the
              property of the franchisor.
            </p>
            <h2>Updates and Maintenance:</h2>
            <p>
              The franchisor reserves the right to update, modify, or
              temporarily disable the App for maintenance or improvements
              without prior notice
            </p>
            <h2>Termination:</h2>
            <p>
              The franchisor may terminate the franchisee's access to the App at
              any time for violations of these terms or other reasons specified
              in the franchise agreement.
            </p>
            <h2>Liability</h2>
            <p>
              The franchisor is not liable for any disruptions, data loss, or
              damages resulting from the use of the App.
            </p>
            <p>
              The franchisee is responsible for any costs associated with using
              the App, including mobile data charges
            </p>
            <h2>Intellectual Property:</h2>
            <p>
              The franchisee is granted a limited, non-transferable license to
              use the App and any associated intellectual property solely for
              franchise management purposes.
            </p>
            <h2>Confidentiality</h2>
            <p>
              The franchisee agrees not to disclose any proprietary information
              or trade secrets related to the App.
            </p>
            <h2>Governing Law</h2>
            <p>
              The agreement specifies the jurisdiction and governing law under
              which it operates.
            </p>
            <h2>Amendments:</h2>
            <p>
              The franchisor reserves the right to amend these terms and
              conditions with notice to the franchisee.
            </p>
            <h2>Termination</h2>
            <p>
              Provisions for the termination of App access and the consequences
              of such termination are outlined.
            </p>
          </div>
          <div className="form-register" style={{ marginTop: "10px" }}>
            <button type="button" onClick={() => handleAcceptClick(true)}>
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TermsModal;
