import React, { useState, useEffect, useRef } from "react";
import { format, parse, parseISO } from "date-fns";
import successimg from "../../Components/Register Modal/assets/success.png";
import { Loader } from "../../Components/Loader/Loader";
import * as Urls from "../../Urls";
import axios from "axios";
import DatePicker from "react-datepicker";
import { Modal } from "antd";
import { IoClose } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import { BsQrCodeScan } from "react-icons/bs";
import { Select } from "antd";

const EditProfileModal = ({
  isOpen,
  onClose,
  franchiseData,
  franchiseId,
  fetchFranchiseProfile,
}) => {
  const token = localStorage.getItem("authToken");
  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRefAdharback = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isUpdationSuccess, setIsUpdationSuccess] = useState(false);
  const [adharImagesContainer, setAdharImagesContainer] = useState(false);
  const [selectedPassbookFilename, setSelectedPassbookFilename] = useState("");
  const [selectedAdharFileName, setSelectedAdharFileName] = useState("");
  const [selectedAdharBackFileName, setSelectedAdharBackFileName] =
    useState("");
  const [selectedAttachmentFileName, setSelectedAttachmentFileName] =
    useState("");
  const [selectedPanFileName, setSelectedPanFileName] = useState("");
  const [qualification, setQualification] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    email: "",
    username: "",
    aadharcard: "",
    bankAccount: "",
    dob: "",
    gender: "",
    pancard: "",
    ifsc: "",
    mobile: "",
    address: "",
    nominee: "",
    bankBranch: "",
    confirmbank: "",
    relation: "",
    qualification: "",
    passbookImage: null,
    adharImagefront: null,
    adharImageBack: null,
    pancardImage: null,
    paymentAttachment: "",
  });

  useEffect(() => {
    const originalDate = new Date(franchiseData?.DateOfBirth);

    const year = originalDate.getFullYear();
    const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
    const day = originalDate.getDate().toString().padStart(2, "0");

    const formattedDate = `${day}-${month}-${year}`;

    setFormValues({
      email: franchiseData?.email,
      username: franchiseData?.username,
      aadharcard: franchiseData?.adhar_number,
      bankAccount: franchiseData?.bank_account_number,
      dob: formattedDate,
      gender: franchiseData?.gender,
      pancard: franchiseData?.pancard_number,
      mobile: franchiseData?.mobile_number,
      address: franchiseData?.address,
      nominee: franchiseData?.nominee_name,
      passbookImage: franchiseData?.bank_passbook_image,
      adharImagefront: franchiseData?.adhar_image,
      adharImageBack: franchiseData?.adhar_imageback,
      pancardImage: franchiseData?.pancard_image,
      paymentAttachment: franchiseData?.attachment,
      ifsc: franchiseData?.IFSC,
      relation: franchiseData?.relationship,
      qualification: franchiseData?.qualification_id,
    });
  }, [franchiseData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const qualificationResponse = await axios.get(Urls.getQualification);

        const mappedqualification = qualificationResponse.data.results.data.map(
          (qualification) => ({
            // label: district.name,
            label:
              qualification.name.charAt(0).toUpperCase() +
              qualification.name.slice(1).toLowerCase(),
            value: qualification.id,
          })
        );
        setQualification(mappedqualification);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChooseFiles = () => {
    fileInputRef2.current.click();
  };

  const handleChooseAdharBackFiles = () => {
    fileInputRefAdharback.current.click();
  };
  const handleChooseFilespancrad = () => {
    fileInputRef3.current.click();
  };
  const handleChooseFilesPassBook = () => {
    fileInputRef4.current.click();
    // setPassbookError("");
  };

  const isEmpty = (value) => value.trim() === "";
  const isValidEmail = (email) => {
    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
    const mailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/;
    return emailRegex.test(email) && mailRegex.test(email);
  };

  const isValidPhoneNumber = (mobile) => {
    const mobileRegex = /^[0]?[6789]\d{9}$/;
    return mobileRegex.test(mobile);
  };

  const handleNumericKeyPress = (event) => {
    const allowedKeys = /[0-9]|\+|-|ArrowLeft|ArrowRight|c|a|x|Control|v/;

    if (!allowedKeys.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  const vaidate = () => {
    const errors = {};

    if (isEmpty(formValues.username)) {
      errors.username = "Full Name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(formValues.username)) {
      errors.username = "Full Name should only contain characters and spaces";
    }

    if (isEmpty(formValues.email)) {
      errors.email = "Email Address is required";
    } else if (!isValidEmail(formValues.email.toLowerCase())) {
      errors.email = "Invalid Email Address";
    } else if (formErrors.email) {
      errors.email = "Email already registered";
    }

    if (isEmpty(formValues.dob)) {
      errors.dob = "Enter date of birth";
    }

    if (isEmpty(formValues.mobile)) {
      errors.mobile = "Enter phone number";
    } else if (!isValidPhoneNumber(formValues.mobile)) {
      errors.mobile = "Invalid Phone Number";
    } else if (formErrors.mobile) {
      errors.mobile = "Phone number already registered";
    }

    if (!formValues.gender) {
      errors.gender = "Select your gender";
    }

    if (isEmpty(formValues.address)) {
      errors.address = "Enter address";
    }

    if (isEmpty(formValues.aadharcard)) {
      errors.aadharcard = "Aadhar Card Number is required";
    } else if (!/^\d{12}$/.test(formValues.aadharcard)) {
      errors.aadharcard = "Aadhar card should only contain up to 12 digits";
    }

    if (isEmpty(formValues.pancard)) {
      errors.pancard = "Pan card number is required";
    } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(formValues.pancard)) {
      errors.pancard = "Invalid PAN card format";
    }

    if (isEmpty(formValues.bankAccount)) {
      errors.bankAccount = "Account number is required";
    } else if (
      formValues.bankAccount.length < 9 ||
      formValues.bankAccount.length > 18
    ) {
      errors.bankAccount =
        "Bank Account Number should be between 9 and 18 digits";
    }
    if (isEmpty(formValues.nominee)) {
      errors.nominee = "Nominee is required";
    } else if (!/^[a-zA-Z\s]*$/.test(formValues.nominee)) {
      errors.nominee = "Nominee should only contain characters and spaces";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    const fieldValue =
      type === "checkbox"
        ? checked
        : name === "pancard" || name === "ifsc"
        ? value.toUpperCase()
        : value;

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    if (name === "relations") {
      setFormValues({ ...formValues, gender: value });
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        gender: "",
      }));
    }

    if (name === "email") {
      console.log("e,ailvalue--------------------->", value);
      isEmailAvailable(value);
    }

    if (name === "pancard") {
      if (value?.length !== 10) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          pancard: "PAN card format should 10 digits alphanumeric characters",
        }));
      } else if (!/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(value)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          pancard: "Invalid pancard number",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          pancard: "",
        }));
      }
    }

    if (name === "bankAccount") {
      if (!/^\d+$/.test(value)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          bankAccount: "Bank Account Number should only contain digits",
        }));
      } else if (value.length < 9 || value.length > 18) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          bankAccount: "Bank Account Number should be between 9 and 18 digits",
        }));
      }
    }

    if (name === "aadharcard") {
      if (!/^\d{12}$/.test(value)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Aadhar Card should only contain up to 12 digits",
        }));
      }
    }

    if (name === "dob" && fieldValue) {
      const parsedDate = parse(fieldValue, "dd-MM-yyyy", new Date());
      const formattedDate = format(parsedDate, "dd-MM-yyyy");
      setFormValues((prevState) => ({
        ...prevState,
        dob: formattedDate,
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        [name]: fieldValue,
      }));
    }
  };

  const relationship = [
    "Father",
    "Mother",
    "Brother",
    "Friend",
    "Husband",
    "Wife",
    "Son",
    "Daughter",
    "Sister",
    "Grandson",
    "Granddaughter",
    "Mother in law",
    "Father in law",
    "Other",
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = vaidate();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      editProfile();
    }
  };

  const isEmailAvailable = async (email) => {
    try {
      const response = await axios.get(
        `${
          Urls.emailAvailable
        }?email=${email.toLowerCase()}&user_id=${franchiseId}`
      );
      if (response.data.results.message === "Email already registered") {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: response.data.results.message,
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
      console.log("email respose:", response);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const handleImageUploadPassbook = (event) => {
    const file = event.target.files[0];
    const allowedFormats = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedFormats.includes(fileExtension)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        passbookImage: "Please select a JPG, JPEG, or PNG image.",
      }));
      return;
    }
    if (file) {
      setSelectedPassbookFilename(file.name);

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormValues({ ...formValues, passbookImage: reader.result });
      };
      reader.readAsDataURL(file);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        passbookImage: "",
      }));
    } else {
      setSelectedPassbookFilename("");
    }
  };

  const handleImageUploadAdhar = (event) => {
    const file = event.target.files[0];
    const allowedFormats = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedFormats.includes(fileExtension)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        adharImage: "Please select a JPG, JPEG, or PNG image.",
      }));
      return;
    }
    if (file) {
      setSelectedAdharFileName(file.name);

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormValues({ ...formValues, adharImagefront: reader.result });
      };
      reader.readAsDataURL(file);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        adharImage: "",
      }));
    } else {
      setSelectedAdharFileName("");
    }
  };

  const handleAdharImageUploadBack = (event) => {
    const file = event.target.files[0];
    const allowedFormats = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedFormats.includes(fileExtension)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        adharImageBack: "Please select a JPG, JPEG, or PNG image.",
      }));
      return;
    }
    if (file) {
      setSelectedAdharBackFileName(file.name);

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormValues({ ...formValues, adharImageBack: reader.result });
      };
      reader.readAsDataURL(file);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        adharImageBack: "",
      }));
    } else {
      setSelectedAdharBackFileName("");
    }
  };

  const handleImageUploadpancard = (event) => {
    const file = event.target.files[0];
    const allowedFormats = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedFormats.includes(fileExtension)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        pancardImage: "Please select a JPG, JPEG, or PNG image.",
      }));
      return;
    }
    if (file) {
      setSelectedPanFileName(file.name);

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormValues({ ...formValues, pancardImage: reader.result });
      };
      reader.readAsDataURL(file);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        pancardImage: "",
      }));
    } else {
      setSelectedPanFileName("");
    }
  };

  const handleChooseFile = () => {
    fileInputRef.current.click();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    const allowedFormats = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedFormats.includes(fileExtension)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        imageAttachment: "Please select a JPG, JPEG, or PNG image.",
      }));
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setFormValues({
          ...formValues,
          paymentAttachment: event.target.result,
        });
      };
      reader.readAsDataURL(file);
      setFormErrors({ imageAttachment: "" });
    }
  };

  const handlePaymentAttachment = (event) => {
    const file = event.target.files[0];
    const allowedFormats = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedFormats.includes(fileExtension)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        imageAttachment: "Please select a JPG, JPEG, or PNG image.",
      }));
      return;
    }
    if (file) {
      setSelectedAttachmentFileName(file.name);

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormValues({ ...formValues, paymentAttachment: reader.result });
      };
      reader.readAsDataURL(file);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        imageAttachment: "",
      }));
    } else {
      setSelectedAttachmentFileName("");
    }
  };

  const onRelationChange = (value) => {
    setFormValues({
      ...formValues,
      relation: value,
    });
    setFormErrors({ ...formErrors, relation: "" });
  };

  const mappedrelationship = relationship.map((relationship) => ({
    value: relationship,
  }));

  const qualificationChange = (value) => {
    console.log("qualificationValue", value);
    setFormValues({
      ...formValues,
      qualification: value,
    });
    setFormErrors({ ...formErrors, qualification: "" });
  };

  const editProfile = async () => {
    setIsLoading(true);
    const body = {
      email: formValues.email.toLowerCase(),
      username: formValues.username,
      adhar_number: formValues.aadharcard,
      bank_account_number: formValues.bankAccount,
      DateOfBirth: formValues.dob,
      gender: formValues.gender,
      mobile_number: formValues.mobile,
      address: formValues.address,
      nominee_name: formValues.nominee,
      pancard_number: formValues.pancard,
      attachment: formValues.paymentAttachment,
      adhar_imageback: formValues.adharImageBack,
      adhar_image: formValues.adharImagefront,
      pancard_image: formValues.pancardImage,
      bank_passbook_image: formValues.passbookImage,
      IFSC: formValues.ifsc,
      relationship: formValues.relation,
      qualification: formValues.qualification,
    };
    try {
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.put(
        Urls.editFranchiseProfile + franchiseId,
        body,
        config
      );
      if (response?.data?.results?.status_code === 200) {
        onClose();
        setIsUpdationSuccess(true);
      }
      setTimeout(() => {
        setIsUpdationSuccess(false);
        fetchFranchiseProfile();
      }, 2000);
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setIsLoading(null);
    }
  };
  return (
    <div>
      <Modal open={isOpen} onOk={onClose} onCancel={onClose} title="">
        <div className="product_creation">
          <form
            className="create_form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <h3 className="create_product_h3">Edit Profile</h3>
            <p
              style={{
                color: "#7589A4",
                fontFamily: "poppins",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              You can edit your profile details here
            </p>
            <div className="product_creation_parent">
              <div className="name_field">
                <label for="username">Full Name</label> <br />
                <input
                  type="text"
                  name="username"
                  value={formValues.username}
                  placeholder="Enter Your Name"
                  onChange={handleChange}
                />
                {formErrors.username && (
                  <p className="error">{formErrors.username}</p>
                )}
              </div>
              <div className="email_field">
                <label for="email">Email Address</label> <br />
                <input
                  type="email"
                  name="email"
                  value={formValues.email.toLowerCase()}
                  onChange={handleChange}
                  placeholder="sample@gmail.com"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}"
                />
                {formErrors.email && (
                  <p className="error">{formErrors.email}</p>
                )}
              </div>
              <div className="parent_dob_gender-edit_profile">
                <div className="dob_field">
                  <label htmlFor="dob">DOB</label> <br />
                  <DatePicker
                    name="dob"
                    selected={
                      formValues.dob
                        ? parse(formValues.dob, "dd-MM-yyyy", new Date())
                        : null
                    }
                    onChange={(date) => {
                      const formattedDate = date
                        ? format(date, "dd-MM-yyyy")
                        : "";
                      setFormValues((prevState) => ({
                        ...prevState,
                        dob: formattedDate,
                      }));
                      setFormErrors((prevState) => ({
                        ...prevState,
                        dob: "",
                      }));
                    }}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="dd-MM-yyyy"
                    showMonthDropdown
                    showYearDropdown
                    maxDate={new Date()}
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                  {formErrors.dob && <p className="error">{formErrors.dob}</p>}
                </div>
                <div className="gender_field">
                  <label for="gender">Gender</label> <br />
                  <div className="parent_radio_edit_profile">
                    <div className="left_radio">
                      <input
                        className="radio_btns"
                        type="radio"
                        name="relations"
                        checked={formValues.gender === "MALE" && "true"}
                        value="MALE"
                        onChange={handleChange}
                      />
                      <label htmlFor="html">Male</label>
                    </div>
                    <div className="right_radio">
                      <input
                        type="radio"
                        id="css"
                        name="relations"
                        checked={formValues.gender === "FEMALE" && "true"}
                        value="FEMALE"
                        className="radio_btns"
                        onChange={handleChange}
                      />
                      <label htmlFor="css">Female</label>
                    </div>
                  </div>
                  {formErrors.gender && (
                    <p className="error">{formErrors.gender}</p>
                  )}
                </div>
              </div>
              <div className="mobile_field">
                <label for="mobile">Mobile Number</label> <br />
                <input
                  type="numeric"
                  name="mobile"
                  maxLength={10}
                  value={formValues.mobile}
                  placeholder="+91 9896700070"
                  onChange={handleChange}
                  onKeyDown={handleNumericKeyPress}
                />
                {formErrors.mobile && (
                  <p className="error">{formErrors.mobile}</p>
                )}
              </div>
              <div className="adharcard">
                <label for="aadharcard">Aadhar Card Number</label> <br />
                <input
                  type="text"
                  name="aadharcard"
                  value={formValues.aadharcard}
                  maxLength={12}
                  onChange={handleChange}
                  placeholder="989866560043"
                />
                {formErrors.aadharcard && (
                  <p className="error">{formErrors.aadharcard}</p>
                )}
              </div>
              <div className="choose_files">
                <label>Aadhar Card Image</label>
                <div className="attament-files">
                  <div
                    className="drag_drops"
                    onClick={() => {
                      setAdharImagesContainer(true);
                      setFormErrors({ ...formErrors, aharChoose: "" });
                    }}
                  >
                    <p className="choose__filess">Edit file.....</p>
                  </div>
                  {(adharImagesContainer ||
                    formValues.adharImagefront ||
                    formValues.adharImageBack) && (
                    <div className="image_parent_container">
                      <div className="img_select_show-container">
                        <div
                          className="adhar_image_select"
                          onClick={handleChooseFiles}
                        >
                          <input
                            type="file"
                            key={formValues.adharImagefront}
                            ref={fileInputRef2}
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={handleImageUploadAdhar}
                          />
                          <FiPlus />
                        </div>
                        {formErrors.adharImage && (
                          <p className="error">{formErrors.adharImage}</p>
                        )}
                        {formValues.adharImagefront && (
                          <>
                            <div className="adhar-image-container">
                              <img
                                className="attachment-payments2"
                                src={formValues.adharImagefront}
                                alt="Selected"
                              />
                            </div>
                            <IoClose
                              className="img-close"
                              onClick={() => {
                                console.log("clicked");
                                setFormValues({
                                  ...formValues,
                                  adharImagefront: null,
                                });
                                fileInputRef2.current.value = "";
                                setSelectedAdharFileName("");
                              }}
                            />
                            <p className="file-name">{selectedAdharFileName}</p>
                          </>
                        )}
                      </div>
                      <div className="img_select_show-container">
                        <div
                          className="adhar_image_select"
                          onClick={handleChooseAdharBackFiles}
                        >
                          <input
                            type="file"
                            key={formValues.adharImageBack}
                            ref={fileInputRefAdharback}
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={handleAdharImageUploadBack}
                          />
                          <FiPlus />
                        </div>
                        {formErrors.adharImageBack && (
                          <p className="error">{formErrors.adharImageBack}</p>
                        )}
                        {formValues.adharImageBack && (
                          <>
                            <div className="adhar-image-container">
                              <img
                                className="attachment-payments2"
                                src={formValues.adharImageBack}
                                alt="Selected"
                              />
                            </div>
                            <IoClose
                              className="img-close"
                              onClick={() => {
                                setFormValues({
                                  ...formValues,
                                  adharImageBack: null,
                                });
                                setSelectedAdharBackFileName("");
                              }}
                            />
                            <p className="file-name">
                              {selectedAdharBackFileName}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {formErrors.aharChoose && (
                <p className="error">{formErrors.aharChoose}</p>
              )}
              <div className="adharcard">
                <label for="pancard">Pan Card Number</label> <br />
                <input
                  type="text"
                  name="pancard"
                  onChange={handleChange}
                  value={formValues.pancard}
                  placeholder="HTYJK8986K"
                />
                {formErrors.pancard && (
                  <p className="error">{formErrors.pancard}</p>
                )}
              </div>
              <div className="choose_files">
                <label>Pan Card Image</label>
                <div className="attament-files">
                  <div
                    className="drag_drops"
                    onClick={handleChooseFilespancrad}
                  >
                    <p className="choose__filess">Edit file.....</p>
                  </div>
                  <input
                    type="file"
                    key={formValues.pancardImage}
                    ref={fileInputRef3}
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleImageUploadpancard}
                  />
                  {formValues.pancardImage && (
                    <>
                      <div className="image-container">
                        <img
                          className="attachment-payments2"
                          src={formValues.pancardImage}
                          alt="Selected"
                        />
                        <IoClose
                          className="img-close"
                          onClick={() => {
                            setFormValues({
                              ...formValues,
                              pancardImage: null,
                            });
                            fileInputRef3.current.value = "";
                            setSelectedPanFileName("");
                          }}
                        />
                      </div>
                      <div className="file-name">{selectedPanFileName}</div>
                    </>
                  )}
                  {formErrors.pancardImage && (
                    <p className="error">{formErrors.pancardImage}</p>
                  )}
                </div>
              </div>
              <div className="adharcard">
                <label for="ifsc">IFSC</label> <br />
                <input
                  type="text"
                  name="ifsc"
                  value={formValues.ifsc}
                  onChange={handleChange}
                  placeholder="FDRL0001537"
                />
                {formErrors.ifsc && <p className="error">{formErrors.ifsc}</p>}
              </div>
              <div className="bankaccount">
                <label for="bankAccount">Bank Account Number</label> <br />
                <input
                  type="text"
                  name="bankAccount"
                  value={formValues.bankAccount}
                  onChange={handleChange}
                  pattern="[0-9]+"
                  placeholder="Enter Bank Account Number"
                />
                {formErrors.bankAccount && (
                  <p className="error">{formErrors.bankAccount}</p>
                )}
              </div>
              <div className="choose_files">
                <label>Passbook Image</label>
                <div className="attament-files">
                  <div
                    className="drag_drops"
                    onClick={handleChooseFilesPassBook}
                  >
                    <p className="choose__filess">Edit file.....</p>
                  </div>
                  <input
                    type="file"
                    key={formValues.passbookImage}
                    ref={fileInputRef4}
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleImageUploadPassbook}
                  />
                  {formValues.passbookImage && (
                    <>
                      <div className="image-container">
                        <img
                          className="attachment-payments2"
                          src={formValues.passbookImage}
                          alt="Selected"
                        />
                        <IoClose
                          className="img-close"
                          onClick={() => {
                            setFormValues({
                              ...formValues,
                              passbookImage: null,
                            });
                            fileInputRef4.current.value = "";
                            setSelectedPassbookFilename("");
                          }}
                        />
                      </div>
                      <div className="file-name">
                        {selectedPassbookFilename}
                      </div>
                    </>
                  )}
                  {formErrors.passbookImage && (
                    <p className="error">{formErrors.passbookImage}</p>
                  )}
                </div>
              </div>
              <div className="nominee_field">
                <label for="nominee">Nominee Name</label> <br />
                <input
                  type="text"
                  name="nominee"
                  placeholder="Enter Nominee"
                  value={formValues.nominee}
                  onChange={handleChange}
                />
                {formErrors.nominee && (
                  <p className="error">{formErrors.nominee}</p>
                )}
                <div
                  // className="relationship"
                  className={`relationship ${
                    formValues.relation ? "selected" : ""
                  }`}
                >
                  <label for="relation">Relationship</label> <br />
                  <Select
                    value={!formValues.relation ? null : formValues.relation}
                    onChange={onRelationChange}
                    placeholder="Select a Relationship"
                    required
                    options={mappedrelationship}
                  />
                  {formErrors.relation && (
                    <p className="error">{formErrors.relation}</p>
                  )}
                </div>
                <div
                  className={`qualification ${
                    formValues.qualification ? "selected" : ""
                  }`}
                >
                  <label for="qualification">Qualification</label> <br />
                  <Select
                    value={
                      !formValues.qualification
                        ? null
                        : formValues.qualification
                    }
                    onChange={qualificationChange}
                    placeholder="Select a qualification"
                    required
                    options={qualification}
                  />
                  {formErrors.qualification && (
                    <p className="error">{formErrors.qualification}</p>
                  )}
                </div>
                <div className="address_field">
                  <label for="address">Address</label> <br />
                  <textarea
                    rows="5"
                    maxLength={500}
                    name="address"
                    value={formValues.address}
                    onChange={handleChange}
                  />
                  {formErrors.address && (
                    <p className="error">{formErrors.address}</p>
                  )}
                </div>
                {franchiseData?.payment_type === "UPI ID" && (
                  <div className="attament-files">
                    <label>Payment attachment</label>
                    <div
                      style={{ position: "relative" }}
                      // className="drag_drop"
                      className={`drag_drop ${isDragging ? "dragging" : ""}`}
                      onClick={handleChooseFile}
                      onDragEnter={handleDragEnter}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                    >
                      Drag & Drop or <span>browse</span>{" "}
                      <BsQrCodeScan className="qrscan" />
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      key={formValues.paymentAttachment}
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={handlePaymentAttachment}
                    />
                    {formValues.paymentAttachment && (
                      <>
                        <div className="image-container">
                          <img
                            className="attachment-payments"
                            src={formValues.paymentAttachment}
                            alt="Selected"
                          />
                          <IoClose
                            className="img-close"
                            style={{ top: "15px" }}
                            onClick={() => {
                              setFormValues({
                                ...formValues,
                                paymentAttachment: null,
                              });
                              fileInputRef.current.value = "";
                              setSelectedAttachmentFileName("");
                            }}
                          />
                        </div>
                        <div className="file-name">
                          {selectedAttachmentFileName}
                        </div>
                      </>
                    )}
                    {formErrors.imageAttachment && (
                      <p className="error">{formErrors.imageAttachment}</p>
                    )}
                  </div>
                )}
                <div className="form-register" style={{ marginTop: "20px" }}>
                  <button type="submit">Update</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      {isUpdationSuccess && (
        <div className="success-message">
          <div>
            <img src={successimg} alt="" />
          </div>
          <div style={{ color: "193B68", marginBottom: "5px" }}>
            Successfully updated
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProfileModal;
