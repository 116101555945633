import React, { useState } from "react";
import Sidebar from "../Dashboard/Sidebar";
import DashboardHeader from "../Dashboard/DashboardHeader";
import "./CreateProduct.css";
import create from "./aseets/create.png";
import CreateProductModal from "./CreateProductModal";
import Products from "../Products/Products";

const CreateProduct = () => {
  // modal start
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  //  modal end
  return (
    <div>
      <Sidebar />
      <DashboardHeader />
      <div className="createproduct_parent">
        <div className="create_product_page">
          <img src={create} alt="" />
          <div className="create-p-content">
            <h2>Create Your product</h2>
            <p>
              Start by adding a new product, Describe it, upload images, set
              prices, and more. Let's get started and bring your business to
              life!{" "}
            </p>
            <div className="create_p_btn">
              <button onClick={showModal}>Create product</button>
            </div>
          </div>
        </div>
      </div>
      <CreateProductModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default CreateProduct;
