import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import userlogo from "./assets/userlogo.png";
import { IoIosArrowDown } from "react-icons/io";
import { useLocation } from "react-router-dom";
import Dashboard from "./Dashboard";
import { BsDot } from "react-icons/bs";
import ChangePassword from "../Login/ChangePassword";
import { RxHamburgerMenu } from "react-icons/rx";
import FranchiseSidebar from "../../Franchise/Franchise/FranchiseSidebar";
import Sidebar from "./Sidebar";
import SidebarMobile from "./SidebarMobile";

const DashboardHeader = ({
  orderId,
  orderDate,
  franchiseOrderId,
  franchiseOrderDate,
  status,
  searchQuery,
  setSearchQuery,
}) => {
  const username = localStorage.getItem("username");
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [isLogoutVisible, setIsLogoutVisible] = useState(false); // State for logout visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [franchiseHeader, setFranchiseHeader] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    const newWidth = window.innerWidth;
    setWindowWidth(newWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (
      path === "/franchiseprofile" ||
      path === "/franchiseorder" ||
      path === "/franchiseproduct"
    ) {
      setFranchiseHeader(true);
    } else {
      setFranchiseHeader(false);
    }
  }, [path]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".right-section-d")) {
        console.log("Click occurred outside MyComponent.");
        setIsLogoutVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const getTitleFromPath = (path) => {
    switch (path) {
      case "/AdminDashboard":
        return "Dashboard";
      case "/dashboard":
        return "Franchise";
      case "/products":
        return "Products";
      case "/order":
        return "Orders";
      case "/OrderList":
        return "Orders";
      case "/franchiseproduct":
        return "Products";
      case "/franchiseorder":
        return "Orders";
      default:
        return "";
    }
  };

  const formatDate = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);

    const day = dateObject.getDate().toString().padStart(2, "0");
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObject.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const logout = () => {
    window.location.href = "/login";
    window.localStorage.clear();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
  };

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  return (
    <div>
      <div className="parent-d-header">
        <div className="left-section-d">
          <div style={{ textAlign: "left" }}>
            <div>
              <div className="status__parent">
                <div>
                  <h3>
                    {(orderId && orderId) ||
                      (franchiseOrderId && franchiseOrderId) ||
                      getTitleFromPath(path)}
                  </h3>
                </div>
                {path === "/franchiseproduct" && (
                  <div>
                    <input
                      className="product-searchbox"
                      type="text"
                      placeholder="Search Product"
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                  </div>
                )}
                <div>
                  {status && status === "Approved" ? (
                    <div className="approved_ribu1">
                      <BsDot
                        style={{
                          fontSize: "27px",
                          position: "absolute",
                          left: "0%",
                        }}
                      />{" "}
                      {status}
                    </div>
                  ) : status === "Rejected" ? (
                    <div className="rejected_ribu">
                      <BsDot
                        style={{
                          fontSize: "27px",
                          position: "absolute",
                          left: "0%",
                        }}
                      />{" "}
                      {status}
                    </div>
                  ) : status === "Pending" ? (
                    <div className="pending_ribu">
                      <BsDot
                        style={{
                          fontSize: "27px",
                          position: "absolute",
                          left: "0%",
                        }}
                      />{" "}
                      {status}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div>
              <p className="order-date">
                {(orderDate && formatDate(orderDate)) ||
                  (franchiseOrderDate && formatDate(franchiseOrderDate))}
              </p>
            </div>
          </div>
        </div>
        <div
          className="parent__right-section-d"
          style={{ position: "relative", cursor: "pointer" }}
          onClick={() => setIsLogoutVisible(!isLogoutVisible)}
        >
          <div className="right-section-d">
            <img src={userlogo} alt="" />
            <p style={{ color: "#193B68" }}>
              {username} <IoIosArrowDown style={{ color: "#255FE8" }} />
            </p>
          </div>
          {isLogoutVisible && (
            <div className="logout_option">
              <p style={{ color: "#0D1217" }} onClick={showModal}>
                Change Password
              </p>
              <p style={{ color: "#0D1217" }} onClick={logout}>
                Log Out
              </p>
            </div>
          )}
        </div>
        <div className="dasboard_hamburger" onClick={toggleSidebar}>
          <RxHamburgerMenu />
        </div>
        {isSidebarVisible && windowWidth <= 575 && franchiseHeader && (
          <FranchiseSidebar visibility={isSidebarVisible} />
        )}
        {isSidebarVisible && windowWidth <= 575 && !franchiseHeader && (
          <Sidebar visibility={isSidebarVisible} />
        )}
      </div>
      <ChangePassword isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default DashboardHeader;
