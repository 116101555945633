import React, { useState, useEffect } from "react";
import "./FranchiseLogin.css";
import Logo from "../../Dashboard/Dashboard/assets/welotaLogo_Dashboard.png";
import { Link, useLocation } from "react-router-dom";
import { RiDashboardLine } from "react-icons/ri";
import franchise from "../../Dashboard/Dashboard/assets/franchise.png";
import { IoBagRemoveSharp } from "react-icons/io5";
import { BsFillBagPlusFill } from "react-icons/bs";
import { PiTreeBold } from "react-icons/pi";
import { isVisible } from "@testing-library/user-event/dist/utils";

const FranchiseSidebar = ({ visibility }) => {
  const location = useLocation(); // Get the current location
  const [activeLink, setActiveLink] = useState(""); // State to manage the active link
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    const newWidth = window.innerWidth;
    setWindowWidth(newWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    // Determine the active link based on the current path
    if (location.pathname === "/franchiseprofile") {
      setActiveLink("franchise");
    } else if (location.pathname === "/franchiseproduct") {
      setActiveLink("productscreate");
    } else if (location.pathname === "/franchiseorder") {
      setActiveLink("orderfranchise");
    }
  }, [location.pathname]);

  return (
    <div
      className={
        windowWidth > 575
          ? "sidebar"
          : windowWidth <= 575 && visibility
          ? "sidebar"
          : "sidebar_hidden"
      }
    >
      <div className="parent-sidebar">
        <div className="dasboard-log">
          <img src={Logo} alt="" />
        </div>
        <div className="sidebars">
          {/* <Link to="" className="dashboards">
            <RiDashboardLine /> Dashboard
          </Link> */}
          <Link
            to="/franchiseprofile"
            className={`franchise ${
              activeLink === "franchise" ? "active-link" : ""
            }`}
            onClick={() => {
              if (activeLink === "franchise") {
                window.location.href = "/franchiseprofile";
              }
            }}
          >
            {/* <img src={franchise} alt="" /> */}
            <PiTreeBold /> Profile
          </Link>
          <Link
            to="/franchiseproduct"
            className={`franchise2 ${
              activeLink === "productscreate" ? "active-link" : ""
            }`}
            onClick={() => {
              if (activeLink === "productscreate") {
                window.location.href = "/franchiseproduct";
              }
            }}
          >
            <IoBagRemoveSharp /> Products
          </Link>
          <Link
            to="/franchiseorder"
            className={`franchise3 ${
              activeLink === "orderfranchise" ? "active-link" : ""
            }`}
            onClick={() => {
              if (activeLink === "orderfranchise") {
                window.location.href = "/franchiseorder";
              }
            }}
          >
            <BsFillBagPlusFill /> Orders
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FranchiseSidebar;
