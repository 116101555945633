import React from "react";
import { Modal } from "antd";
import deleteimg from "./assets/delete.png";
import axios from "axios";
import * as Urls from "../../Urls";

const DeleteProductModal = ({
  isOpen,
  onClose,
  productId,
  fetchProductList,
  fetchPaginatedProductList,
  productName,
}) => {
  const token = localStorage.getItem("authToken");

  const deleteProduct = async () => {
    try {
      if (!token) {
        console.error("No auth token found");
        return;
      }

      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };

      const response = await axios.put(
        Urls.deleteProduct + productId,
        null,
        config
      );
      if (response.data.results.status_code === 200) {
        fetchPaginatedProductList();
        fetchProductList();
        onClose();
      }

      console.log("successfully deleted", response);
    } catch (error) {
      console.log("Error deleting product:", error);
    }
  };

  return (
    <div>
      <Modal open={isOpen} onOk={onClose} onCancel={onClose} title="">
        <div className="product_creation">
          <div>
            <div className="delete_img">
              <img src={deleteimg} alt="" />
            </div>
            <div className="delete_text">
              <h3>Are you sure you want to Delete this Product</h3>
              <p>{productName}</p>
            </div>
            <div className="cancel_delete">
              <button className="cancel__btn" onClick={onClose}>
                Cancel
              </button>
              <button className="delete__btn" onClick={() => deleteProduct()}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteProductModal;
