import React, { useState, useRef, useEffect } from "react";
import { Modal } from "antd";
import uploadimage from "../Dashboard/assets/Vector.png";
import axios from "axios";
import * as Urls from "../../Urls";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../Components/Loader/Loader";
import Joi from "joi";
import { IoClose } from "react-icons/io5";

const CreateProductModal = ({
  isOpen,
  onClose,
  fetchProductList,
  fetchPaginatedProductList,
}) => {
  const token = localStorage.getItem("authToken");
  // image upload start
  const navigate = useNavigate();

  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [imageError, setImageError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [productImageName, setproductImageName] = useState("");
  const [formData, setFormData] = useState({
    productName: "",
    productDescription: "",
    productCategory: "",
    quantity: "",
    unitPrice: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    productName: "",
    productDescription: "",
    productCategory: "",
    quantity: "",
    unitPrice: "",
  });

  const schema = Joi.object({
    productName: Joi.string().required().messages({
      "string.empty": "Product name is required",
    }),
    productDescription: Joi.string().required().messages({
      "string.empty": "Product description is required",
    }),
    productCategory: Joi.string().required().messages({
      "string.empty": "Product category is required",
    }),
    quantity: Joi.number().integer().min(0).required().messages({
      "number.base": "Quantity is required and must be a number",
      "number.min": "Quantity cannot be a negative value",
    }),
    unitPrice: Joi.number().integer().min(0).required().messages({
      "number.base": "Unit Price is required must be a number",
      "number.min": "Unitprice cannot be a negative value",
    }),
  });

  const handleChooseFile = () => {
    fileInputRef.current.click();
    setImageError("");
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setImageError("");
  };

  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   setIsDragging(false);

  //   const file = e.dataTransfer.files[0];
  //   if (file) {
  //     setproductImageName(file.name);
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImagePreview(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   setproductImageName(file.name);
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     setImagePreview(reader.result);
  //   };
  //   reader.readAsDataURL(file);
  // };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      const allowedFormats = ["jpg", "jpeg", "png"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (!allowedFormats.includes(fileExtension)) {
        // Display an error message or take appropriate action for invalid format
        setImageError("Please select a JPG, JPEG, or PNG image.");
        return;
      }

      setproductImageName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedFormats = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedFormats.includes(fileExtension)) {
      // Display an error message or take appropriate action for invalid format
      setImageError("Please select a JPG, JPEG, or PNG image.");
      return;
    }

    setproductImageName(file.name);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  //Api Call for categories List
  const fetchCategories = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.get(Urls.getCategories, config);
      if (response.data.results.status_code === 200) {
        setCategoryList(response.data.results.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!imagePreview) {
      setImageError("Please select an image");
      return;
    }

    const validationResult = schema.validate(formData, { abortEarly: false });

    if (validationResult.error) {
      const newValidationErrors = {};
      validationResult.error.details.forEach((error) => {
        newValidationErrors[error.context.key] = error.message;
      });
      setValidationErrors(newValidationErrors);
      return;
    }

    const requestBody = {
      product_name: formData.productName,
      product_category: formData.productCategory,
      product_description: formData.productDescription,
      quantity: formData.quantity,
      price: formData.unitPrice,
      product_image: imagePreview,
    };

    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.post(
        Urls.createProduct,
        requestBody,
        config
      );
      if (response.data.results.message === "Succesfully saved") {
        console.log("Product created successfully", response);
        onClose();
        navigate("/products");
        fetchPaginatedProductList();
        fetchProductList();
        setFormData({
          productName: "",
          productDescription: "",
          productCategory: "",
          quantity: "",
          unitPrice: "",
        });
        setImagePreview(null);
      } else {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          productDescription:
            response.data.results.reason.product_description ||
            "An error occurred",
        }));
      }
    } catch (error) {
      console.log("Error creating product:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNumericKeyPress = (event) => {
    const allowedKeys = /[0-9]|\+|Tab|ArrowLeft|ArrowRight|c|a|x|Control|v/;

    if (!allowedKeys.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  return (
    <div>
      <Modal open={isOpen} onOk={onClose} onCancel={onClose} title="">
        <div className="product_creation">
          <form className="create_form" onSubmit={handleSubmit}>
            <h3 className="create_product_h3">Create Product</h3>
            <p
              style={{
                color: "#7589A4",
                fontFamily: "poppins",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              Fill up your product details here
            </p>
            <div className="product_creation_parent">
              <div className="thumbnail">
                <div className="attament-files">
                  <div
                    style={{ color: "#000" }}
                    className="drag_drop"
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    <div onClick={handleChooseFile}>
                      <div style={{ margin: "10px 0px" }}>
                        <img src={uploadimage} alt="" />
                      </div>
                      Drag & drop or <span>browse</span>{" "}
                    </div>
                    <p
                      style={{
                        textAlign: "center",
                        margin: "15px 0px",
                        color: "#A2C2D2",
                      }}
                    >
                      PNG / JPG / JPEG Images accepted
                    </p>
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    key={imagePreview}
                    style={{ display: "none" }}
                    // accept="image/*"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleFileChange}
                  />
                  {imageError && <p className="error">{imageError}</p>}
                  {imagePreview && (
                    <>
                      <div className="showimages">
                        <img
                          className="attachment-payments"
                          style={{ marginTop: "6px" }}
                          src={imagePreview}
                          alt="Selected"
                        />
                      </div>
                      <IoClose
                        className="img-close"
                        style={{ top: "15px" }}
                        onClick={() => {
                          setImagePreview(null);
                          fileInputRef.current.value = "";
                          setproductImageName("");
                        }}
                      />
                    </>
                  )}
                </div>
                <div className="file-name">{productImageName}</div>
              </div>
              <div className="name_field">
                <label for="fname">Product Name</label> <br />
                <input
                  type="text"
                  name="productName"
                  placeholder="Enter Product name"
                  value={formData.productName}
                  onChange={handleInputChange}
                />
                {validationErrors.productName && (
                  <p className="error">{validationErrors.productName}</p>
                )}
              </div>
              <div className="product_description">
                <label for="Description">Product Description</label> <br />
                <textarea
                  rows="5"
                  name="productDescription"
                  value={formData.productDescription}
                  placeholder="Enter product description here"
                  onChange={handleInputChange}
                />
                {validationErrors.productDescription && (
                  <p className="error">{validationErrors.productDescription}</p>
                )}
              </div>
              <div
                className={`qualification ${
                  formData.productCategory ? "selected" : ""
                }`}
              >
                <label>Product category</label>
                <select
                  name="productCategory"
                  id="category"
                  value={formData.productCategory}
                  onChange={handleInputChange}
                >
                  <option className="qualifications" value="">
                    Select a category
                  </option>
                  {categoryList.map((category) => (
                    <option
                      className="drop-select"
                      key={category.id}
                      value={category.id}
                    >
                      {category.name}
                    </option>
                  ))}
                </select>
                {validationErrors.productCategory && (
                  <p className="error">{validationErrors.productCategory}</p>
                )}
              </div>
              <div className="product_quantity">
                <label for="Quantity">Quantity</label> <br />
                <input
                  type="text"
                  name="quantity"
                  placeholder="Enter Quantity"
                  value={formData.quantity}
                  onChange={handleInputChange}
                  onKeyDown={handleNumericKeyPress}
                />
                {validationErrors.quantity && (
                  <p className="error">{validationErrors.quantity}</p>
                )}
              </div>
              <div className="product_unitprice">
                <label for="Unit_Price">Unit Price</label> <br />
                <input
                  type="text"
                  name="unitPrice"
                  placeholder="Enter Unit Price"
                  value={formData.unitPrice}
                  onChange={handleInputChange}
                  onKeyDown={handleNumericKeyPress}
                />
                {validationErrors.unitPrice && (
                  <p className="error">{validationErrors.unitPrice}</p>
                )}
              </div>
              <div className="create_order">
                {isLoading ? (
                  <Loader width={30} height={30} />
                ) : (
                  <div className="login-btn">
                    <button type="submit">Create Product</button>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default CreateProductModal;
