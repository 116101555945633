import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "../../Dashboard/Dashboard/DashboardHeader.js";
import HoverImage from "../../Dashboard/Dashboard/HoverImage.js";
import axios from "axios";
import * as Urls from "../../Urls";
import { useLocation } from "react-router-dom";
import "../../Dashboard/Franchise/Franchise.css";
import { BiSolidDownload } from "react-icons/bi";
import FranchiseSidebar from "./FranchiseSidebar";
import { Loader } from "../../Components/Loader/Loader.js";

const FranchiseProfile = () => {
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const location = useLocation();
  const [franchiseData, setFranchiseData] = useState(null);
  // const franchiseId = location.state?.data?.franchiseId;
  const franchiseId = localStorage.getItem("franchiseId");
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageHover, setImageHover] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false); // New state variable

  useEffect(() => {
    if (franchiseId) {
      fetchFranchiseProfile();
    }
  }, [franchiseId]);

  const handleNavigate = () => {
    window.location.href = "/login";
    window.localStorage.clear();
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
  };

  const fetchFranchiseProfile = async () => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.get(
        Urls.franchiseProfile + franchiseId,
        config
      );
      if (response?.data?.results?.data) {
        setFranchiseData(response.data.results.data);
      }
    } catch (error) {
      console.log("Error fetching data:", error.response.data.reason);
      if (error.response.data.message === "Invalid Token") {
        handleNavigate();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const handleDownload = async () => {
    try {
      setIsDownloading(true); // Set isLoading to true when starting download

      const response = await axios.get(Urls.downloadCirtificate + franchiseId);

      // Create a URL for the blob object
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element and click it to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "certificate.pdf"); // Set desired file name
      document.body.appendChild(link);
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error while downloading:", error);
    } finally {
      setIsDownloading(false); // Set isLoading back to false after download completes or fails
    }
  };

  // when clicking its downloading but getting error
  // const handleDownload = async () => {
  //   const blobProvider = (
  //     <BlobProvider document={<PDFDocument />}>
  //       {({ blob, url, loading, error }) => {
  //         if (loading) {
  //           // Handle loading state if needed
  //         } else if (blob) {
  //           fileDownload(blob, "certificate.pdf"); // Trigger the download
  //         }
  //       }}
  //     </BlobProvider>
  //   );

  //   // Render the BlobProvider component to generate the PDF and trigger the download
  //   await new Promise((resolve) => setTimeout(resolve, 100)); // Wait for rendering (optional delay)
  //   const blobProviderElement = document.createElement("div");
  //   ReactDOM.render(blobProvider, blobProviderElement);
  //   blobProviderElement.firstChild.click(); // Trigger the click event to initiate the download
  //   ReactDOM.unmountComponentAtNode(blobProviderElement);
  // };
  // pop up modal
  // const handleDownload = async () => {
  //   const blobProvider = (
  //     <BlobProvider document={<PDFDocument />}>
  //       {({ blob }) => {
  //         if (blob) {
  //           fileDownload(blob, "certificate.pdf"); // Trigger the download
  //         }
  //       }}
  //     </BlobProvider>
  //   );

  //   // Create a temporary anchor element
  //   const a = document.createElement("a");
  //   a.style.display = "none";

  //   // Set the Blob URL as the href
  //   const blobUrl = URL.createObjectURL(
  //     new Blob([blobProvider.props.document.props.blob])
  //   );
  //   a.href = blobUrl;

  //   // Set the download attribute and trigger a click event
  //   a.download = "certificate.pdf";
  //   document.body.appendChild(a);
  //   a.click();

  //   // Clean up
  //   document.body.removeChild(a);
  //   URL.revokeObjectURL(blobUrl);
  // };

  if (isLoading) {
    return (
      <div className="loading-spinner">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <FranchiseSidebar />
      <DashboardHeader
        franchiseOrderId={franchiseData?.request_id}
        franchiseOrderDate={franchiseData?.date}
      />

      <div
        style={{
          background: "#F8F9FB",
          padding: "30px 0px",
          paddingBottom: "50px",
        }}
      >
        <div className="F-deatils">
          <div className="F-datas">
            <div className="F-left">
              <h3>{franchiseData?.username}</h3>
              <div className="F-dob">
                <p style={{ color: "#cfcfcf" }}>DOB</p>
                <p style={{ color: "#193B68" }}>
                  {formatDate(franchiseData?.DateOfBirth)}
                </p>
              </div>
              <div className="F-email">
                <p style={{ color: "#cfcfcf" }}>Email</p>
                <p style={{ color: "#193B68" }}>{franchiseData?.email}</p>
              </div>
              <div className="F-address" style={{ width: "360px" }}>
                <p style={{ color: "#cfcfcf" }}>Address </p>
                <p style={{ color: "#193B68" }}>{franchiseData?.address}</p>
              </div>
            </div>
            <div className="F-center">
              <div className="F-sex">
                <p style={{ color: "#cfcfcf" }}>Sex</p>
                <p style={{ color: "#193B68" }}>{franchiseData?.gender}</p>
              </div>
              <div className="F-phone">
                <p style={{ color: "#cfcfcf" }}>Mobile </p>
                <p style={{ color: "#193B68" }}>
                  {franchiseData?.mobile_number}
                </p>
              </div>
            </div>
            <div className="F-end">
              <div className="download-btn" onClick={handleDownload}>
                {isDownloading ? (
                  <Loader />
                ) : (
                  <button>
                    Download Certificate
                    <BiSolidDownload style={{ marginLeft: "5px" }} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* second section start */}
        <div className="parent_address_payement">
          <div className="F-address-payment-main">
            <div className="F-left_address">
              <div className="F-left-inner">
                <h3 className="F-inner-h3">Address and Other</h3>
                <div className="F-qualification">
                  <p className="F-A-C">Qualification </p>
                  <p style={{ color: "#193B68" }}>
                    {franchiseData?.qualification}
                  </p>
                </div>
                <div className="F-panchayath">
                  <p className="F-A-C">
                    {franchiseData?.panchayath_name
                      ? "Panchayath"
                      : franchiseData?.corporation_name
                      ? "Corporation"
                      : "Municipality"}
                  </p>
                  <p style={{ color: "#193B68" }}>
                    {franchiseData?.panchayath_name ||
                      franchiseData?.corporation_name ||
                      franchiseData?.municipality_name}
                  </p>
                </div>
                <div className="F-nominee">
                  <p className="F-A-C">Nominee Name</p>
                  <p style={{ color: "#193B68" }}>
                    {franchiseData?.nominee_name}
                  </p>
                </div>
              </div>
              <div className="F-right-inner">
                <div className="F-district">
                  <p className="F-A-C">District </p>
                  <p style={{ color: "#193B68" }}>
                    {franchiseData?.district_name}
                  </p>
                </div>
                <div className="F-ward">
                  <p className="F-A-C">Ward </p>
                  <p style={{ color: "#193B68" }}>
                    {franchiseData?.ward_number}
                  </p>
                </div>
                <div className="F-relation">
                  <p className="F-A-C">Relationship </p>
                  <p style={{ color: "#193B68" }}>
                    {franchiseData?.relationship}
                  </p>
                </div>
              </div>
            </div>
            <div className="F-right-payment">
              <div className="F-payment-left">
                <h3 className="F-inner-h3">Payment</h3>
                <div className="F-payment-method">
                  <p className="F-A-C">Payment Method </p>
                  <p style={{ color: "#193B68" }}>
                    {franchiseData?.payment_type}
                  </p>
                </div>
                {/* <div className="F-upi-id">
                  <p className="F-A-C">UPI ID </p>
                  <p style={{ color: "#193B68" }}>{franchiseData?.upi_id}</p>
                </div> */}
              </div>
              {franchiseData?.attachment && (
                <div className="F-payment-img">
                  <img
                    style={{
                      height: "220px",
                      width: "220px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowImageModal(true);
                      setImageHover(franchiseData?.attachment);
                    }}
                    src={franchiseData?.attachment}
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* second section end */}

        {/* third section start */}
        <div className="parent_adharcard">
          <div className="aadharcard_details">
            <div className="aadhar_main_left">
              <h3>Passbook</h3>
              <img
                style={{
                  height: "150px",
                  width: "210px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowImageModal(true);
                  setImageHover(franchiseData?.bank_passbook_image);
                }}
                src={franchiseData?.bank_passbook_image}
                alt=""
              />
            </div>
            <div className="aadhar-main-right">
              <div className="bank-account-numbers">
                <p className="F-A-C">Bank Account Number </p>
                <p style={{ color: "#193B68" }}>
                  {franchiseData?.bank_account_number}
                </p>
              </div>
            </div>
          </div>

          <div className="aadharcard_details">
            <div className="aadhar_main_left">
              <h3>Pan Card</h3>
              <img
                style={{
                  width: "210px",
                  height: "150px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowImageModal(true);
                  setImageHover(franchiseData?.pancard_image);
                }}
                src={franchiseData?.pancard_image}
                alt=""
              />
            </div>
            <div className="aadhar-main-right">
              <div className="aadha-card-numbers">
                <p className="F-A-C">Pan Card Number </p>
                <p style={{ color: "#193B68" }}>
                  {franchiseData?.pancard_number}
                </p>
              </div>
            </div>
          </div>
          <div className="aadharcard_details">
            <div className="aadhar_main_left">
              <h3>Confidentials</h3>
              <img
                style={{
                  height: "150px",
                  width: "210px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowImageModal(true);
                  setImageHover(franchiseData?.adhar_image);
                }}
                src={franchiseData?.adhar_image}
                alt=""
              />
              {franchiseData?.adhar_imageback !== null ? (
                <img
                  style={{
                    height: "150px",
                    width: "210px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowImageModal(true);
                    setImageHover(franchiseData?.adhar_imageback);
                  }}
                  src={franchiseData?.adhar_imageback}
                  alt=""
                />
              ) : null}
            </div>
            <div className="aadhar-main-right">
              <div className="aadha-card-numbers">
                <p className="F-A-C">Aadhar Card Number </p>
                <p style={{ color: "#193B68" }}>
                  {franchiseData?.adhar_number}
                </p>
              </div>
              {/* <div className="bank-account-numbers">
                <p className="F-A-C">Bank Account Number </p>
                <p style={{ color: "#193B68" }}>
                  {franchiseData?.bank_account_number}
                </p>
              </div> */}
            </div>
          </div>
        </div>
        {/* third section end */}
      </div>
      <HoverImage
        isOpen={showImageModal}
        onClose={handleCloseImageModal}
        image={imageHover}
      />
    </div>
  );
};

export default FranchiseProfile;
