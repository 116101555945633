import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Banner from "../Banner/Banner";
import Income from "../Income/Income";
import Business from "../Business/Business";
import Network from "../Network/Network";
import Question from "../Question/Question";
import Footer from "../Footer/Footer";
import RegisterModal from "../Register Modal/RegisterModal";
import TermsModal from "../Terms Modal/TermsModal";
import { useNavigate } from "react-router-dom";

function Landingpage() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  useEffect(() => {
    userType && userType === " Admin"
      ? navigate("/AdminDashboard")
      : userType === " Franchise" && navigate("/franchiseprofile");
  }, [userType]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isTerms, setIsTerms] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeTermsModal = () => {
    setIsTermsModalOpen(false);
  };

  const handleTermsClick = (value) => {
    if (value === true) {
      setIsTermsModalOpen(true);
    }
  };

  const updatedTerms = (value) => {
    setIsTerms(value);
  };

  return (
    <div>
      <RegisterModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onTermsClick={handleTermsClick}
        terms={isTerms}
      />
      <TermsModal
        isOpen={isTermsModalOpen}
        onClose={closeTermsModal}
        onTermsChange={updatedTerms}
      />
      <Navbar onRegisterNowClick={openModal} />
      <Banner onRegisterNowClick={openModal} />
      <Income />
      <Business onRegisterNowClick={openModal} />
      <Network />
      <Question onRegisterNowClick={openModal} />
      <Footer onRegisterNowClick={openModal} />
    </div>
  );
}

export default Landingpage;
