export const ProductDetails = [
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
  {
    image: "bag.png",
    price: 570.0,
    productdescription: "Nayasa Plastic Square Deluxe Bathroom Buck...",
  },
];
