import React, { useState } from "react";
import "./Banner.css";
import Bannerimage from "../Banner/assets/banner.png";
// import bgimage from "../Banner/assets/bgimage.png";
import bgelement from "../Banner/assets/webpanjayath.png";

function Banner({ onRegisterNowClick }) {
  const handleRegisterNowClick = () => {
    onRegisterNowClick();
  };
  return (
    <div>
      <div className="home section" id="home">
        {/* <img src={} alt="" /> */}
        <div className="home__container container">
          <div className="parent-banner">
            <div
              className="left-section"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <p className="ibe">Invest, Franchise, Earn</p>
              <h2 className="w-business">
                <span className="b-main-text">Become</span> <br /> our
                Franchisee.
              </h2>
              <p className="w-para1">Unlock Financial Freedom through us.</p>
              <button className="register-now" onClick={handleRegisterNowClick}>
                Register Now
              </button>
            </div>
            <div className="right-section">
              <img
                data-aos="fade-up"
                data-aos-duration="3000"
                className="bannerimage"
                src={Bannerimage}
                alt=""
              />
              <p className="m-business">Make your business</p>
              <img
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500"
                className="bgelement"
                src={bgelement}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
