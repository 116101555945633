import React, { useState, useRef, useEffect } from "react";
import { Modal } from "antd";
import "./RegisterModal.css";
import { Loader } from "../Loader/Loader";
import * as Urls from "../../Urls";
import axios from "axios";
import { format, parse } from "date-fns"; // Import date-fns functions
import DatePicker from "react-datepicker"; // Import the DatePicker component
import "react-datepicker/dist/react-datepicker.css";
import successimg from "./assets/success.png";
import { IoClose } from "react-icons/io5";
import { Select } from "antd";
import TermsModal from "../../Components/Terms Modal/TermsModal";
import { FiPlus } from "react-icons/fi";
import { BsQrCodeScan } from "react-icons/bs";
import qrcodes from "./assets/qrcode.jpeg";

const RegisterModal = ({ isOpen, onClose, onTermsClick }) => {
  // image upload start
  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRefAdharback = useRef(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedAadhar, setselectedAadhar] = useState(null);
  const [selectedAadhar2, setselectedAadhar2] = useState(null);
  const [selectedpancard, setselectedPancard] = useState(null);
  const [selectedPassbook, setSelectedPassbook] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [fileupload, setFileupload] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [adharEror, setAdharEror] = useState("");
  const [pancardError, setPancardError] = useState("");
  const [cashAttachment, setCashAttachment] = useState("");
  const [isLoading, setIsLoading] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [imageUrl, setImageUrl] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [panchayaths, setPanchayaths] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedAuthority, setSelectedAuthority] = useState("");
  const [districtId, setDistrictId] = useState();
  const [errors, setErrors] = useState();
  const [availabilityCheckError, setAvailabilityCheckError] = useState("");
  const [wardList, setWardList] = useState([]);
  const [adharImagesContainer, setAdharImagesContainer] = useState(false);
  const [isRegistrationSuccess, setIsRegistrationSuccess] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedAdharFileName, setSelectedAdharFileName] = useState("");
  const [selectedAdharBackFileName, setSelectedAdharBackFileName] =
    useState("");
  const [selectedPanFileName, setSelectedPanFileName] = useState("");
  const [selectedPassbookFilename, setSelectedPassbookFilename] = useState("");
  const [panchayathFilteredOptions, setPanchayathFilteredOptions] = useState(
    []
  );
  const [munipalityFilteredOptions, setMunipalityFilteredOptions] = useState(
    []
  );
  const [corporationFilterOptions, setCorporationFilterOptions] = useState([]);

  const [muncipalityList, setMuncipalityList] = useState([]);
  const [corporationList, setCorporationList] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [wardFilteredOptions, setWardFilteredOptions] = useState([]);
  const [selectedAttachmentFileName, setSelectedAttachmentFileName] =
    useState("");
  const [formValues, setFormValues] = useState({
    fname: "",
    aadharcard: "",
    pancard: "",
    payment_method: "",
    ifsc: "",
    bank: "",
    email: "",
    dob: "",
    mobile: "",
    qualification: "",
    address: "",
    district: "",
    corporation: "",
    newcorporation: "",
    municipality: "",
    authority: "",
    ward: "",
    nominee: "",
    relation: "",
    terms: false,
  });

  const [formErrors, setFormErrors] = useState({});

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [districtResponse, qualificationResponse] = await Promise.all([
          axios.get(Urls.getDistrict),
          axios.get(Urls.getQualification),
        ]);

        const mappedqualification = qualificationResponse.data.results.data.map(
          (qualification) => ({
            // label: district.name,
            label:
              qualification.name.charAt(0).toUpperCase() +
              qualification.name.slice(1).toLowerCase(),
            value: qualification.id,
          })
        );

        const mappedDistricts = districtResponse.data.results.data.map(
          (district) => ({
            // label: district.name,
            label:
              district.name.charAt(0).toUpperCase() +
              district.name.slice(1).toLowerCase(),
            value: district.id,
          })
        );

        setDistricts(mappedDistricts);
        setQualification(mappedqualification);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const relationship = [
    "Father",
    "Mother",
    "Brother",
    "Friend",
    "Husband",
    "Wife",
    "Son",
    "Daughter",
    "Sister",
    "Grandson",
    "Granddaughter",
    "Mother in law",
    "Father in law",
    "Other",
  ];
  const mappedrelationship = relationship.map((relationship) => ({
    value: relationship,
  }));

  const payment_method = ["UPI ID", "Cash"];
  const mappedpayment_method = payment_method.map((payment_method) => ({
    value: payment_method,
  }));

  const isEmpty = (value) => value.trim() === "";
  const isValidEmail = (email) => {
    // const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
    const mailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/;
    return emailRegex.test(email) && mailRegex.test(email);
  };

  const isValidPhoneNumber = (mobile) => {
    const mobileRegex = /^[0]?[6789]\d{9}$/;
    return mobileRegex.test(mobile);
  };
  const validateTab1 = () => {
    const errors = {};

    if (isEmpty(formValues.fname)) {
      errors.fname = "Full Name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(formValues.fname)) {
      errors.fname = "Full Name should only contain characters and spaces";
    }

    if (isEmpty(formValues.email)) {
      errors.email = "Email Address is required";
    } else if (!isValidEmail(formValues.email.toLowerCase())) {
      errors.email = "Invalid Email Address";
    } else if (formErrors.email) {
      errors.email = "Email already registered";
    }

    if (isEmpty(formValues.dob)) {
      errors.dob = "Enter date of birth";
    }

    if (isEmpty(formValues.mobile)) {
      errors.mobile = "Enter phone number";
    } else if (!isValidPhoneNumber(formValues.mobile)) {
      errors.mobile = "Invalid Phone Number";
    } else if (formErrors.mobile) {
      errors.mobile = "Phone number already registered";
    }

    if (!selectedGender) {
      errors.gender = "Select your gender";
    }

    if (isEmpty(formValues.address)) {
      errors.address = "Enter address";
    }

    return errors;
  };
  const validateTab2 = () => {
    const errors = {};

    if (!formValues.qualification) {
      errors.qualification = "Qualification is required";
    }

    if (!formValues.district) {
      errors.district = "District is required";
    }

    if (!formValues.authority) {
      errors.authority = "Please select your authority";
    }
    if (
      !formValues.corporation &&
      !formValues.newcorporation &&
      !formValues.municipality
    ) {
      errors.corporation = "Authority is required";
    }

    if (!formValues.authority) {
      errors.authority = "Select your authority";
    }

    if (!formValues.ward) {
      errors.ward = "ward is required";
    }

    if (availabilityCheckError) {
      errors.availabilityCheckError = availabilityCheckError;
    }
    if (isEmpty(formValues.nominee)) {
      errors.nominee = "Nominee is required";
    } else if (!/^[a-zA-Z\s]*$/.test(formValues.nominee)) {
      errors.nominee = "Full name should only contain characters and spaces";
    }

    if (isEmpty(formValues.relation)) {
      errors.relation = "Relation is required";
    }

    return errors;
  };

  const validateTab3 = () => {
    const errors = {};
    if (selectedAadhar === null) {
      errors.adharImage = "Please upload your front side of aadhar";
    }

    if (!adharImagesContainer) {
      errors.aharChoose = "Please upload your aadhar images";
    }

    if (selectedAadhar2 === null) {
      errors.adharImageBack = "Please upload your back side of aadhar";
    }

    if (selectedpancard === null) {
      errors.pancardImage = "Please upload your pan Card";
    }

    if (formValues.aadharcard === "") {
      errors.aadharcard = "Aadhar Card Number is required";
    } else if (!/^\d{12}$/.test(formValues.aadharcard)) {
      errors.aadharcard = "Aadhar card should only contain up to 12 digits";
    }

    if (formValues.pancard === "") {
      errors.pancard = "Pan card number is required";
    } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(formValues.pancard)) {
      errors.pancard = "Invalid PAN card format";
    }

    if (formValues.ifsc === "") {
      errors.ifsc = "IFSC is required";
    } else if (bankBranch === "Invalid IFSC") {
      errors.ifsc = "Invalid IFSC";
    }

    if (formValues.bank === "") {
      errors.bank = "Account number is required";
    } else if (formValues.bank.length < 9 || formValues.bank.length > 18) {
      errors.bank = "Bank Account Number should be between 9 and 18 digits";
    }
    if (formValues.confirmbank === "") {
      errors.confirmbank = "Confirm bank account number is required";
    }

    if (selectedPassbook === null) {
      errors.passbookImage = "Please upload your passbook attachment";
    }

    if (formValues.payment_method === "") {
      errors.paymentMethod = "Please select a payment method";
    }

    if (formValues.payment_method === "UPI ID" && selectedImage === "") {
      errors.imageAttachment = "Please upload your payment attachment";
    }

    if (formValues.terms === false) {
      errors.terms = "Please accept the terms and condition";
    }
    return errors;
  };

  const scrollToTop = () => {
    var modalContent = document.querySelector(".ant-modal-wrap");
    modalContent.scrollTop = 0;
  };

  const handleNextTab = () => {
    let isValid = true;
    let errors = {};

    if (activeTab === 1) {
      errors = validateTab1();
      isValid = Object.keys(errors).length === 0;
    } else if (activeTab === 2) {
      errors = validateTab2();
      isValid = Object.keys(errors).length === 0;
    } else if (activeTab === 3) {
      errors = validateTab3();
      isValid = Object.keys(errors).length === 0;
      console.log("formErrors.length()----------------", Object.keys(errors));
      if (isValid) {
        registerForm();
      }
    }

    if (isValid) {
      setFormErrors({});
      setActiveTab((prevTab) => prevTab + 1);
      activeTab !== 3 && scrollToTop();
    } else {
      setFormErrors(errors);
    }
  };
  const handlePreviousTab = () => {
    setActiveTab((prevTab) => prevTab - 1);
  };

  const handleChooseFile = () => {
    fileInputRef.current.click();
    setCashAttachment("");
  };

  const handleChooseFiles = () => {
    fileInputRef2.current.click();
  };

  const handleChooseAdharBackFiles = () => {
    fileInputRefAdharback.current.click();
  };
  const handleChooseFilespancrad = () => {
    fileInputRef3.current.click();
    setPancardError("");
  };
  const handleChooseFilesPassBook = () => {
    fileInputRef4.current.click();
    // setPassbookError("");
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedFormats = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedFormats.includes(fileExtension)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        imageAttachment: "Please select a JPG, JPEG, or PNG image.",
      }));
      return;
    }
    if (file) {
      setSelectedAttachmentFileName(file.name);

      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        imageAttachment: "",
      }));
    } else {
      setSelectedAttachmentFileName("");
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const allowedFormats = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedFormats.includes(fileExtension)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        adharImage: "Please select a JPG, JPEG, or PNG image.",
      }));
      return;
    }
    if (file) {
      setSelectedAdharFileName(file.name);

      const reader = new FileReader();
      reader.onloadend = () => {
        setselectedAadhar(reader.result);
      };
      reader.readAsDataURL(file);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        adharImage: "",
      }));
    } else {
      setSelectedAdharFileName("");
    }
  };
  const handleAdharImageUpload = (event) => {
    const file = event.target.files[0];
    const allowedFormats = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedFormats.includes(fileExtension)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        adharImageBack: "Please select a JPG, JPEG, or PNG image.",
      }));
      return;
    }
    if (file) {
      setSelectedAdharBackFileName(file.name);

      const reader = new FileReader();
      reader.onloadend = () => {
        setselectedAadhar2(reader.result);
      };
      reader.readAsDataURL(file);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        adharImageBack: "",
      }));
    } else {
      setSelectedAdharBackFileName("");
    }
  };
  const handleImageUploadpancard = (event) => {
    const file = event.target.files[0];
    const allowedFormats = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedFormats.includes(fileExtension)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        pancardImage: "Please select a JPG, JPEG, or PNG image.",
      }));
      return;
    }
    if (file) {
      setSelectedPanFileName(file.name);

      const reader = new FileReader();
      reader.onloadend = () => {
        setselectedPancard(reader.result);
      };
      reader.readAsDataURL(file);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        pancardImage: "",
      }));
    } else {
      setSelectedPanFileName("");
    }
  };

  const handleImageUploadPassbook = (event) => {
    const file = event.target.files[0];
    const allowedFormats = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedFormats.includes(fileExtension)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        passbookImage: "Please select a JPG, JPEG, or PNG image.",
      }));
      return;
    }
    if (file) {
      setSelectedPassbookFilename(file.name);

      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedPassbook(reader.result);
      };
      reader.readAsDataURL(file);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        passbookImage: "",
      }));
    } else {
      setSelectedPassbookFilename("");
    }
  };

  const handleConfirmBankBlur = () => {
    if (formValues.bank && formValues.confirmbank?.length > 0) {
      if (formValues.confirmbank !== formValues.bank) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          confirmbank: "Bank Account Numbers do not match",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          confirmbank: "",
        }));
      }
    }
  };

  const handleChange = async (e) => {
    const { name, value, type, checked } = e.target;

    const fieldValue =
      type === "checkbox"
        ? checked
        : name === "pancard" || name === "ifsc"
        ? value.toUpperCase()
        : value;

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    if (name === "ifsc") {
      if (value.length !== 11) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          ifsc: "IFSC code should be 11 characters long",
        }));
        setBankBranch("");
        setBankName("");
      } else if (!/^[A-Za-z]{4}0[A-Z0-9]{6}$/.test(value)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          ifsc: "Invalid IFSC code format",
        }));
        setBankBranch("");
        setBankName("");
      } else {
        getBankBranch(value);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          ifsc: "",
        }));
      }
    }

    if (name === "pancard") {
      if (value?.length !== 10) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          pancard: "PAN card format should 10 digits alphanumeric characters",
        }));
      } else if (!/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(value)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          pancard: "Invalid pancard number",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          pancard: "",
        }));
      }
    }

    if (name === "confirmbank") {
      setFormValues({ ...formValues, confirmbank: value });
    }
    if (name === "relations") {
      setSelectedGender(value);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        gender: "",
      }));
    }

    if (name === "authority") {
      setWardFilteredOptions([]);
      setWardList([]);
      setFormValues({
        ...formValues,
        authority: value,
        newcorporation: null,
        municipality: null,
        corporation: null,
        ward: null,
      });
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        authority: "",
      }));
    }

    if (name === "district") {
      const districtId = value;
      setDistrictId(districtId);
      try {
        const panchayathResponse = await axios.get(
          `${Urls.getPunchayath}?dist=${districtId}`
        );
        if (panchayathResponse?.data?.results.message === "Fail") {
          setPanchayaths([]);
          alert(panchayathResponse.data.results.reason);
        } else if (panchayathResponse.data.results.message === "Success") {
          setPanchayaths(panchayathResponse.data.results.data);
        }
      } catch (error) {
        console.error("Error fetching panchayath data:", error);
      }
    }

    if (name === "bank") {
      if (!/^\d+$/.test(value)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          bank: "Bank Account Number should only contain digits",
        }));
      } else if (value.length < 9 || value.length > 18) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          bank: "Bank Account Number should be between 9 and 18 digits",
        }));
      }
    }

    if (name === "aadharcard") {
      if (!/^\d{12}$/.test(value)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Aadhar Card should only contain up to 12 digits",
        }));
      }
    }

    if (name === "dob" && fieldValue) {
      const parsedDate = parse(fieldValue, "dd-MM-yyyy", new Date());
      const formattedDate = format(parsedDate, "dd-MM-yyyy");
      setFormValues((prevState) => ({
        ...prevState,
        dob: formattedDate,
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        [name]: fieldValue,
      }));
    }
  };

  const getPanchayath = async (district_id) => {
    try {
      const panchayathResponse = await axios.get(
        `${Urls.getPunchayath}?dist=${district_id}`
      );
      if (panchayathResponse?.data?.results.message === "Fail") {
        setPanchayaths([]);
      } else if (panchayathResponse.data.results.message === "Success") {
        const mappedDistricts = panchayathResponse.data.results.data.map(
          (panchayath) => ({
            label: panchayath.name,
            value: panchayath.id,
          })
        );
        setPanchayaths(mappedDistricts);
      }
    } catch (error) {
      console.error("Error fetching panchayath data:", error);
    }
  };

  const getMuncipality = async (districtId) => {
    try {
      const muncipalityResponse = await axios.get(
        // `${Urls.getMuncipality}${districtId}`
        Urls.getMuncipality + districtId
      );
      if (muncipalityResponse?.data?.results.message === "Fail") {
        setMuncipalityList([]);
        // alert(muncipalityResponse.data.results.reason);
      } else if (muncipalityResponse.data.results.message === "Success") {
        const mappedMunicipalities = muncipalityResponse.data.results.data.map(
          (muncipality) => ({
            label: muncipality.name,
            value: muncipality.id,
          })
        );
        setMuncipalityList(mappedMunicipalities);
      }
    } catch (error) {
      console.error("Error fetching panchayath data:", error);
    }
  };

  const getCorporation = async (districtId) => {
    try {
      const corporationResponse = await axios.get(
        // `${Urls.getMuncipality}${districtId}`
        Urls.getCorporation + districtId
      );
      if (corporationResponse?.data?.results.message === "Fail") {
        setCorporationList([]);
      } else if (corporationResponse.data.results.message === "Success") {
        const mappedCorporations = corporationResponse.data.results.data.map(
          (corporation) => ({
            label: corporation.name,
            value: corporation.id,
          })
        );
        setCorporationList(mappedCorporations);
      }
    } catch (error) {
      console.error("Error fetching panchayath data:", error);
    }
  };

  const handleNumericKeyPress = (event) => {
    const allowedKeys = /[0-9]|\+|-|ArrowLeft|ArrowRight|c|a|x|Control|v/;

    if (!allowedKeys.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  const handleWardKeyPress = (event) => {
    const allowedKeys = /[0-9]|ArrowLeft|ArrowRight/;

    if (!allowedKeys.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleNextTab();
  };

  const registerForm = async () => {
    try {
      setIsLoading(true);
      const body = {
        username: formValues.fname,
        email: formValues.email.toLowerCase(),
        adhar_number: formValues.aadharcard,
        user_type: "2",
        bank_account_number: formValues.bank,
        DateOfBirth: formValues.dob,
        gender: selectedGender,
        mobile_number: formValues.mobile,
        phone_code: "+91",
        qualification: formValues.qualification,
        address: formValues.address,
        district: formValues.district,
        panchayath_master: formValues.corporation,
        corporation_master: formValues.newcorporation,
        municipality_master: formValues.municipality,
        ward_number: formValues.ward,
        nominee_name: formValues.nominee,
        relationship: formValues.relation,
        terms_and_conditions: formValues.terms,
        payment_type: formValues.payment_method,
        payment_id: null,
        payment_status: "success",
        active_status: "True",
        attachment: selectedImage,
        IFSC: formValues.ifsc,
        adhar_imageback: selectedAadhar2,
        adhar_image: selectedAadhar,
        pancard_image: selectedpancard,
        bank_passbook_image: selectedPassbook,
        pancard_number: formValues.pancard,
      };
      const response = await axios.post(Urls.register, body);
      if (response?.data?.results?.message === " Succesfully Registered") {
        onClose();
        setSelectedPayment("");
        setSelectedImage(null);
        setselectedAadhar(null);
        setselectedAadhar2(null);
        setselectedPancard(null);
        setSelectedPassbook(null);
        setBankBranch("");
        setBankName("");
        setActiveTab(1);
        console.log("RegisterForm", response.data);
        setFormValues({
          fname: "",
          aadharcard: "",
          payment_method: "",
          ifsc: "",
          bank: "",
          email: "",
          dob: "",
          mobile: "",
          qualification: "",
          address: "",
          district: "",
          corporation: "",
          ward: "",
          nominee: "",
          relation: "",
          terms: false,
        });
        setSelectedGender("");
        setIsRegistrationSuccess(true);
        setTimeout(() => {
          setIsRegistrationSuccess(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Error Posting data:", error);
    } finally {
      setIsLoading(null);
    }
  };

  // const [availabilityCheckUrl, setAvailabilityCheckUrl] = useState(null);

  const checkRegisterationAvailability = async () => {
    const availabilityCheckUrl =
      formValues.district && formValues.corporation && formValues.ward
        ? `${Urls.franchiseRegisterationCheck}?dist=${formValues.district}&panchayath=${formValues.corporation}&ward=${formValues.ward}`
        : formValues.district && formValues.newcorporation && formValues.ward
        ? `${Urls.franchiseRegisterationCheck}?dist=${formValues.district}&corporation=${formValues.newcorporation}&ward=${formValues.ward}`
        : `${Urls.franchiseRegisterationCheck}?dist=${formValues.district}&municipality=${formValues.municipality}&ward=${formValues.ward}`;

    try {
      const response = await axios.get(availabilityCheckUrl);
      if (response.data?.results?.message === "Fail") {
        setAvailabilityCheckError(response.data.results?.reason);
      } else {
        setAvailabilityCheckError("");
        console.log(response.data.results?.reason);
      }
      console.log("availabilityCheck:", response);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (
      (formValues.district && formValues.corporation && formValues.ward) ||
      (formValues.district && formValues.newcorporation && formValues.ward) ||
      (formValues.district && formValues.municipality && formValues.ward)
    ) {
      checkRegisterationAvailability();
    }
  }, [
    formValues.district,
    formValues.corporation,
    formValues.ward,
    formValues.newcorporation,
    formValues.municipality,
  ]);

  useEffect(() => {
    if (formValues.district && formValues.corporation) {
      getPanchayathWardList();
    }
    if (formValues.district && formValues.newcorporation) {
      getCorporationWardList();
    }
    if (formValues.district && formValues.municipality) {
      getMuncipalityWardList();
    }
  }, [
    formValues.district,
    formValues.corporation,
    formValues.newcorporation,
    formValues.municipality,
  ]);

  const getPanchayathWardList = async () => {
    try {
      const response = await axios.get(
        `${Urls.wardList}?dist=${formValues.district}&panchayath=${formValues.corporation}`
      );
      if (response.data?.results?.data?.ward) {
        const wardOptionsArray = response.data.results.data.ward.map(
          (ward) => ({
            value: ward,
            label: `Ward ${ward}`,
          })
        );
        setWardList(wardOptionsArray);
      } else {
        setWardList([]);
      }
      console.log("wards:", response);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const getCorporationWardList = async () => {
    try {
      const response = await axios.get(
        `${Urls.wardList}?dist=${formValues.district}&corporation=${formValues.newcorporation}`
      );
      if (response.data?.results?.data?.ward) {
        const wardOptionsArray = response.data.results.data.ward.map(
          (ward) => ({
            value: ward,
            label: `Ward ${ward}`,
          })
        );
        setWardList(wardOptionsArray);
      } else {
        setWardList([]);
      }
      console.log("wards:", response);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const getMuncipalityWardList = async () => {
    try {
      const response = await axios.get(
        `${Urls.wardList}?dist=${formValues.district}&municipality=${formValues.municipality}`
      );
      if (response.data?.results?.data?.ward) {
        const wardOptionsArray = response.data.results.data.ward.map(
          (ward) => ({
            value: ward,
            label: `Ward ${ward}`,
          })
        );
        setWardList(wardOptionsArray);
      } else {
        setWardList([]);
      }
      console.log("wards:", response);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const isEmailAvailable = async () => {
    try {
      const response = await axios.get(
        `${Urls.emailAvailable}?email=${formValues.email}`
      );
      if (response.data.results.message === "Email already registered") {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: response.data.results.message,
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
      console.log("email respose:", response);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (isValidEmail(formValues.email)) {
      isEmailAvailable();
    }
  }, [formValues.email]);

  const isMobileAvailable = async () => {
    try {
      const response = await axios.get(
        `${Urls.phoneAvailable}?phone=${formValues.mobile}`
      );
      if (response.data.results.message === "Phone number already registered") {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          mobile: response.data.results.message,
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          mobile: "",
        }));
      }
      console.log("phone respose:", response);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   if (formValues.mobile.length === 10) {
  //     isMobileAvailable();
  //   }
  // }, [formValues.mobile]);

  const [bankBranch, setBankBranch] = useState("");
  const [bankName, setBankName] = useState("");
  const getBankBranch = async (ifsc) => {
    const bank = await fetch(`https://ifsc.razorpay.com/${ifsc}`);
    const branchInfo = await bank.json();
    console.log("......bank", branchInfo);
    if (branchInfo === "Not Found") {
      setBankBranch("Invalid IFSC");
    } else {
      setBankBranch(branchInfo.BRANCH);
      setBankName(branchInfo.BANK);
    }
  };

  const modalRef = useRef();

  useEffect(() => {
    modalRef.current?.scrollIntoView({ block: "nearest" });
  }, [activeTab]);

  const onDistrictChange = async (value) => {
    setAvailabilityCheckError("");
    setWardFilteredOptions([]);
    setWardList([]);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      district: "",
    }));

    setFormValues({
      ...formValues,
      district: value,
      newcorporation: null,
      municipality: null,
      corporation: null,
      ward: null,
    });

    setFilteredOptions([]);
    getMuncipality(value);
    getCorporation(value);
    getPanchayath(value);
  };

  const onPanchayathChange = async (value) => {
    setAvailabilityCheckError("");
    setFormErrors({ ...formErrors, corporation: "" });
    setFormValues({ ...formValues, corporation: value, ward: null });
    setPanchayathFilteredOptions([]);
  };

  const onMuncipalityChange = async (value) => {
    setAvailabilityCheckError("");
    setFormErrors({ ...formErrors, corporation: "" });
    setFormValues({ ...formValues, municipality: value, ward: null });
    setMunipalityFilteredOptions([]);
  };

  const onCorporationChange = async (value) => {
    setAvailabilityCheckError("");
    setFormErrors({ ...formErrors, corporation: "" });
    setFormValues({ ...formValues, newcorporation: value, ward: null });
    setCorporationFilterOptions([]);
  };

  const onWardChange = async (value) => {
    setFormErrors({ ...formErrors, ward: "" });
    setFormValues({ ...formValues, ward: value });
    setWardFilteredOptions([]);
  };

  const onSearch = (inputValue) => {
    const filtered = districts.filter((district) =>
      district.label?.toLowerCase().includes(inputValue?.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const onPanchayathSearch = (inputValue) => {
    const filtered = panchayaths.filter((panchayath) =>
      panchayath.label?.toLowerCase().includes(inputValue?.toLowerCase())
    );
    setPanchayathFilteredOptions(filtered);
  };

  const onMuncipalitySearch = (inputValue) => {
    const filtered = muncipalityList.filter((municipality) =>
      municipality.label?.toLowerCase().includes(inputValue?.toLowerCase())
    );
    setMunipalityFilteredOptions(filtered);
  };

  const onCorporationSearch = (inputValue) => {
    const filtered = corporationList.filter((corporation) =>
      corporation.label?.toLowerCase().includes(inputValue?.toLowerCase())
    );
    setCorporationFilterOptions(filtered);
  };

  const onWardSearch = (inputValue) => {
    const filtered = wardList.filter((ward) =>
      ward.label?.toLowerCase().includes(inputValue?.toLowerCase())
    );
    setWardFilteredOptions(filtered);
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    const allowedFormats = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedFormats.includes(fileExtension)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        imageAttachment: "Please select a JPG, JPEG, or PNG image.",
      }));
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setSelectedImage(event.target.result);
      };
      reader.readAsDataURL(file);

      setIsFileSelected(true);
      setFormErrors({ imageAttachment: "" });
    }
  };

  const handleKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    // Check if the pressed key is not an alphabetic character (A-Z or a-z)
    if (
      !(keyCode >= 65 && keyCode <= 90) && // A-Z
      !(keyCode >= 97 && keyCode <= 122) // a-z
    ) {
      event.preventDefault();
    }
  };

  const qualificationChange = (value) => {
    setFormValues({
      ...formValues,
      qualification: value,
    });
    setFormErrors({ ...formErrors, qualification: "" });
  };

  const onRelationChange = (value) => {
    setFormValues({
      ...formValues,
      relation: value,
    });
    setFormErrors({ ...formErrors, relation: "" });
  };

  const onPaymentMethodChange = (value) => {
    setFormValues({
      ...formValues,
      payment_method: value,
    });
    setFormErrors({ ...formErrors, paymentMethod: "" });
    if (formErrors.imageAttachment && formValues.payment_method === "Cash") {
      setFormErrors({ ...formErrors, imageAttachment: "" });
    }
  };

  console.log("formValues", formValues);

  return (
    <>
      <Modal open={isOpen} onOk={onClose} onCancel={onClose} ref={modalRef}>
        <div className="parent_form">
          <div className="register_text">
            <h3>Register Now</h3>
            <p>
              You are just one step away from joining us. Fill out this form and
              become our Franchisee Partner right away.
            </p>
          </div>
          <div className="tabs-methods">
            <div className="tab1">
              <p
                className={`tabnumber ${
                  activeTab === 1 ? "tabnumber-active" : ""
                } ${
                  activeTab === 2 || activeTab === 3 ? "tabnumber-fill" : ""
                }`}
              >
                1
              </p>
              <p style={{ color: "#7589A4" }}>Personal Details</p>
            </div>
            <div
              className={`rod-line ${
                activeTab === 2 || activeTab === 3 ? "rod-line-fill" : ""
              }`}
            ></div>
            <div className="tab2">
              <p
                className={`tabnumber ${
                  activeTab === 2 ? "tabnumber-active" : ""
                } ${activeTab === 3 ? "tabnumber-fill" : ""}`}
              >
                2
              </p>
              <p style={{ color: "#7589A4" }}>Address & Other</p>
            </div>
            <div
              className={`rod-line-2 ${
                activeTab === 3 ? "rod-line-2-fill" : ""
              }`}
            ></div>
            <div className="tab3">
              <p
                className={`tabnumber ${
                  activeTab === 3 ? "tabnumber-active" : ""
                }`}
              >
                3
              </p>
              <p style={{ color: "#7589A4" }}>Confidential & Payments</p>
            </div>
          </div>
          <div className="form_parent">
            <form onSubmit={handleSubmit} autocomplete="off">
              {/* first tab */}
              {activeTab === 1 && (
                <div className="fisrt-tab">
                  <div className="personal-dat">
                    <h3>Personal Information</h3>
                  </div>
                  <div className="name_field">
                    <label for="fname">Full Name</label> <br />
                    <input
                      type="text"
                      name="fname"
                      value={formValues.fname}
                      placeholder="Enter Your Name"
                      onChange={handleChange}
                      required
                    />
                    {formErrors.fname && (
                      <p className="error">{formErrors.fname}</p>
                    )}
                  </div>
                  <div className="email_field">
                    <label for="email">Email Address</label> <br />
                    <input
                      type="email"
                      name="email"
                      value={formValues.email.toLowerCase()}
                      onChange={handleChange}
                      required
                      placeholder="sample@gmail.com"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}"
                    />
                    {formErrors.email && (
                      <p className="error">{formErrors.email}</p>
                    )}
                  </div>
                  <div className="parent_dob_gender">
                    <div className="dob_field">
                      <label htmlFor="dob">DOB</label> <br />
                      <DatePicker
                        name="dob"
                        selected={
                          formValues.dob
                            ? parse(formValues.dob, "dd-MM-yyyy", new Date())
                            : null
                        }
                        onChange={(date) => {
                          const formattedDate = date
                            ? format(date, "dd-MM-yyyy")
                            : "";
                          setFormValues((prevState) => ({
                            ...prevState,
                            dob: formattedDate,
                          }));
                          setFormErrors((prevState) => ({
                            ...prevState,
                            dob: "",
                          }));
                        }}
                        dateFormat="dd-MM-yyyy"
                        placeholderText="dd-MM-yyyy"
                        required
                        showMonthDropdown
                        showYearDropdown
                        maxDate={new Date()}
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                      {formErrors.dob && (
                        <p className="error">{formErrors.dob}</p>
                      )}
                    </div>
                    <div className="gender_field">
                      <label for="gender">Gender</label> <br />
                      <div className="parent_radio">
                        <div className="left_radio">
                          <input
                            className="radio_btns"
                            type="radio"
                            name="relations"
                            checked={selectedGender === "MALE" && "true"}
                            value="MALE"
                            onChange={handleChange}
                          />
                          <label htmlFor="html">Male</label>
                        </div>
                        <div className="right_radio">
                          <input
                            type="radio"
                            id="css"
                            name="relations"
                            checked={selectedGender === "FEMALE" && "true"}
                            value="FEMALE"
                            className="radio_btns"
                            onChange={handleChange}
                          />
                          <label htmlFor="css">Female</label>
                        </div>
                      </div>
                      {formErrors.gender && (
                        <p className="error">{formErrors.gender}</p>
                      )}
                    </div>
                  </div>
                  <div className="mobile_field">
                    <label for="mobile">Mobile Number</label> <br />
                    <input
                      type="numeric"
                      name="mobile"
                      maxLength={10}
                      value={formValues.mobile}
                      placeholder="+91 9896700070"
                      onChange={handleChange}
                      onKeyDown={handleNumericKeyPress}
                      required
                    />
                    {formErrors.mobile && (
                      <p className="error">{formErrors.mobile}</p>
                    )}
                  </div>
                  <div className="address_field">
                    <label for="address">Address</label> <br />
                    <textarea
                      rows="5"
                      maxLength={500}
                      name="address"
                      value={formValues.address}
                      onChange={handleChange}
                      required
                    />
                    {formErrors.address && (
                      <p className="error">{formErrors.address}</p>
                    )}
                  </div>
                </div>
              )}
              {/* first tab */}
              {/* second tab start */}

              {activeTab === 2 && (
                <div className="second-tab slide-left">
                  <div className="personal-dat">
                    <h3>Address and Other</h3>
                  </div>

                  <div
                    className={`qualification ${
                      formValues.qualification ? "selected" : ""
                    }`}
                  >
                    <label for="qualification">Qualification</label> <br />
                    <Select
                      value={
                        !formValues.qualification
                          ? null
                          : formValues.qualification
                      }
                      onChange={qualificationChange}
                      placeholder="Select a qualification"
                      required
                      options={qualification}
                    />
                    {formErrors.qualification && (
                      <p className="error">{formErrors.qualification}</p>
                    )}
                  </div>
                  <div
                    // className="district_field"
                    className={`district_field ${
                      formValues.district ? "selected" : ""
                    }`}
                  >
                    <label htmlFor="district">Choose District</label> <br />
                    <Select
                      showSearch
                      value={!formValues.district ? null : formValues.district}
                      placeholder="Select a district"
                      onChange={onDistrictChange}
                      onSearch={onSearch}
                      filterOption={false}
                      options={
                        filteredOptions.length > 0 ? filteredOptions : districts
                      }
                      onKeyPress={handleKeyPress}
                    />
                    {formErrors.district && (
                      <p className="error">{formErrors.district}</p>
                    )}
                  </div>
                  <div className="parent_cp_ward">
                    <div
                      className={`left_corporation ${
                        formValues.corporation ? "selected" : ""
                      }`}
                    >
                      <div className="panjayath_parent">
                        <div className="label_radio">
                          <input
                            type="radio"
                            name="authority"
                            checked={
                              formValues.authority === "Corporation" && "true"
                            }
                            value="Corporation"
                            onChange={handleChange}
                          />
                          <p>Corporation</p>
                        </div>
                        <div className="label_radio">
                          <input
                            type="radio"
                            name="authority"
                            checked={
                              formValues.authority === "Municipality" && "true"
                            }
                            value="Municipality"
                            onChange={handleChange}
                          />
                          <p>Municipality</p>
                        </div>
                        <div className="label_radio">
                          <input
                            type="radio"
                            name="authority"
                            checked={
                              formValues.authority === "Panchayath" && "true"
                            }
                            value="Panchayath"
                            onChange={handleChange}
                          />
                          <p>Panchayath</p>
                        </div>
                      </div>
                      {formErrors.authority && (
                        <p className="error" style={{ marginTop: "15px" }}>
                          {formErrors.authority}
                        </p>
                      )}

                      <Select
                        showSearch
                        value={
                          // !formValues.corporation
                          //   ? null
                          //   : formValues.corporation
                          formValues.corporation ||
                          formValues.newcorporation ||
                          formValues.municipality ||
                          null
                        }
                        placeholder={
                          !formValues.authority
                            ? "Select authority"
                            : formValues.authority === "Corporation"
                            ? "Select Corporation"
                            : formValues.authority === "Municipality"
                            ? "Select Municipality"
                            : "Select Panchayath"
                        }
                        onChange={
                          formValues.authority === "Municipality"
                            ? onMuncipalityChange
                            : formValues.authority === "Panchayath"
                            ? onPanchayathChange
                            : onCorporationChange
                        }
                        onSearch={
                          formValues.authority === "Municipality"
                            ? onMuncipalitySearch
                            : formValues.authority === "Corporation"
                            ? onCorporationSearch
                            : onPanchayathSearch
                        }
                        filterOption={false}
                        onKeyPress={handleKeyPress}
                        options={
                          formValues.authority === "Panchayath" &&
                          panchayathFilteredOptions.length > 0
                            ? panchayathFilteredOptions
                            : formValues.authority === "Panchayath"
                            ? panchayaths
                            : formValues.authority === "Municipality" &&
                              munipalityFilteredOptions.length > 0
                            ? munipalityFilteredOptions
                            : formValues.authority === "Municipality"
                            ? muncipalityList
                            : formValues.authority === "Corporation" &&
                              corporationFilterOptions.length > 0
                            ? corporationFilterOptions
                            : formValues.authority === "Corporation" &&
                              corporationList
                        }
                      />
                      {formErrors.corporation && (
                        <p className="error">{formErrors.corporation}</p>
                      )}
                    </div>
                    <div
                      className={`right_ward ${
                        formValues.ward ? "selected" : ""
                      }`}
                    >
                      <label for="ward">Ward No</label> <br />
                      <Select
                        showSearch
                        value={!formValues.ward ? null : formValues.ward}
                        placeholder="Select Ward"
                        onChange={onWardChange}
                        onSearch={onWardSearch}
                        onKeyPress={handleWardKeyPress}
                        filterOption={false}
                        options={
                          wardFilteredOptions.length > 0
                            ? wardFilteredOptions
                            : wardList
                        }
                      />
                      {formErrors.ward && (
                        <p className="error">{formErrors.ward}</p>
                      )}
                    </div>
                  </div>
                  {availabilityCheckError && (
                    <p className="error">{availabilityCheckError} </p>
                  )}
                  <div className="nominee_field">
                    <label for="nominee">Nominee Name</label> <br />
                    <input
                      type="text"
                      name="nominee"
                      placeholder="Enter Nominee"
                      value={formValues.nominee}
                      onChange={handleChange}
                      required
                    />
                    {formErrors.nominee && (
                      <p className="error">{formErrors.nominee}</p>
                    )}
                  </div>
                  <div
                    // className="relationship"
                    className={`relationship ${
                      formValues.relation ? "selected" : ""
                    }`}
                  >
                    <label for="relation">Relationship</label> <br />
                    <Select
                      value={!formValues.relation ? null : formValues.relation}
                      onChange={onRelationChange}
                      placeholder="Select a Relationship"
                      required
                      options={mappedrelationship}
                    />
                    {formErrors.relation && (
                      <p className="error">{formErrors.relation}</p>
                    )}
                  </div>
                </div>
              )}
              {/* second tab end */}

              {/* third tab start */}
              {activeTab >= 3 && (
                <div className="third-tab slide-left">
                  <div className="personal-dat">
                    <h3>Confidentials</h3>
                  </div>
                  <div className="choose_files">
                    <label>Upload Aadhar Card</label>
                    <div className="attament-files">
                      <div
                        className="drag_drops"
                        onClick={() => {
                          setAdharImagesContainer(true);
                          setFormErrors({ ...formErrors, aharChoose: "" });
                        }}
                      >
                        <p className="choose__filess">Choose file.....</p>
                      </div>
                      {adharImagesContainer && (
                        <div className="image_parent_container">
                          <div className="img_select_show-container">
                            <div
                              className="adhar_image_select"
                              onClick={handleChooseFiles}
                            >
                              <input
                                type="file"
                                key={selectedAadhar}
                                ref={fileInputRef2}
                                style={{ display: "none" }}
                                accept="image/*"
                                onChange={handleImageUpload}
                              />
                              <FiPlus />
                            </div>
                            {formErrors.adharImage && (
                              <p className="error">{formErrors.adharImage}</p>
                            )}
                            {selectedAadhar && (
                              <>
                                <div className="adhar-image-container">
                                  <img
                                    className="attachment-payments2"
                                    src={selectedAadhar}
                                    alt="Selected"
                                  />
                                </div>
                                <IoClose
                                  className="img-close"
                                  onClick={() => {
                                    console.log("clicked");
                                    setselectedAadhar(null);
                                    fileInputRef2.current.value = "";
                                    setSelectedAdharFileName("");
                                  }}
                                />
                                <p className="file-name">
                                  {selectedAdharFileName}
                                </p>
                              </>
                            )}
                          </div>
                          <div className="img_select_show-container">
                            <div
                              className="adhar_image_select"
                              onClick={handleChooseAdharBackFiles}
                            >
                              <input
                                type="file"
                                key={selectedAadhar2}
                                ref={fileInputRefAdharback}
                                style={{ display: "none" }}
                                accept="image/*"
                                onChange={handleAdharImageUpload}
                              />
                              <FiPlus />
                            </div>
                            {formErrors.adharImageBack && (
                              <p className="error">
                                {formErrors.adharImageBack}
                              </p>
                            )}
                            {selectedAadhar2 && (
                              <>
                                <div className="adhar-image-container">
                                  <img
                                    className="attachment-payments2"
                                    src={selectedAadhar2}
                                    alt="Selected"
                                  />
                                </div>
                                <IoClose
                                  className="img-close"
                                  onClick={() => {
                                    setselectedAadhar2(null);
                                    // fileInputRefAdharback.current.value = "";
                                    setSelectedAdharBackFileName("");
                                  }}
                                />
                                <p className="file-name">
                                  {selectedAdharBackFileName}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {formErrors.aharChoose && (
                    <p className="error">{formErrors.aharChoose}</p>
                  )}
                  <div className="choose_files">
                    <label>Upload Pan Card</label>
                    <div className="attament-files">
                      <div
                        className="drag_drops"
                        onClick={handleChooseFilespancrad}
                      >
                        <p className="choose__filess">Choose file.....</p>
                      </div>
                      <input
                        type="file"
                        key={selectedpancard}
                        ref={fileInputRef3}
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleImageUploadpancard}
                      />
                      {selectedpancard && (
                        <>
                          <div className="image-container">
                            <img
                              className="attachment-payments2"
                              src={selectedpancard}
                              alt="Selected"
                            />
                            <IoClose
                              className="img-close"
                              onClick={() => {
                                setselectedPancard(null);
                                fileInputRef3.current.value = "";
                                setSelectedPanFileName("");
                              }}
                            />
                          </div>
                          <div className="file-name">{selectedPanFileName}</div>
                        </>
                      )}
                      {formErrors.pancardImage && (
                        <p className="error">{formErrors.pancardImage}</p>
                      )}
                    </div>
                  </div>
                  <div className="adharcard">
                    <label for="aadharcard">Aadhar Card Number</label> <br />
                    <input
                      type="text"
                      name="aadharcard"
                      value={formValues.aadharcard}
                      maxLength={12}
                      onChange={handleChange}
                      placeholder="989866560043"
                    />
                    {formErrors.aadharcard && (
                      <p className="error">{formErrors.aadharcard}</p>
                    )}
                  </div>
                  <div className="adharcard">
                    <label for="pancard">Pan Card Number</label> <br />
                    <input
                      type="text"
                      name="pancard"
                      onChange={handleChange}
                      value={formValues.pancard}
                      placeholder="HTYJK8986K"
                    />
                    {formErrors.pancard && (
                      <p className="error">{formErrors.pancard}</p>
                    )}
                  </div>
                  <div className="adharcard">
                    <label for="ifsc">IFSC</label> <br />
                    <input
                      type="text"
                      name="ifsc"
                      value={formValues.ifsc}
                      onChange={handleChange}
                      placeholder="FDRL0001537"
                    />
                    {formErrors.ifsc && (
                      <p className="error">{formErrors.ifsc}</p>
                    )}
                  </div>
                  <div className="bank_info">
                    <p>{bankName && bankName.toUpperCase()}</p>
                    <p>{bankBranch && bankBranch}</p>
                  </div>
                  <div className="bankaccount">
                    <label for="bank">Bank Account Number</label> <br />
                    <input
                      type="text"
                      name="bank"
                      value={formValues.bank}
                      onChange={handleChange}
                      pattern="[0-9]+"
                      placeholder="Enter Bank Account Number"
                    />
                    {formErrors.bank && (
                      <p className="error">{formErrors.bank}</p>
                    )}
                  </div>
                  <div className="bankaccount">
                    <label for="confirmbank">Confirm Bank Account Number</label>{" "}
                    <br />
                    <input
                      type="text"
                      name="confirmbank"
                      onChange={handleChange}
                      onBlur={handleConfirmBankBlur}
                      value={formValues.confirmbank}
                      pattern="[0-9]+"
                      placeholder="Confirm Bank Account Number"
                    />
                    {formErrors.confirmbank && (
                      <p className="error">{formErrors.confirmbank}</p>
                    )}
                  </div>
                  <div className="choose_files">
                    <label>Upload Passbook</label>
                    <div className="attament-files">
                      <div
                        className="drag_drops"
                        onClick={handleChooseFilesPassBook}
                      >
                        <p className="choose__filess">Choose file.....</p>
                      </div>
                      <input
                        type="file"
                        key={selectedPassbook}
                        ref={fileInputRef4}
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleImageUploadPassbook}
                      />
                      {selectedPassbook && (
                        <>
                          <div className="image-container">
                            <img
                              className="attachment-payments2"
                              src={selectedPassbook}
                              alt="Selected"
                            />
                            <IoClose
                              className="img-close"
                              onClick={() => {
                                setSelectedPassbook(null);
                                fileInputRef4.current.value = "";
                                setSelectedPassbookFilename("");
                              }}
                            />
                          </div>
                          <div className="file-name">
                            {selectedPassbookFilename}
                          </div>
                        </>
                      )}
                      {formErrors.passbookImage && (
                        <p className="error">{formErrors.passbookImage}</p>
                      )}
                    </div>
                  </div>

                  <div className="payment-section">
                    <h3>Payment</h3>
                    <div
                      className={`qualification ${
                        formValues.payment_method ? "selected" : ""
                      }`}
                    >
                      <label for="payment">Payment Method</label> <br />
                      <Select
                        value={
                          !formValues.payment_method
                            ? null
                            : formValues.payment_method
                        }
                        onChange={onPaymentMethodChange}
                        placeholder="Select Payment Method"
                        required
                        options={mappedpayment_method}
                        mappedrelationship
                      />
                    </div>
                    {formErrors.paymentMethod && (
                      <p className="error">{formErrors.paymentMethod}</p>
                    )}
                    {formValues.payment_method === "UPI ID" && (
                      <>
                        <div className="attachment">
                          <p className="attachment_texts">
                            Attachment <br />{" "}
                            <span>Attach payment screenshort here</span>{" "}
                          </p>
                          <div className="attament-files">
                            <div
                              style={{ position: "relative" }}
                              // className="drag_drop"
                              className={`drag_drop ${
                                isDragging ? "dragging" : ""
                              }`}
                              onClick={handleChooseFile}
                              onDragEnter={handleDragEnter}
                              onDragLeave={handleDragLeave}
                              onDragOver={handleDragOver}
                              onDrop={handleDrop}
                            >
                              Drag & Drop or <span>browse</span>{" "}
                              <BsQrCodeScan className="qrscan" />
                            </div>
                            <input
                              type="file"
                              ref={fileInputRef}
                              key={selectedImage}
                              style={{ display: "none" }}
                              accept="image/*"
                              onChange={handleFileChange}
                            />
                            {selectedImage && (
                              <>
                                <div className="image-container">
                                  <img
                                    className="attachment-payments"
                                    src={selectedImage}
                                    alt="Selected"
                                  />
                                  <IoClose
                                    className="img-close"
                                    style={{ top: "15px" }}
                                    onClick={() => {
                                      setSelectedImage("");
                                      fileInputRef.current.value = "";
                                      setSelectedAttachmentFileName("");
                                    }}
                                  />
                                </div>
                                <div className="file-name">
                                  {selectedAttachmentFileName}
                                </div>
                              </>
                            )}
                            {formErrors.imageAttachment && (
                              <p className="error">
                                {formErrors.imageAttachment}
                              </p>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <img
                            style={{ width: " 200px", height: " 180px" }}
                            src={qrcodes}
                            alt=""
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <div className="accept-terms">
                    <input
                      className="checkbox-pay"
                      type="checkbox"
                      name="terms"
                      checked={formValues.terms}
                      onChange={handleChange}
                    />
                    <label for="terms" className="accept-terms-condition">
                      {" "}
                      <span className="accept-terms-condition_accept">
                        Accept
                      </span>{" "}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const valueToPass = true;
                          onTermsClick(valueToPass);
                        }}
                      >
                        Terms and Conditions
                      </span>
                    </label>
                    {formErrors.terms && (
                      <p className="error">{formErrors.terms}</p>
                    )}
                  </div>
                </div>
              )}

              {/* third tab end */}

              <div className="form-register">
                {activeTab === 1 && (
                  <button type="button" onClick={handleNextTab}>
                    Next
                  </button>
                )}
                {activeTab === 2 && (
                  <>
                    <button
                      type="button"
                      style={{
                        border: "1px solid #255FE8",
                        background: "#FFF",
                        color: "#255FE8",
                      }}
                      onClick={handlePreviousTab}
                    >
                      Previous
                    </button>
                    <button type="button" onClick={handleNextTab}>
                      Next
                    </button>
                  </>
                )}
                {activeTab >= 3 && (
                  <>
                    {isLoading ? (
                      <Loader width="25px" height="25px" />
                    ) : (
                      <>
                        <button
                          type="button"
                          style={{
                            border: "1px solid #255FE8",
                            background: "#FFF",
                            color: "#255FE8",
                          }}
                          onClick={handlePreviousTab}
                        >
                          Previous
                        </button>
                        <button type="submit">Register</button>
                      </>
                    )}
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </Modal>
      {isRegistrationSuccess && (
        <div className="success-message">
          <div>
            <img src={successimg} alt="" />
          </div>
          <div style={{ color: "193B68", marginBottom: "5px" }}>
            You are successfully registered
          </div>
          <div style={{ color: "#848590", textAlign: "center" }}>
            Welcome to WELOTA.
          </div>
        </div>
      )}
    </>
  );
};

export default RegisterModal;
