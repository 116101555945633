import React, { useState } from "react";
import { Modal } from "antd";
import Joi from "joi";
import axios from "axios";
import { Loader } from "../../Components/Loader/Loader";
import successimg from "../../Components/Register Modal/assets/success.png";

const ChangePassword = ({ isOpen, onClose }) => {
  const email = localStorage.getItem("email");
  const authToken = localStorage.getItem("authToken");
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false);
  const [passwordData, setPasswordData] = useState({
    newpassword: "",
    confirmpassword: "",
  });
  const [passwordError, setPasswordError] = useState({
    newpassword: "",
    confirmpassword: "",
  });

  const handleSuccess = (e) => {
    e.preventDefault();

    const { error } = newPasswordSchema.validate(passwordData, {
      abortEarly: false,
    });

    if (error) {
      const newErrors = { newpassword: "", confirmpassword: "" };

      error.details.forEach((detail) => {
        if (detail.context.key) {
          newErrors[detail.context.key] = detail.message;
        }
      });

      setPasswordError(newErrors);
      return;
    } else {
      resetPassword();
    }
  };

  const newPasswordSchema = Joi.object({
    newpassword: Joi.string().min(8).required().label("Password").messages({
      "string.min": "Password must be at least 8 characters long",
      "any.required": "Password is required",
      "string.empty": "Password is required",
    }),
    confirmpassword: Joi.string()
      .valid(Joi.ref("newpassword"))
      .required()
      .label("Confirm Password")
      .messages({
        "any.only": "Passwords do not match",
        "any.required": "Confirm Password is required",
        "string.empty": "Confirm Password is required",
      }),
  });

  const resetPassword = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        // `${Urls.resetPassword + mobileOrEmail}`,
        // "https://werevetest.zinfog.com/changepassword?email=" +
        "https://welotaapi.welota.com/changepassword?email=" +
          email +
          "&auth=" +
          authToken,
        {
          new_password: passwordData.newpassword,
          confirm_password: passwordData.confirmpassword,
        }
      );
      console.log("resetPassword");
      if (
        response?.data?.results?.message === "Franchisee Succesfully updated" ||
        response?.data?.results?.message === "Admin Succesfully updated"
      ) {
        onClose();
        setIsPasswordResetSuccess(true);
        setTimeout(() => {
          setIsPasswordResetSuccess(false);
        }, 2000);
      }
      setPasswordData({ newpassword: "", confirmpassword: "" });
    } catch (error) {
      alert("something went wrong");
      return undefined;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal open={isOpen} onOk={onClose} onCancel={onClose} title="">
        <div className="new_password_parent">
          <h3>Set new password</h3>
          <p className="logindesc">
            Password must be at least 8 characters long
          </p>
          <form onSubmit={handleSuccess}>
            <div className="new___password">
              <label htmlFor="newpassword">Password</label>
              <br />
              <input
                type="password"
                name="newpassword"
                value={passwordData.newpassword}
                onChange={(e) => {
                  const newPassword = e.target.value;
                  setPasswordError((prevErrors) => ({
                    ...prevErrors,
                    newpassword:
                      newPassword.length >= 8
                        ? ""
                        : "Password length must be 8 letters",
                  }));
                  setPasswordData((prevData) => ({
                    ...prevData,
                    newpassword: e.target.value,
                  }));
                }}
              />
              {passwordError.newpassword && (
                <div className="error">{passwordError.newpassword}</div>
              )}
            </div>
            <div className="confirm_password">
              <label htmlFor="confirmpassword">Confirm Password</label>
              <br />
              <input
                type="password"
                name="confirmpassword"
                value={passwordData.confirmpassword}
                onChange={(e) => {
                  const confirmPassword = e.target.value;
                  setPasswordError((prevErrors) => ({
                    ...prevErrors,
                    confirmpassword:
                      confirmPassword.length >= 8
                        ? ""
                        : "Confirm password length must be 8 letters",
                  }));
                  setPasswordData((prevData) => ({
                    ...prevData,
                    confirmpassword: e.target.value,
                  }));
                }}
              />
              {passwordError.confirmpassword && (
                <div className="error">{passwordError.confirmpassword}</div>
              )}
            </div>

            {isLoading ? (
              <Loader width={30} height={30} />
            ) : (
              <div className="login-btn">
                <button>Reset Password</button>
              </div>
            )}
          </form>
        </div>
      </Modal>
      {isPasswordResetSuccess && (
        <div className="success-message">
          {/* <div
            id="lottie-container"
            style={{ width: "300px", height: "300px", zIndex: "99999" }}
          ></div> */}
          <div>
            {/* <lottie-player
              className="lottie"
              src="https://lottie.host/e79c0151-d047-46a0-ab0c-3f44393c9245/WF99IshXyK.json"
              style={{ background: "#fff" }}
              speed="1"
              loop
              controls
              autoplay
              direction="1"
              mode="normal"
            ></lottie-player> */}
            <img src={successimg} alt="" />
            {/* <div
              id="lottie-container"
              style={{ width: "300px", height: "300px" }}
            ></div> */}
          </div>
          <div style={{ color: "193B68", marginBottom: "5px" }}>
            Your password successfully changed
          </div>
          <div style={{ color: "#848590", textAlign: "center" }}>WELOTA.</div>
        </div>
      )}
    </>
  );
};

export default ChangePassword;
