import React from "react";
import Sidebar from "../Dashboard/Sidebar";
import DashboardHeader from "../Dashboard/DashboardHeader";
import OrderListScreen from "./OrderListScreen";
import "./Orderlist.css";

const OrderList = () => {
  return (
    <div>
      <Sidebar />
      <DashboardHeader />
      <OrderListScreen />
    </div>
  );
};

export default OrderList;
