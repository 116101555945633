import { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [userData, setUserData] = useState(null);

  return (
    <AuthContext.Provider value={{ userData, setUserData }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

const PaginationContext = createContext();

export const PaginationProvider = ({ children }) => {
  const [paginationState, setPaginationState] = useState({
    franchisePage: 1,
    searchQuery: "",
  });

  return (
    <PaginationContext.Provider value={{ paginationState, setPaginationState }}>
      {children}
    </PaginationContext.Provider>
  );
};

export const usePagination = () => {
  return useContext(PaginationContext);
};
