import React from "react";
import "./Question.css";
import qaimg from "./assets/qaimg.png";
import Faq from "./Faq";

function Question({ onRegisterNowClick }) {
  return (
    <div>
      <div
        className="faqparentsec section "
        id="franchise"
        style={{
          background: "#03031e",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div className="shadow2"></div>
        <div className="about__container container">
          <div className="parent-section">
            <div className="p-left">
              <h3>
                Join us as a Franchisee: Unlock Financial Freedom and
                Empowerment!
              </h3>
              <p>
                Join us today to unlock financial freedom effortlessly as a
                franchisee. Access our proven system for prosperity with minimal
                investment and effort. Create a network of empowered individuals
                across Kerala, providing convenient solutions. Enjoy benefits
                like financial independence, exclusive discounts, and ongoing
                support. Don't miss out on this incredible opportunity to
                rewrite your financial future and contribute to local economic
                growth.
              </p>
            </div>
            <div className="p-right">
              <img src={qaimg} alt="" />
            </div>
          </div>
          <Faq onRegisterNowClick={onRegisterNowClick} />
        </div>
      </div>
    </div>
  );
}

export default Question;
