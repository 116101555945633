import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import DashboardHeader from "./DashboardHeader";
import activityimg from "./assets/box.png";
import buckets from "./assets/buckets.png";
import axios from "axios";
import { Chart } from "primereact/chart";
import * as Urls from "../../Urls";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const [franchiseList, setFranchiseList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove vertical lines
        },
        ticks: {
          color: "rgba(25, 59, 104, 0.6)",
          // Set the maximum value to 500
        },
      },
      y: {
        border: { dash: [6, 6] }, // for the grid lines
        grid: {
          color: "#BECFE2", // for the grid lines
          tickColor: "#BECFE2", // for the tick mark
          tickBorderDash: [2, 3], // also for the tick, if long enough
          tickLength: 0, // just to see the dotted line
          // tickWidth: 2,
          offset: true,
          drawTicks: true, // true is default
          drawOnChartArea: true, // true is default
        },
      },
    },
  });
  const [activities, setActivities] = useState([]);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    fetchFranchiseList();
    fetchProductList();
    fetchActivityLog();
    fetchGraph();
  }, []);

  const fetchGraph = async () => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.get(Urls.graph, config);
      if (response && response.data.results.message === "Success") {
        const data = {
          labels: Object.keys(response.data.results.Data[0]),
          datasets: [
            {
              label: "",
              backgroundColor: ["#48AAF7"],
              borderColor: ["48AAF7"],
              barThickness: 30,
              barPercentage: 2.0,
              data: Object.values(response.data.results.Data[0]),
            },
          ],
        };
        setChartData(data);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchActivityLog = async () => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.get(Urls.activityLog, config);
      if (response.data) {
        setActivities(response.data);
      } else {
        setActivities([]);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  console.log("...activities", activities);
  const handleNavigate = () => {
    window.location.href = "/login";
    window.localStorage.clear();
  };

  const fetchFranchiseList = async () => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.get(Urls.franchiseList, config);
      if (
        response.data &&
        response.data.results &&
        response.data.results.data
      ) {
        setFranchiseList(response.data.results.data);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      if (error.response.data.message === "Invalid Token") {
        handleNavigate();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProductList = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.get(Urls.getProductList, config);
      if (
        response.data &&
        response.data.results &&
        response.data.results.data
      ) {
        setProductList(response.data.results.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRequestData = () => [
    {
      Image: "allrequest",
      head: "All Request",
      request: franchiseList.length,
    },
    {
      Image: "Acceptedrequest",
      head: "Accepted Request",
      request: countRequestStatus("Approved"),
    },
    {
      Image: "pendingrequest",
      head: "Pending Request",
      request: countRequestStatus("Pending"),
    },
    {
      Image: "rejectedrequest",
      head: "Rejected Request",
      request: countRequestStatus("Rejected"),
    },
  ];

  const countRequestStatus = (status) => {
    return franchiseList.filter((item) => item.approve_status === status)
      .length;
  };

  const formatActivityDate = (created_date) => {
    const activityDate = new Date(created_date);
    const today = new Date();

    if (
      activityDate.getDate() === today.getDate() &&
      activityDate.getMonth() === today.getMonth() &&
      activityDate.getFullYear() === today.getFullYear()
    ) {
      const hours = activityDate.getHours();
      const minutes = activityDate.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes.toString().padStart(2, "0");

      return `Today ${formattedHours}:${formattedMinutes} ${ampm}`;
    } else {
      return (
        activityDate.toLocaleDateString() +
        " " +
        activityDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
    }
  };

  return (
    <div style={{ background: "#F8F9FB" }}>
      <Sidebar />
      <DashboardHeader />
      <div
        style={{
          margin: "25px 0px",
          marginBottom: "0px",
          paddingBottom: "25px",
        }}
      >
        <div className="dashboardcontents">
          <div className="dashboard-request">
            {getRequestData().map((item) => (
              <div
                className="request-card"
                onClick={() => navigate("/dashboard")}
              >
                <img src={require(`./assets/${item.Image}.png`)} alt="" />
                <div className="request-content">
                  <p>{item.head}</p>
                  <h3>{item.request}</h3>
                </div>
              </div>
            ))}
          </div>
          {/* registration overview and activity start */}
          <div className="registration_overview__activity">
            <div className="registration__overview_left">
              <h3 className="register-overviewh3">Registration overview</h3>

              <div>
                {chartData && chartOptions && (
                  <Chart type="bar" data={chartData} options={chartOptions} />
                )}
              </div>
            </div>
            <div className="register__activity">
              <h3>Activity</h3>
              <div className="activity_scroll">
                {activities.length > 0 &&
                  activities.map((item) => (
                    <div className="activity__parent">
                      <div className="left__activity">
                        <img src={activityimg} alt="" />
                      </div>
                      <div className="right__activity">
                        <p style={{ color: "#193B68" }}>{item.activity_type}</p>
                        <p style={{ color: "#193b6887" }}>
                          {formatActivityDate(item.created_date)}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {/* registration overview and activity end */}
          {/* new registration and products */}
          <div className="new_register____products">
            <div className="left__newRegister">
              <div className="register__table">
                <p style={{ color: "#193B68", fontWeight: "500" }}>
                  New Registration
                </p>
                <p
                  style={{ color: "#255FE8", fontSize: "", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                >
                  View all
                </p>
              </div>
              <div className="admin_table_res" style={{ overflowX: "auto" }}>
                <table
                  style={{
                    width: "100%",
                    textAlign: "left",
                    colorL: "#193B68",
                  }}
                >
                  <thead>
                    <tr style={{ border: "none" }}>
                      <th className="table-head2">Name</th>
                      <th className="table-head2">District</th>
                      <th className="table-head2">Ward</th>
                    </tr>
                  </thead>
                  <tbody>
                    {franchiseList.slice(0, 5)?.map((item) => (
                      <tr key={item.id} style={{ background: "#fff" }}>
                        <td className="table-datas2">
                          {item.username} <br />
                          <span>{item.email}</span>
                        </td>
                        <td className="table-datas2">
                          {item.district_name} <br />{" "}
                          <span>{item.panchayath_name}</span>{" "}
                        </td>
                        <td
                          className="table-datas2"
                          style={{ color: "#1C65DF" }}
                        >
                          {item.ward_number}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="right____products">
              <div className="products___tables">
                <p style={{ color: "#193B68", fontWeight: "500" }}>Products</p>
                <p
                  style={{ color: "#255FE8", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/products");
                  }}
                >
                  View all
                </p>
              </div>
              <div style={{ overflowX: "auto" }}>
                <table
                  style={{
                    width: "100%",
                    textAlign: "left",
                    colorL: "#193B68",
                  }}
                >
                  <thead>
                    <tr style={{ border: "none" }}>
                      <th className="table-head2">Product</th>
                      <th className="table-head2">Quantity</th>
                      <th className="table-head2">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productList.slice(0, 5)?.map((product) => (
                      <tr style={{ background: "#fff" }}>
                        <td className="table-datas2">
                          <div className="products_______images">
                            <img
                              src={product.product_image}
                              alt=""
                              style={{ maxWidth: "45px", height: "49px" }}
                            />
                            <p>{product.product_name}</p>
                          </div>
                        </td>
                        <td className="table-datas2">
                          {product.balance_quantity} Nos
                        </td>
                        <td className="table-datas2">
                          ₹ {product.price.toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
