import React, { useState } from "react";
import "./Footer.css";
import Logo from "../Navbar/assets/welotaLogo.png";
import { AiFillInstagram } from "react-icons/ai";
import { BsFacebook, BsTwitter, BsYoutube } from "react-icons/bs";
import { Link } from "react-scroll";

const Footer = ({ onRegisterNowClick }) => {
  // modal start
  const handleRegisterNowClick = () => {
    onRegisterNowClick();
  };
  //  modal end
  return (
    <div>
      <div
        className="footer__main section "
        id="footer"
        style={{ background: "#000E74" }}
      >
        <div className="about__container container">
          <div className="footer-contents">
            <div className="footer_header-container">
              <div className="footer_header">
                <h3 className="footer_header-text">
                  Sign up to our newsletter
                </h3>
                <p className="footer_sub-text">
                  Stay up to date with latest news, announcements and articles.
                </p>
              </div>
              <div className="subscribe-section">
                <input className="email-input" placeholder="Enter your Email" />
                <div className="subscribe-button">Subscribe</div>
              </div>
            </div>
            <div className="footer_body-container">
              <div className="logo_dis-container">
                <img className="body-header-logo" src={Logo} />
                <p className="body-sub-text">
                  Join our Franchise program with a minimal investment. Register
                  people from your locality on our app. They access various
                  services, and you earn up to 20% of each transaction.
                  Convenience, transparency, and financial rewards await you.
                </p>
                <div
                  className="register-button"
                  onClick={handleRegisterNowClick}
                >
                  Register Franchise
                </div>
              </div>
              <div className="links">
                <p className="link_header-text">Quick links</p>
                <p className="link_sub-text">
                  <Link
                    // activeClass="active-link"
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-70} // Adjust the offset as needed
                    className="nav__link"
                  >
                    About us
                  </Link>
                </p>
                <p className="link_sub-text">
                  <Link
                    // activeClass="active-link"
                    to="income"
                    spy={true}
                    smooth={true}
                    offset={-70} // Adjust the offset as needed
                    className="nav__link"
                  >
                    How its work
                  </Link>
                </p>
                <Link
                  // activeClass="active-link"
                  to="business"
                  spy={true}
                  smooth={true}
                  offset={-70} // Adjust the offset as needed
                  className="nav__link"
                >
                  Business
                </Link>
                <p className="link_sub-text">
                  <Link
                    // activeClass="active-link"
                    to="franchise"
                    spy={true}
                    smooth={true}
                    offset={-70} // Adjust the offset as needed
                    className="nav__link"
                  >
                    Franchise
                  </Link>
                </p>
                <p className="link_sub-text">
                  <Link
                    // activeClass="active-link"
                    to="footer"
                    spy={true}
                    smooth={true}
                    offset={-70} // Adjust the offset as needed
                    className="nav__link"
                  >
                    Contact us
                  </Link>
                </p>
              </div>
              <div className="social-media-container">
                <p className="social-media-text">Social media</p>
                <div className="social-icons">
                  <AiFillInstagram
                    onClick={() =>
                      (window.location.href =
                        "https://instagram.com/welotasolutions?igshid=MzRlODBiNWFlZA==")
                    }
                    style={{ fill: "#FFF", fontSize: "30", cursor: "pointer" }}
                  />
                  <BsFacebook
                    onClick={() =>
                      (window.location.href =
                        "https://www.facebook.com/welotasolutions?mibextid=ZbWKwL")
                    }
                    className="socialmedia-icon"
                  />
                  <BsTwitter
                    onClick={() =>
                      (window.location.href =
                        "https://twitter.com/WelotaSolutions")
                    }
                    className="socialmedia-icon"
                  />
                  <BsYoutube
                    onClick={() =>
                      (window.location.href =
                        "https://youtube.com/@welotasolutions?si=EEjhLf9pMt64Tsx4")
                    }
                    className="socialmedia-icon"
                  />
                </div>
                <p className="social-media-text" style={{ marginTop: "40px" }}>
                  Registered Office
                </p>
                <p className="address-sub-text" style={{ marginBottom: "0px" }}>
                  Welota, First floor, Kunhipari Building, Cherooty Road,
                  Calicut - 673032
                </p>
                <p className="body-sub-text">
                  +91&nbsp;9072330102, office@welota.in
                </p>
              </div>
            </div>
          </div>
          <div className="top-border">
            <div className="legal-texts">
              <div className="copy-rights">
                <p>Copy rights :2023 - 2024 WELOTA all rights reserved </p>
              </div>
              <div className="Tc-Pp">
                <p>Terms and condition</p>
                <p>Privacy policy</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
