import React, { useEffect, useState } from "react";
import { Modal } from "antd";
// import "./FilterModal.css";
import * as Urls from "../../Urls";
import axios from "axios";
import { right } from "@popperjs/core";
import "./Filter.css";

const FilterModal = ({
  isOpen,
  onClose,
  onApplyFilters,
  filter,
  setFilter,
}) => {
  const [districts, setDistricts] = useState([]);
  const [panchayaths, setPanchayaths] = useState([]);
  const [showDistrictContainer, setShowDistrictContainer] = useState(false);
  const [showPanchayathContainer, setShowPanchayathContainer] = useState(false);
  const [showPunchayathLabel, setShowPunchayathLabel] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [checkedPanchayathIds, setCheckedPanchayathIds] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [showMunicipalityContainer, setShowMunicipalityContainer] =
    useState(false);
  const [showCorporationContainer, setShowCorporationContainer] =
    useState(false);

  useEffect(() => {
    getDistricts();
  }, []);

  useEffect(() => {
    if (filter.authority === "panchayath") {
      getPanchayath(filter.districtId);
    } else if (filter.authority === "corporation") {
      getCorporation(filter.districtId);
    } else if (filter.authority === "municipality") {
      getMuncipality(filter.districtId);
    }
  }, [filter.authority]);

  const getDistricts = async () => {
    try {
      const response = await axios.get(Urls.getDistrict);
      setDistricts(response.data.results.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getPanchayath = async (districId) => {
    console.log(".......................");
    try {
      const panchayathResponse = await axios.get(
        `${Urls.getPunchayath}?dist=${districId}`
      );
      if (panchayathResponse?.data?.results.message === "Fail") {
        setPanchayaths([]);
      } else if (panchayathResponse.data.results.message === "Success") {
        setPanchayaths(panchayathResponse.data.results.data);
        setShowPunchayathLabel(true);
      }
    } catch (error) {
      console.error("Error fetching panchayath data:", error);
    }
  };

  const getMuncipality = async (districtId) => {
    try {
      const muncipalityResponse = await axios.get(
        // `${Urls.getMuncipality}${districtId}`
        Urls.getMuncipality + districtId
      );
      if (muncipalityResponse?.data?.results.message === "Fail") {
        setPanchayaths([]);
        // alert(muncipalityResponse.data.results.reason);
      } else if (muncipalityResponse.data.results.message === "Success") {
        setPanchayaths(muncipalityResponse.data.results.data);
        // const mappedMunicipalities = muncipalityResponse.data.results.data.map(
        //   (muncipality) => ({
        //     label: muncipality.name,
        //     value: muncipality.id,
        //   })
        // );
        // setPanchayaths(mappedMunicipalities);
      }
    } catch (error) {
      console.error("Error fetching panchayath data:", error);
    }
  };

  const getCorporation = async (districtId) => {
    try {
      const corporationResponse = await axios.get(
        // `${Urls.getMuncipality}${districtId}`
        Urls.getCorporation + districtId
      );
      if (corporationResponse?.data?.results.message === "Fail") {
        setPanchayaths([]);
      } else if (corporationResponse.data.results.message === "Success") {
        setPanchayaths(corporationResponse.data.results.data);
        // const mappedCorporations = corporationResponse.data.results.data.map(
        //   (corporation) => ({
        //     label: corporation.name,
        //     value: corporation.id,
        //   })
        // );
        // setPanchayaths(mappedCorporations);
      }
    } catch (error) {
      console.error("Error fetching panchayath data:", error);
    }
  };

  const closeModal = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const handleClearAll = () => {
    setFilter({
      ...filter,
      options: [],
      districtId: null,
      panchayathIds: [],
      authority: null,
    });
    setShowDiv(false);
    setShowDistrictContainer(false);
    setShowPanchayathContainer(false);
  };

  const handleApplyFilters = () => {
    onApplyFilters(checkedPanchayathIds);
    onClose();
  };

  const onchangeOptions = (option) => {
    if (filter.options.includes(option)) {
      let _options = filter.options.filter((x) => x !== option);
      setFilter({ ...filter, options: _options });
    } else {
      setFilter({ ...filter, options: [...filter.options, option] });
    }
  };

  const onchangePanchayath = (panchayathID) => {
    if (filter.panchayathIds.includes(panchayathID)) {
      let _panchayathIds = filter.panchayathIds.filter(
        (x) => x !== panchayathID
      );
      setFilter({ ...filter, panchayathIds: _panchayathIds });
    } else {
      setFilter({
        ...filter,
        panchayathIds: [...filter.panchayathIds, panchayathID],
      });
    }
  };

  useEffect(() => {
    filter.districtId && setShowDistrictContainer(true);
  }, [filter.districtId]);

  useEffect(() => {
    filter.authority && setShowDiv(true);
  }, [filter.authority]);

  // useEffect(() => {
  //   filter.panchayathIds && setShowDiv(true);
  // }, [filter.panchayathIds]);
  console.log("filter: -->", filter);
  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal">
            <div className="filter__clears">
              <h3>Filters</h3>
              <p
                style={{ color: "#255FE8", cursor: "pointer" }}
                onClick={handleClearAll}
              >
                Clear all
              </p>
            </div>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <div className="filter___boxes" style={{ paddingLeft: "15px" }}>
                <div className="filterOptions">
                  <div className="filterOption1">
                    <input
                      className="filter_check"
                      type="checkbox"
                      id="Pending"
                      checked={filter.options.includes("Pending")}
                      onChange={() => {
                        onchangeOptions("Pending");
                      }}
                    />
                    <label for="vehicle1"> Pending</label>
                  </div>
                  <div className="filterOption1">
                    <input
                      className="filter_check"
                      type="checkbox"
                      id="Approved"
                      checked={filter.options.includes("Approved")}
                      onChange={() => {
                        onchangeOptions("Approved");
                      }}
                    />
                    <label for="vehicle1"> Approved</label>
                  </div>
                  <div className="filterOption1">
                    <input
                      className="filter_check"
                      type="checkbox"
                      id="Rejected"
                      checked={filter.options.includes("Rejected")}
                      onChange={() => {
                        onchangeOptions("Rejected");
                      }}
                    />
                    <label for="Refunded"> Rejected</label>
                  </div>
                  <div className="filterOption1">
                    <input
                      className="filter_check"
                      type="checkbox"
                      id="District"
                      name="District"
                      value="Bike"
                      checked={showDistrictContainer}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setFilter({
                          ...filter,
                          districtId: null,
                          panchayathIds: [],
                        });
                        setShowDistrictContainer(isChecked);
                        setShowDiv(false);
                      }}
                    />
                    <label for="District"> District</label>
                  </div>
                </div>
              </div>
              {showDistrictContainer && (
                <div className="district-container">
                  {districts.map((district) => (
                    <div className="filterOption1" key={district.id}>
                      <input
                        className="filter_check"
                        type="checkbox"
                        value={district.id}
                        checked={district.id === filter.districtId}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            districtId: Number(e.target.value),
                            panchayathIds: [],
                            authority: null,
                          });
                          setShowMunicipalityContainer(false);
                          setShowCorporationContainer(false);
                          setShowPanchayathContainer(false);
                          setShowDiv(false);
                          setPanchayaths([]);
                          if (district.id === filter.districtId) {
                            setFilter({
                              ...filter,
                              districtId: null,
                            });
                          }
                          setShowPunchayathLabel(false);
                        }}
                      />
                      <label htmlFor={`District-${district.id}`}>
                        {district.name}
                      </label>
                    </div>
                  ))}
                </div>
              )}
              {filter.districtId && (
                <div className="filter___boxes" style={{ paddingLeft: "15px" }}>
                  <div className="filterOptions">
                    <div
                      className="filterOption1"
                      // style={{ margin: "20px 0px", marginBottom: "0px" }}
                    >
                      <input
                        className="filter_check"
                        style={{ width: "10px" }}
                        type="checkbox"
                        id="Panjayath"
                        value={"panchayath"}
                        checked={filter.authority === "panchayath"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setShowPanchayathContainer(isChecked); // Update the state accordingly
                          getPanchayath(filter.districtId);
                          setShowMunicipalityContainer(false);
                          setShowCorporationContainer(false);
                          setShowDiv(isChecked);
                          setFilter({
                            ...filter,
                            authority: "panchayath",
                            panchayathIds: [],
                          });
                          if (filter.authority === "panchayath") {
                            setFilter({ ...filter, authority: null });
                          }
                        }}
                      />
                      <label for="Panjayath"> Panchayath</label>
                    </div>
                    <div
                      className="filterOption1"
                      // style={{ margin: "20px 0px", marginBottom: "0px" }}
                    >
                      <input
                        className="filter_check"
                        style={{ width: "10px" }}
                        type="checkbox"
                        id="Panjayath"
                        checked={filter.authority === "corporation"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setShowCorporationContainer(isChecked); // Update the state accordingly
                          setShowMunicipalityContainer(false);
                          setShowPanchayathContainer(false);
                          getCorporation(filter.districtId);
                          setShowDiv(isChecked);
                          setFilter({
                            ...filter,
                            authority: "corporation",
                            panchayathIds: [],
                          });
                          if (filter.authority === "corporation") {
                            setFilter({ ...filter, authority: null });
                          }
                        }}
                      />
                      <label for="Panjayath"> Corporation</label>
                    </div>
                    <div
                      className="filterOption1"
                      // style={{ margin: "20px 0px", marginBottom: "0px" }}
                    >
                      <input
                        className="filter_check"
                        style={{ width: "10px" }}
                        type="checkbox"
                        id="Panjayath"
                        checked={filter.authority === "municipality"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setShowMunicipalityContainer(isChecked); // Update the state accordingly
                          setShowCorporationContainer(false); // Update the state accordingly
                          setShowPanchayathContainer(false);
                          setShowDiv(isChecked);
                          getMuncipality(filter.districtId);
                          setFilter({
                            ...filter,
                            authority: "municipality",
                            panchayathIds: [],
                          });
                          if (filter.authority === "municipality") {
                            setFilter({ ...filter, authority: null });
                          }
                        }}
                      />
                      <label for="Panjayath"> Municipality</label>
                    </div>
                  </div>
                </div>
              )}
              {showDiv && (
                <>
                  <div className="panjayath_search">
                    <input
                      className="filter_check"
                      type="text"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      style={{
                        padding: "10px",
                        marginBottom: "5px!important",
                      }}
                      placeholder="Search ..."
                    />
                  </div>
                  <div className="district-container2">
                    {showDiv &&
                      panchayaths
                        .filter((panchayath) =>
                          panchayath?.name
                            ?.toLowerCase()
                            ?.includes(searchInput?.toLowerCase())
                        )
                        ?.map((panchayath) => (
                          <div className="filterOption1" key={panchayath.id}>
                            <input
                              style={{ width: "10px" }}
                              className="filter_check"
                              type="checkbox"
                              value={panchayath.id}
                              checked={filter.panchayathIds.includes(
                                panchayath.id
                              )}
                              onChange={(e) =>
                                onchangePanchayath(panchayath.id)
                              }
                            />
                            <label htmlFor={`District-${panchayath.id}`}>
                              {panchayath.name}
                            </label>
                          </div>
                        ))}
                  </div>
                </>
              )}
            </div>
            <div className="apply___filterss">
              <button onClick={handleApplyFilters}>Apply</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FilterModal;
