// Sidebar.js

import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import * as Urls from "../../Urls";
import axios from "axios";
import Logo from "./assets/welotaLogo_Dashboard.png";
import { Link, useLocation } from "react-router-dom"; // Import useLocation from react-router-dom
import { RiDashboardLine } from "react-icons/ri";
import franchise from "./assets/franchise.png";
import franchisewhite from "./assets/franchise.png";
import { IoBagRemoveSharp } from "react-icons/io5";
import { BsFillBagPlusFill } from "react-icons/bs";
import { PiTreeBold } from "react-icons/pi";

const Sidebar = ({ visibility }) => {
  const token = localStorage.getItem("authToken");
  const location = useLocation(); // Get the current location

  const [productList, setProductList] = useState([]);
  const [activeLink, setActiveLink] = useState(""); // State to manage the active link
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    const newWidth = window.innerWidth;
    setWindowWidth(newWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    fetchProductList();
  }, []);

  useEffect(() => {
    // Determine the active link based on the current path
    if (location.pathname === "/AdminDashboard") {
      setActiveLink("dashboard");
    } else if (location.pathname === "/dashboard") {
      setActiveLink("franchise");
    } else if (
      location.pathname === "/products" ||
      location.pathname === "/Createproduct"
    ) {
      setActiveLink("products");
    } else if (location.pathname === "/OrderList") {
      setActiveLink("orders");
    }
  }, [location.pathname]);

  const fetchProductList = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.get(Urls.getProductList, config);
      if (response.data.results.data.length > 0) {
        setProductList(response.data.results.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log("visibility", visibility);

  const productRoute = productList.length > 0 ? "/products" : "/Createproduct";

  console.log("productList.length----->", productList);

  return (
    <div
      className={
        windowWidth > 575
          ? "sidebar"
          : windowWidth <= 575 && visibility
          ? "sidebar"
          : "sidebar_hidden"
      }
    >
      <div className="parent-sidebar">
        <div className="dasboard-log">
          <img src={Logo} alt="" />
        </div>
        <div className="sidebars">
          <Link
            to="/AdminDashboard"
            className={`dashboards ${
              activeLink === "dashboard" ? "active-link" : ""
            }`}
            onClick={() => {
              if (activeLink === "dashboard") {
                window.location.href = "/AdminDashboard";
              }
            }}
          >
            <RiDashboardLine /> Dashboard
          </Link>
          <Link
            to="/dashboard"
            className={`franchise ${
              activeLink === "franchise" ? "active-link" : ""
            }`}
            onClick={() => {
              if (activeLink === "franchise") {
                window.location.href = "/dashboard";
              }
            }}
          >
            {/* <img src={franchise} alt="" /> */}
            <PiTreeBold /> Franchise
          </Link>
          <Link
            to={productRoute}
            className={`franchise ${
              activeLink === "products" ? "active-link" : ""
            }`}
            onClick={() => {
              if (location.pathname === "/products") {
                window.location.href = "/products";
              } else if (location.pathname === "/Createproduct") {
                window.location.href = "/Createproduct";
              }
            }}
          >
            <IoBagRemoveSharp /> Products
          </Link>
          <Link
            to="/OrderList"
            className={`franchise ${
              activeLink === "orders" ? "active-link" : ""
            }`}
            onClick={() => {
              if (activeLink === "orders") {
                window.location.href = "/OrderList";
              }
            }}
          >
            <BsFillBagPlusFill /> Orders
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
