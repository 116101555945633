import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Urls from "../../Urls";
import { Loader } from "../../Components/Loader/Loader";
import Pagination from "../../Components/Pagination/Pagination";

const FranchiseOrderDetail = () => {
  const token = localStorage.getItem("authToken");
  const franchiseId = localStorage.getItem("franchiseId");
  const [franchiseOrders, setFranchiseOrders] = useState([]);
  const [paginatedFranchiseOrders, setPaginatedFranchiseOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTab, setSelectedTab] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchfranchiseOrders();
  }, []);

  useEffect(() => {
    fetchPaginatedfranchiseOrders();
  }, [currentPage]);

  useEffect(() => {
    if (selectedTab === "all" && searchQuery) {
      filterOrdersBySearch(franchiseOrders, searchQuery);
    } else if (selectedTab === "all") {
      filterOrdersBySearch(paginatedFranchiseOrders, searchQuery);
    } else {
      const filtered = franchiseOrders?.filter(
        (order) => order.delivery_status === selectedTab
      );
      filterOrdersBySearch(filtered, searchQuery);
    }
  }, [selectedTab, franchiseOrders, searchQuery]);

  const filterOrdersBySearch = (orders, query) => {
    const filtered = orders?.filter(
      (order) =>
        order.orderID?.toLowerCase().includes(query.toLowerCase()) ||
        order.product_name?.toLowerCase().includes(query.toLowerCase()) ||
        order.delivery_status?.toLowerCase().includes(query.toLowerCase()) ||
        order.order_status?.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredOrders(filtered);
  };

  const handleNavigate = () => {
    window.location.href = "/login";
    window.localStorage.clear();
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchfranchiseOrders = async () => {
    try {
      const response = await axios.get(
        `${Urls.franchiseOrder}?franchise=${franchiseId}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response?.data?.results?.data) {
        setFranchiseOrders(response.data.results.data);
        setTotalPages(response.data.results.total_pages);
      }
      console.log("Francchise orders", response.data.results);
    } catch (error) {
      if (error.response.data.message === "Invalid Token") {
        handleNavigate();
      }
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPaginatedfranchiseOrders = async () => {
    debugger;
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${Urls.franchiseOrder}?page=${currentPage}&franchise=${franchiseId}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response?.data?.results?.data) {
        setPaginatedFranchiseOrders(response.data.results.data);
        setFilteredOrders(response.data.results.data);
      }
      console.log("Francchise orders", response.data.results);
    } catch (error) {
      if (error.response.data.message === "Invalid Token") {
        handleNavigate();
      }
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const formatDate = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);

    const day = dateObject.getDate().toString().padStart(2, "0");
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObject.getFullYear();

    const hours =
      dateObject.getHours() > 12
        ? dateObject.getHours() - 12
        : dateObject.getHours();
    const minutes = dateObject.getMinutes().toString().padStart(2, "0");
    const period = dateObject.getHours() >= 12 ? "PM" : "AM";

    const formattedDateTime = `${day}/${month}/${year}, ${hours}:${minutes} ${period}`;
    return formattedDateTime;
  };

  if (isLoading) {
    return (
      <div className="loading-spinner">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div>
        <div className="parent_oredr_list_screen">
          <div className="order__tabs">
            <p
              style={{
                cursor: "pointer",
                color: selectedTab === "all" ? "#255FE8" : "#000",
                borderBottom:
                  selectedTab === "all" ? "2px solid #255FE8" : "none",
              }}
              onClick={() => setSelectedTab("all")}
            >
              All Orders
            </p>
            <p
              style={{
                cursor: "pointer",
                color: selectedTab === "Order Placed" ? "#255FE8" : "#000",
                borderBottom:
                  selectedTab === "Order Placed" ? "2px solid #255FE8" : "none",
              }}
              onClick={() => setSelectedTab("Order Placed")}
            >
              Pending
            </p>
            <p
              style={{
                cursor: "pointer",
                color: selectedTab === "Approved" ? "#255FE8" : "#000",
                borderBottom:
                  selectedTab === "Approved" ? "2px solid #255FE8" : "none",
              }}
              onClick={() => setSelectedTab("Approved")}
            >
              Approved
            </p>
            <p
              style={{
                cursor: "pointer",
                color: selectedTab === "Cancelled" ? "#255FE8" : "#000",
                borderBottom:
                  selectedTab === "Cancelled" ? "2px solid #255FE8" : "none",
              }}
              onClick={() => setSelectedTab("Cancelled")}
            >
              Cancelled
            </p>
          </div>
          <div className="order__search_filter">
            <div className="left__searchbox">
              <input
                type="text"
                placeholder="Search for order ID, Product Name, order status..etc"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="order__listscreen_table">
            <table
              style={{ width: "100%", textAlign: "left", colorL: "#193B68" }}
            >
              <thead>
                <tr>
                  <th className="table-head">Order ID</th>
                  <th className="table-head">Purchace Date</th>
                  <th className="table-head" style={{ width: "300px" }}>
                    Product Name
                  </th>
                  <th className="table-head" style={{ width: "140px" }}>
                    Qty
                  </th>
                  <th className="table-head">Total Price</th>
                  <th className="table-head">Delivery status</th>
                  <th className="table-head"></th>
                </tr>
              </thead>
              <tbody>
                {filteredOrders.map((order) => (
                  <tr key={order.id}>
                    <td className="table-datas">{order.orderID}</td>
                    <td className="table-datas">{formatDate(order.date)}</td>
                    <td className="table-datas">
                      <div className="order__photo_desc">
                        <div className="oredr__pic">
                          <img
                            style={{
                              width: "59px",
                              height: "72px",
                              objectFit: "contain",
                            }}
                            src={order.product_image}
                            alt=""
                          />
                        </div>
                        <div className="order__desc">
                          <p>{order.product_name}</p>
                        </div>
                      </div>
                    </td>

                    <td className="table-datas" style={{ color: "#255FE8" }}>
                      {order.order_quantity}
                    </td>
                    <td
                      className="table-datas"
                      style={{ color: "#193B68", fontWeight: "500" }}
                    >
                      ₹ {order.price}
                    </td>
                    <td>
                      <span
                        className={
                          order.delivery_status === "Cancelled"
                            ? "order_cancelled"
                            : "order_approved"
                        }
                      >
                        {order.delivery_status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {selectedTab === "all" && !searchQuery && totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FranchiseOrderDetail;
