import React, { useState } from "react";
import "./Question.css";
import { BsChevronDown } from "react-icons/bs";
import { IoIosArrowUp } from "react-icons/io";
import BusinessCard from "./BusinessCard";

// const text = (
//   <p
//     style={{
//       paddingLeft: 24,
//     }}
//   >
//     A dog is a type of domesticated animal. Known for its loyalty and
//     faithfulness, it can be found as a welcome guest in many households across
//     the world.
//   </p>
// );

const items = [
  {
    key: "1",
    label: "How can I join your Franchise program?",
    children:
      " Joining our Franchise program is effortless. Simply make a minimal investment and become the esteemed representative of our company in your ward or locality.",
  },
  {
    key: "2",
    label: "How can I earn with your Franchise program?",
    children:
      "Earning with our Franchise program is simple. As a franchisee, your main task is to register people from your locality onto our user-friendly application. Every time someone from your locality utilizes the app for a service, you receive a percentage of up to 20% of the transaction amount as your earnings.",
  },
  {
    key: "3",
    label:
      "What services are available through the app for the users registered under the Franchisee?",
    children:
      "Once registered, users gain access to a wide range of services including electrical works, plumbing services, and household maintenance. Our app provides convenience and a one-stop solution for various service needs.",
  },
  {
    key: "4",
    label: "How are transactions handled in the app?",
    children:
      "All transactions can be tracked directly through the app, all the cash handling when the technician arrives must be done by the customer directly. This ensures a secure and hassle-free payment process for both the users and the franchisee.",
  },
  {
    key: "5",
    label: "What are the benefits of being a franchisee?",
    children:
      "As a valued franchisee, you enjoy numerous benefits. These include financial rewards with up to 20% earnings from transactions, a user-friendly app for easy registration, convenience for your locality, and the opportunity to build a thriving business with minimal effort and investment.",
  },
];

function Faq({ onRegisterNowClick }) {
  const [activeKey, setActiveKey] = useState(null);

  const handleCollapse = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <div>
      <div className="section " id="about">
        <div className="about__container container">
          <div className="parent-faq">
            <h3>FAQ</h3>
            <div className="parent-questions">
              {items.map((item) => (
                <div key={item.key}>
                  <div
                    className="label question-label"
                    onClick={() => handleCollapse(item.key)}
                  >
                    {item.label}{" "}
                    {activeKey === item.key ? (
                      <IoIosArrowUp style={{ color: "#255FE8" }} />
                    ) : (
                      <BsChevronDown style={{ color: "#255FE8" }} />
                    )}
                  </div>
                  {activeKey === item.key && (
                    <div className="children">{item.children}</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <BusinessCard onRegisterNowClick={onRegisterNowClick} />
    </div>
  );
}

export default Faq;
