import React, { useState } from "react";
import "./Question.css";
import bcard from "./assets/bcard.png";

function BusinessCard({ onRegisterNowClick }) {
  const handleRegisterClick = () => {
    onRegisterNowClick(); // Call the function to open the modal
  };
  return (
    <div>
      <div className="section " id="about">
        <div className="about__container container">
          <div className="b-card-parent">
            <div className="b-card-left">
              <h3 className="w-business2">
                <span className="b-main-text">Become </span> our Franchisee
              </h3>
              <p className="b-smart">Unlock Financial freedom through us</p>
              <div style={{ textAlign: "left" }}>
                <button className="register-now" onClick={handleRegisterClick}>
                  Register Now
                </button>
              </div>
            </div>
            <div className="b-card-right">
              <img src={bcard} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessCard;
