import React, { useState } from "react";
import DashboardHeader from "../../Dashboard/Dashboard/DashboardHeader.js";
import "./Order.css";
import OrderDetails from "./OrderDetails";
import FranchiseSidebar from "../Franchise/FranchiseSidebar";

const FranchiseProducts = () => {
  const [searchQuery, setSearchQuery] = useState("");
  console.log(searchQuery);
  return (
    <div>
      <FranchiseSidebar />
      <DashboardHeader
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <OrderDetails searchQuery={searchQuery} />
    </div>
  );
};

export default FranchiseProducts;
