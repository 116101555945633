import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import axios from "axios";
import * as Urls from "../../Urls";
import { Loader } from "../../Components/Loader/Loader";

const WarningModal = ({
  isOpen,
  onClose,
  franchiseId,
  approveStatus,
  fetchFranchiseList,
  fetchPaginatedFranchiseList,
  franchiseName,
  // searchInput,
  // search,
}) => {
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [franchiseData, setFranchiseData] = useState(null);
  const [availabilityCheckError, setAvailabilityCheckError] = useState("");

  useEffect(() => {
    franchiseId && fetchFranchiseProfile();
  }, [franchiseId]);

  useEffect(() => {
    if (approveStatus === "Rejected") {
      setAvailabilityCheckError("");
    }
  }, [approveStatus]);
  const purpose =
    approveStatus === "refund"
      ? "Refund"
      : approveStatus === "delete"
      ? "Delete"
      : approveStatus === "Approved"
      ? "Approve"
      : approveStatus === "Rejected"
      ? "Reject"
      : "Edit";

  const handleAprove = async () => {
    const requestBody = {
      approve_status: approveStatus,
      login_link: "welota.com/login",
    };

    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.put(
        Urls.franchiseAprove + franchiseId,
        requestBody,
        config
      );
      console.log("Franchise approved successfully", response);
      onClose();
      navigate("/dashboard");
      fetchFranchiseList();
      fetchPaginatedFranchiseList();
    } catch (error) {
      console.log("Error approving franchise:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFranchiseProfile = async () => {
    // setIsLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.get(
        Urls.franchiseProfile + franchiseId,
        config
      );
      if (
        response.data &&
        response.data.results &&
        response.data.results.data
      ) {
        setFranchiseData(response.data.results.data);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
    // finally {
    //   setIsLoading(null);
    // }
  };

  const checkRegisterationAvailability = async () => {
    setIsLoading(true);
    const availabilityCheckUrl =
      franchiseData.district_id &&
      franchiseData.panchayath_id &&
      franchiseData.ward_number
        ? `${Urls.franchiseRegisterationCheck}?dist=${franchiseData.district_id}&panchayath=${franchiseData.panchayath_id}&ward=${franchiseData.ward_number}`
        : franchiseData.district_id &&
          franchiseData.corporation_id &&
          franchiseData.ward_number
        ? `${Urls.franchiseRegisterationCheck}?dist=${franchiseData.district_id}&corporation=${franchiseData.corporation_id}&ward=${franchiseData.ward_number}`
        : `${Urls.franchiseRegisterationCheck}?dist=${franchiseData.district_id}&municipality=${franchiseData.municipality_id}&ward=${franchiseData.ward_number}`;

    try {
      const response = await axios.get(availabilityCheckUrl);
      if (response.data?.results?.message === "Success") {
        handleAprove();
      } else if (response.data?.results?.message === "Fail") {
        setAvailabilityCheckError(response.data.results?.reason);
        setIsLoading(false);
      } else {
        setAvailabilityCheckError("");
      }
      console.log("availabilityCheck:", response);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const handleRefund = async () => {
    const requestBody = {
      reject_status: "Refunded",
    };
    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.put(
        Urls.updateRejectStatus + franchiseId,
        requestBody,
        config
      );
      console.log("Updated Rject status", response);
      fetchFranchiseList();
      fetchPaginatedFranchiseList();
      onClose();
    } catch (error) {
      console.log("Error While updating reject status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteFranchise = async () => {
    try {
      setIsLoading(true);
      if (!token) {
        console.error("No auth token found");
        return;
      }

      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };

      const response = await axios.put(
        Urls.franchiseDelete + franchiseId,
        null,
        config
      );
      console.log("franchise deleted successfully", response);
      fetchFranchiseList();
      fetchPaginatedFranchiseList();
      onClose();
    } catch (error) {
      console.log("Error deleting franchise:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Modal open={isOpen} onOk={onClose} onCancel={onClose} title="">
        <div className="product_creation">
          <div>
            {availabilityCheckError ? (
              <div className="delete_text">
                <h3>{availabilityCheckError}</h3>
              </div>
            ) : (
              <>
                <div className="delete_img">
                  {/* <img src={deleteimg} alt="" /> */}
                </div>
                <div className="delete_text">
                  <h3>Are you sure you want to {purpose}</h3>
                  <p>{franchiseName}</p>
                </div>
              </>
            )}
            {isLoading ? (
              <Loader />
            ) : (
              <div className="cancel_delete">
                {!availabilityCheckError && (
                  <button className="cancel__btn" onClick={onClose}>
                    Cancel
                  </button>
                )}
                <button
                  className="delete__btn"
                  onClick={() => {
                    if (availabilityCheckError) {
                      onClose();
                      setAvailabilityCheckError("");
                    } else if (approveStatus === "refund") {
                      handleRefund();
                    } else if (approveStatus === "delete") {
                      deleteFranchise();
                    } else if (approveStatus === "Approved") {
                      checkRegisterationAvailability();
                    } else {
                      handleAprove();
                    }
                  }}
                >
                  Ok
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WarningModal;
