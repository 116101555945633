import React from "react";
import "./Loader.css";
import { ClipLoader } from "react-spinners";

const Loader = ({ width, height }) => {
  const loaderStyle = {
    width: width,
    height: height,
  };

  return (
    <div className="loader-container">
      <div class="custom-loader" style={loaderStyle}></div>
    </div>
  );
};

// export default Loader;

const clipSpinner = () => {
  return (
    <div className="loading-spinner">
      <ClipLoader color="#300508" />
    </div>
  );
};

export { Loader, clipSpinner };
