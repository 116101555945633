import React, { useState, useEffect } from "react";
import { useAuth } from "../../AuthContext";
import * as Urls from "../../Urls";
import axios from "axios";
import Logo from "./assets/welotaLogo.png";
import { Link } from "react-router-dom";
import Loginimage from "./assets/Logins.png";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import successimg from "./assets/success.png";
import { useLocation } from "react-router-dom";
import { Loader } from "../../Components/Loader/Loader";
import Joi from "joi";

function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const forgot = queryParams.get("step");
  const email = queryParams.get("email");
  const authCode = queryParams.get("auth");

  const { setUserData } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [inputTYpe, setInputType] = useState("");
  const [mobileOrEmail, setMobileOrEmail] = useState("");
  const [validationError, setValidationError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [step, setStep] = useState("login");
  const [loginError, setLoginError] = useState("");
  const [passwordData, setPasswordData] = useState({
    newpassword: "",
    confirmpassword: "",
  });
  const [passwordError, setPasswordError] = useState({
    newpassword: "",
    confirmpassword: "",
  });

  useEffect(() => {
    authCode && checkAuthCode();
  }, [authCode]);

  const schema = Joi.object({
    value: Joi.string()
      .required()
      .custom((value, helper) => {
        if (/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/.test(value)) {
          if (
            !Joi.string()
              .email({ tlds: { allow: false } })
              .validate(value).error
          ) {
            return value;
          }
          return helper.message("Invalid email format");
        } else if (/^\d{10}$/.test(value)) {
          return value;
        } else {
          return helper.message("Invalid format");
        }
      })
      .label("Mobile number / Email")
      .messages({
        "any.required": "Mobile number / Email is required",
      }),
  });

  const newPasswordSchema = Joi.object({
    newpassword: Joi.string().min(8).required().label("Password").messages({
      "string.min": "Password must be at least 8 characters long",
      "any.required": "Password is required",
      "string.empty": "Password is required",
    }),
    confirmpassword: Joi.string()
      .valid(Joi.ref("newpassword"))
      .required()
      .label("Confirm Password")
      .messages({
        "any.only": "Passwords do not match",
        "any.required": "Confirm Password is required",
        "string.empty": "Confirm Password is required",
      }),
  });

  const getResetPasswordLink = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${Urls.forgotPassword}`, {
        email: mobileOrEmail?.trim(),
        link: "welota.com/login?step=newpassword",
        // link: "http://localhost:3000/login?step=newpassword",
      });
      if (
        /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/.test(
          mobileOrEmail
        )
      ) {
        setInputType("email");
      } else if (/^\d{10}$/.test(mobileOrEmail)) {
        setInputType("phone");
      } else {
        setInputType("");
      }
      setStep("passwordReset");
      setValidationError("");
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const resetPassword = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        Urls.resetPassword + "?email=" + email + "&auth=" + authCode,
        {
          new_password: passwordData.newpassword,
          confirm_password: passwordData.confirmpassword,
        }
      );
      console.log("resetPassword", response);
      setStep("successpage");
      setMobileOrEmail("");
      setTimeout(() => {
        setMobileOrEmail("");
        setStep("login");
        const urlWithoutParams = window.location.href.split("?")[0];
        window.history.replaceState(null, null, urlWithoutParams);
      }, 1000);
    } catch (error) {
      alert("something went wrong");
      return undefined;
    } finally {
      setIsLoading(false);
    }
  };

  const checkAuthCode = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        Urls.resetPassword + "?email=" + email + "&auth=" + authCode
      );
      if (response.data.results.message === "Successfully Validated") {
        setStep("newpassword");
      } else {
        alert("Token has Expired");
        setStep("login");
        const urlWithoutParams = window.location.href.split("?")[0];
        window.history.replaceState(null, null, urlWithoutParams);
      }
      console.log("authCode -------->", response);
    } catch (error) {
      alert("something went wrong");
      return undefined;
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = (event) => {
    event.preventDefault();

    const { error } = schema.validate({ value: mobileOrEmail.trim() });

    if (error) {
      setValidationError(error.details[0].message);
      return;
    } else {
      getResetPasswordLink();
    }
  };

  const handleSuccess = (e) => {
    e.preventDefault();

    const { error } = newPasswordSchema.validate(passwordData, {
      abortEarly: false,
    });

    if (error) {
      const newErrors = { newpassword: "", confirmpassword: "" };

      error.details.forEach((detail) => {
        if (detail.context.key) {
          newErrors[detail.context.key] = detail.message;
        }
      });

      setPasswordError(newErrors);
      return;
    } else {
      localStorage.removeItem("authToken");
      resetPassword();
    }
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setLoginError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setLoginError("");
  };

  const handleSubmit = (e) => {
    console.log("handleSubmit", e);
    e.preventDefault();

    const formData = {
      username: username.trim(),
      password: password,
    };
    console.log("Login form data:", formData);
  };

  const handleLogin = async () => {
    if (username !== "" && password !== "") {
      try {
        setIsLoading(true);
        const response = await axios.post(`${Urls.FranchiseeLogin}`, {
          email: username.trim(),
          password: password,
        });
        if (
          response?.data?.results?.message === "Succesfully login  Franchise"
        ) {
          localStorage.setItem("franchiseId", response.data.results.user_id);
          localStorage.setItem("email", response.data.results.email);
          localStorage.setItem(
            "authToken",
            response.data.results.data.auth_token
          );
          localStorage.setItem("username", response.data.results.data.name);
          localStorage.setItem(
            "userType",
            response.data.results.data.user_type
          );
          setUserData(response.data.results.data);
          navigate("/franchiseprofile");
        } else if (
          response?.data?.results?.message === "Succesfully login  Admin"
        ) {
          localStorage.setItem("email", response.data.results.email);
          localStorage.setItem(
            "authToken",
            response.data.results.data.auth_token
          );
          localStorage.setItem("username", response.data.results.data.name);
          localStorage.setItem(
            "userType",
            response.data.results.data.user_type
          );
          setUserData(response.data.results.data);
          navigate("/AdminDashboard");
        } else {
          setLoginError(response.data.results.reason);
        }
      } catch (error) {
        alert("Login failed");
        return undefined;
      } finally {
        setIsLoading(false);
      }
    } else {
      // alert("Fill All Fields");
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setStep("forgot"); // Set step to "forgot" when "Forgot password?" is clicked
  };

  const handleConfirmPassword = (e) => {
    e.preventDefault();
    setStep("newpassword");
  };

  const handleBackToLogin = () => {
    setStep("login"); // Set step back to "login"
  };

  const verificationText =
    inputTYpe === "phone"
      ? `We sent a code to +91 ${mobileOrEmail} Please enter the code to reset your password`
      : `We sent a reset link to ${mobileOrEmail} Please check out your email`;

  return (
    <div>
      <div className="login_parent">
        <div className="l-left">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
          <div className="left-login">
            {step === "login" && ( // Show logins div if step is "login"
              <div className="logins">
                <h3>Login</h3>
                <p className="logindesc">
                  Welcome to WELOTA. Sign in, Let's see what you got today
                  through our financial freedom journey.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="username">
                    <label htmlFor="fname">Email</label> <br />
                    <input
                      type="text"
                      name="fname"
                      placeholder="Email"
                      value={username}
                      onChange={handleUsernameChange}
                      required
                    />
                  </div>
                  <div className="password">
                    <label htmlFor="password">Password</label> <br />
                    <input
                      type="password"
                      name="password"
                      placeholder="Enter password"
                      value={password}
                      onChange={handlePasswordChange}
                      required
                    />
                  </div>
                  {loginError && <p className="error">{loginError}</p>}
                  <div className="forgot">
                    <Link
                      className="forgot-password"
                      onClick={handleForgotPassword}
                    >
                      Forgot password?
                    </Link>
                  </div>
                  {isLoading ? (
                    <Loader width={30} height={30} />
                  ) : (
                    <div className="login-btn">
                      <button onClick={handleLogin}>Login</button>
                    </div>
                  )}
                </form>
              </div>
            )}
            {/* forgot password screen start */}
            {step === "forgot" && ( // Show forgot__password div if step is "forgot"
              <div className="forgot__password">
                <h3>Forgot password</h3>
                <p className="logindesc">
                  No worries, we’ll send you reset instruction to your
                  registered mobile number
                </p>
                <form onSubmit={handleResetPassword}>
                  <div className="mobile_number">
                    <label
                      htmlFor="mobile"
                      style={{
                        color: "#193b68",
                        fontFamily: "poppins",
                        textAlign: "left",
                      }}
                    >
                      Mobile number / Email
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      name="mobile"
                      value={mobileOrEmail}
                      onChange={(e) => setMobileOrEmail(e.target.value)}
                      // required
                    />
                    {validationError && (
                      <div className="error">{validationError}</div>
                    )}
                  </div>
                  {isLoading ? (
                    <Loader width={30} height={30} />
                  ) : (
                    <div className="login-btn">
                      <button>Reset Password</button>
                    </div>
                  )}
                  <div>
                    <button className="backtologin" onClick={handleBackToLogin}>
                      <BsArrowLeft /> Back to login
                    </button>
                  </div>
                </form>
              </div>
            )}
            {/* forgot password screen end */}
            {/* password reset page start */}
            {step === "passwordReset" && ( // Show password_reset_parent div if step is "passwordReset"
              <div className="password_reset_parent">
                <h3>Password reset</h3>
                <p className="logindesc">{verificationText}</p>
                <form>
                  {inputTYpe === "phone" ? (
                    <>
                      <div className="otp_fill">
                        <input
                          type="text"
                          className="otp-input"
                          maxLength="1"
                          autoFocus
                          onKeyUp={(e) => {
                            if (e.target.value.length === 1) {
                              e.target.nextSibling.focus();
                            }
                          }}
                        />
                        <input
                          type="text"
                          className="otp-input"
                          maxLength="1"
                          onKeyUp={(e) => {
                            if (e.target.value.length === 1) {
                              e.target.nextSibling.focus();
                            }
                          }}
                        />
                        <input
                          type="text"
                          className="otp-input"
                          maxLength="1"
                          onKeyUp={(e) => {
                            if (e.target.value.length === 1) {
                              e.target.nextSibling.focus();
                            }
                          }}
                        />
                        <input
                          type="text"
                          className="otp-input"
                          maxLength="1"
                          onKeyUp={(e) => {
                            if (e.target.value.length === 1) {
                              // Optionally, you can trigger the "Continue" button here
                            }
                          }}
                        />
                      </div>
                      <div className="login-btn">
                        <button onClick={handleConfirmPassword}>
                          Continue
                        </button>
                      </div>
                      <div className="resendcode">
                        <p style={{ color: "#848590" }}>
                          Don’t receive the code?{" "}
                          <span
                            style={{
                              color: "#255FE8",
                              textDecoration: "underline",
                            }}
                          >
                            resend code
                          </span>{" "}
                        </p>
                      </div>
                    </>
                  ) : null}
                  <div>
                    <button className="backtologin" onClick={handleBackToLogin}>
                      <BsArrowLeft /> Back to login
                    </button>
                  </div>
                </form>
              </div>
            )}
            {/* password reset page end */}
            {/* new password  start */}
            {step === "newpassword" && (
              <div className="new_password_parent">
                <h3>Set new password</h3>
                <p className="logindesc">
                  Password must be at least 8 characters long
                </p>
                <form onSubmit={handleSuccess}>
                  <div className="new___password">
                    <label htmlFor="newpassword">Password</label>
                    <br />
                    <input
                      type="password"
                      name="newpassword"
                      value={passwordData.newpassword}
                      onChange={(e) => {
                        const newPassword = e.target.value;
                        setPasswordError((prevErrors) => ({
                          ...prevErrors,
                          newpassword:
                            newPassword.length >= 8
                              ? ""
                              : "Password length must be 8 letters",
                        }));
                        setPasswordData((prevData) => ({
                          ...prevData,
                          newpassword: e.target.value,
                        }));
                      }}
                    />
                    {passwordError.newpassword && (
                      <div className="error">{passwordError.newpassword}</div>
                    )}
                  </div>
                  <div className="confirm_password">
                    <label htmlFor="confirmpassword">Confirm Password</label>
                    <br />
                    <input
                      type="password"
                      name="confirmpassword"
                      value={passwordData.confirmpassword}
                      onChange={(e) => {
                        const confirmPassword = e.target.value;
                        setPasswordError((prevErrors) => ({
                          ...prevErrors,
                          confirmpassword:
                            confirmPassword.length >= 8
                              ? ""
                              : "Confirm password length must be 8 letters",
                        }));
                        setPasswordData((prevData) => ({
                          ...prevData,
                          confirmpassword: e.target.value,
                        }));
                      }}
                    />
                    {passwordError.confirmpassword && (
                      <div className="error">
                        {passwordError.confirmpassword}
                      </div>
                    )}
                  </div>

                  <div className="login-btn">
                    <button>Reset password</button>
                  </div>
                  <div>
                    <button className="backtologin" onClick={handleBackToLogin}>
                      <BsArrowLeft /> Back to login
                    </button>
                  </div>
                </form>
              </div>
            )}
            {/* new password  end */}
            {/* success page start */}
            {step === "successpage" && (
              <div className="successpages">
                <img src={successimg} alt="" />
                <div className="success_text">
                  <h3>
                    Your password <br /> has been successfully reseted
                  </h3>
                  <p className="logindesc">
                    Now you can login with your new password
                  </p>
                </div>
              </div>
            )}
            {/* success page end */}
          </div>
        </div>
        <div className="l-right">
          <img src={Loginimage} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Login;
