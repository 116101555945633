//Base Url
// export const baseUrl = "https://werevetest.zinfog.com/";
// export const baseUrl = "https://wereve.zinfog.com/";
export const baseUrl = "https://welotaapi.welota.com/";
//Register
export const register = baseUrl + "register";
//Get Punchayth
export const getPunchayath = baseUrl + "panchayath";
//Get District
export const getDistrict = baseUrl + "district";
//Get Qualification
export const getQualification = baseUrl + "qualification";
//Franchise List
export const franchiseList = baseUrl + "get/franchise/list";
//Franchise Profile
export const franchiseProfile = baseUrl + "profile/franchise/";
//Get Categories
export const getCategories = baseUrl + "product/category";
//Create Product
export const createProduct = baseUrl + "create/product";
//Get Produc tList
export const getProductList = baseUrl + "get/product/list";
//Get Order List Admin
export const getOrderList = baseUrl + "get/order/list";
//Franchisee Login
export const FranchiseeLogin = baseUrl + "login";
//Franchise Aprove
export const franchiseAprove = baseUrl + "approve/franchise/";
//Delete Franchise
export const franchiseDelete = baseUrl + "delete/franchise/";
//Update Reject Status
export const updateRejectStatus = baseUrl + "update/reject/status/franchise/";
// product Buy franchise
export const franchiseBuyOrder = baseUrl + "create/order";
//Order Aprove
export const orderAprove = baseUrl + "order/status/change/";
//Delete Product
export const deleteProduct = baseUrl + "delete/product/";
//Franchise Order List
export const franchiseOrder = baseUrl + "get/order/list";
//Download Cirtificate
export const downloadCirtificate = baseUrl + "pdf/";
//Product detail
export const productDetail = baseUrl + "product/details/";
//Franchise Registration Availability Check
export const franchiseRegisterationCheck = baseUrl + "check/franchise/register";
//Ward List
export const wardList = baseUrl + "ward/list";
//Email Availability Check
export const emailAvailable = baseUrl + "check/franchise/register";
//Phone Availability Check
export const phoneAvailable = baseUrl + "check/franchise/register";
//Forgot Password
export const forgotPassword = baseUrl + "forgotpassword";
//Reset Password
export const resetPassword = baseUrl + "changepassword";
//Update Product
export const updateProduct = baseUrl + "update/product/";
//Activity Log
export const activityLog = baseUrl + "get/activity/";
//Dashboard Graph
export const graph = baseUrl + "get/month/graph";
//Get Municipality
export const getMuncipality = baseUrl + "get/municipality/list?dist=";
//Get Corporation
export const getCorporation = baseUrl + "get/corporation/list?dist=";
//Edit Profile
export const editFranchiseProfile = baseUrl + "edit/franchise/";
//Download Excel
export const excelDownload = baseUrl + "get/order/excel";
