import React, { useState } from "react";
import "./Pagination.css";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const [visiblePages, setVisiblePages] = useState(5); // Number of visible dots

  const getPageNumbers = () => {
    const pageNumbers = [];

    if (totalPages <= visiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const half = Math.floor(visiblePages / 2);
      let start = currentPage - half;
      let end = currentPage + half;

      if (currentPage <= half) {
        start = 1;
        end = visiblePages;
      } else if (currentPage >= totalPages - half) {
        start = totalPages - visiblePages + 1;
        end = totalPages;
      }

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (start > 1) {
        pageNumbers.unshift("..."); // Add ellipsis at the beginning
      }

      if (end < totalPages) {
        pageNumbers.push("..."); // Add ellipsis at the end
      }
    }

    return pageNumbers;
  };

  return (
    <div className="paginations">
      <div className="pagination_btn">
        <button
          className="paginate__btns"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <p className="pagination_numbers">
          {getPageNumbers().map((pageNumber, index) => (
            <span
              key={index}
              className={
                pageNumber === "..."
                  ? "ellipsis"
                  : pageNumber === currentPage
                  ? "active_paginate"
                  : "default_paginate"
              }
              onClick={() =>
                pageNumber !== "..." ? onPageChange(pageNumber) : null
              }
            >
              {pageNumber}
            </span>
          ))}
        </p>
        <button
          className="paginate__btns"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;

// import React, { useState, useEffect } from "react";
// import "./Pagination.css";

// const Pagination = ({
//   currentPage,
//   itemsPerPage,
//   filteredData,
//   onPageChange,
// }) => {
//   const [visiblePages, setVisiblePages] = useState(5);

//   const getPageNumbers = () => {
//     const totalItems = filteredData?.length;
//     const totalPages = Math.ceil(totalItems / itemsPerPage);

//     const pageNumbers = [];

//     if (totalPages <= visiblePages) {
//       for (let i = 1; i <= totalPages; i++) {
//         pageNumbers.push(i);
//       }
//     } else {
//       const half = Math.floor(visiblePages / 2);
//       let start = currentPage - half;
//       let end = currentPage + half;

//       if (currentPage <= half) {
//         start = 1;
//         end = visiblePages;
//       } else if (currentPage >= totalPages - half) {
//         start = totalPages - visiblePages + 1;
//         end = totalPages;
//       }

//       for (let i = start; i <= end; i++) {
//         pageNumbers.push(i);
//       }

//       if (start > 1) {
//         pageNumbers.unshift("..."); // Add ellipsis at the beginning
//       }

//       if (end < totalPages) {
//         pageNumbers.push("..."); // Add ellipsis at the end
//       }
//     }

//     return pageNumbers;
//   };

//   return (
//     <div className="paginations">
//       <div className="pagination_btn">
//         <button
//           className="paginate__btns"
//           onClick={() => onPageChange(currentPage - 1)}
//           disabled={currentPage === 1}
//         >
//           Previous
//         </button>
//         <p className="pagination_numbers">
//           {getPageNumbers().map((pageNumber, index) => (
//             <span
//               key={index}
//               className={
//                 pageNumber === "..."
//                   ? "ellipsis"
//                   : pageNumber === currentPage
//                   ? "active_paginate"
//                   : "default_paginate"
//               }
//               onClick={() =>
//                 pageNumber !== "..." ? onPageChange(pageNumber) : null
//               }
//             >
//               {pageNumber}
//             </span>
//           ))}
//         </p>
//         <button
//           className="paginate__btns"
//           onClick={() => onPageChange(currentPage + 1)}
//           disabled={
//             currentPage === Math.ceil(filteredData?.length / itemsPerPage)
//           }
//         >
//           Next
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Pagination;
