import React, { useState } from "react";
import "./Business.css";
import { Link } from "react-router-dom";
import { businessdata } from "./BusinessData";
import businessimg from "./assets/business.png";

function Business({ onRegisterNowClick }) {
  // modal start
  const handleRegisterNowClick = () => {
    onRegisterNowClick();
  };

  const [items, setitems] = useState(businessdata);
  return (
    <div>
      <div
        className="about section "
        id="business"
        style={{
          background: "#03031e",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div className="shadows"></div>
        <div className="about__container container grid business-about">
          <div className="b-left" style={{ zIndex: "1" }}>
            <h3>Briefing the WELOTA Business Model.</h3>
            <div className="b-linkhead">
              <Link className="b-link1">
                Join a growing network of our business using WELOTA to transform
                your income
              </Link>
            </div>
            <div style={{ textAlign: "left" }}>
              <button className="register-now" onClick={handleRegisterNowClick}>
                Register Now
              </button>
            </div>
          </div>
          <div className="b-right" style={{ zIndex: "1" }}>
            {items.map((item) => (
              <div className="b-cards">
                <div className="b-image">
                  <img src={businessimg} alt="" />
                </div>
                <div className="b-desc">
                  <h3>{item.head}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Business;
