import "./App.css";
import { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import { PaginationProvider } from "./AuthContext";
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";
import Landingpage from "./Components/Landingpage/Landingpage";
import Login from "./Dashboard/Login/Login";
import Dashboard from "./Dashboard/Dashboard/Dashboard";
import Franchise from "./Dashboard/Franchise/Franchise";
import CreateProduct from "./Dashboard/CreateProduct/CreateProduct";
import Products from "./Dashboard/Products/Products";
import FranchiseProfile from "./Franchise/Franchise/FranchiseProfile";
import OrderList from "./Dashboard/OrderList/OrderList";
import FranchiseProducts from "./Franchise/Franchise Products/FranchiseProducts";
import ProductInner from "./Franchise/Franchise Products/ProductInner";
import FranchiseOrder from "./Franchise/FranchiseOrder/FranchiseOrder";
import AdminDashboard from "./Dashboard/Dashboard/AdminDashboard";
import { Loader } from "./Components/Loader/Loader";

function App() {
  const [token, setToken] = useState();
  const { userData } = useAuth();
  const [userType, setUserType] = useState("");
  const [authStatus, setAuthStatus] = useState("pending");

  useEffect(() => {
    const userType = localStorage.getItem("userType");
    const token = localStorage.getItem("authToken");
    if (userType && token) {
      setToken(token);
      setUserType(userType);
      setAuthStatus("authenticated");
    } else {
      setAuthStatus("unauthenticated");
    }
  }, [userData]);

  return (
    <div className="App">
      {authStatus === "pending" ? (
        <div className="loading-spinner">
          <Loader />
        </div>
      ) : (
        <Router>
          <PaginationProvider>
            <Routes>
              <>
                {!userType && !token ? (
                  <>
                    <Route path="/" Component={Landingpage} exact></Route>
                    <Route path="Login" Component={Login} exact></Route>
                  </>
                ) : token && userType === " Admin" ? (
                  <Route
                    path="AdminDashboard"
                    Component={AdminDashboard}
                    exact
                  ></Route>
                ) : (
                  <Route
                    path="franchiseprofile"
                    Component={FranchiseProfile}
                    exact
                  ></Route>
                )}
                <Route
                  path="*"
                  element={
                    token && userType === " Admin" ? (
                      <Navigate to="/AdminDashboard" />
                    ) : userType === " Franchise" ? (
                      <Navigate to="/franchiseprofile" />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
              </>

              {token && userType === " Admin" ? (
                <>
                  <Route
                    path="AdminDashboard"
                    Component={AdminDashboard}
                    exact
                  ></Route>
                  <Route path="Dashboard" Component={Dashboard} exact></Route>
                  <Route path="products" Component={Products} exact></Route>
                  <Route
                    path="Createproduct"
                    Component={CreateProduct}
                    exact
                  ></Route>
                  <Route path="OrderList" Component={OrderList} exact></Route>
                  <Route path="Franchise" Component={Franchise} exact></Route>
                </>
              ) : (
                token &&
                userType === " Franchise" && (
                  <>
                    <Route
                      path="franchiseprofile"
                      Component={FranchiseProfile}
                      exact
                    ></Route>
                    <Route
                      path="franchiseproduct"
                      Component={FranchiseProducts}
                      exact
                    ></Route>
                    <Route
                      path="productinner"
                      Component={ProductInner}
                      exact
                    ></Route>
                    <Route
                      path="franchiseorder"
                      Component={FranchiseOrder}
                      exact
                    ></Route>
                  </>
                )
              )}
            </Routes>
          </PaginationProvider>
        </Router>
      )}
    </div>
  );
}

export default App;
