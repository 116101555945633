import React, { useEffect } from "react";
import Sidebar from "../Dashboard/Sidebar";
import DashboardHeader from "../Dashboard/DashboardHeader";
import FranchiseContent from "./FranchiseContent";
import { useLocation } from "react-router-dom";

const Franchise = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const franchiseId = queryParams.get("franchiseId");
  const orderId = queryParams.get("orderId");
  const orderDate = queryParams.get("orderDate");
  const status = queryParams.get("status");

  return (
    <div>
      <Sidebar />
      <DashboardHeader
        orderId={orderId}
        orderDate={orderDate}
        status={status}
      />
      <FranchiseContent franchiseId={franchiseId} />
    </div>
  );
};

export default Franchise;
