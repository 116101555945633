import React from "react";
import { Modal } from "antd";

const HoverImage = ({ isOpen, onClose, image }) => {
  return (
    <div>
      <Modal open={isOpen} onOk={onClose} onCancel={onClose} title="">
        <div className="hover-image_container">
          <img src={image} style={{ width: "100%" }} />
        </div>
      </Modal>
    </div>
  );
};

export default HoverImage;
