import React from "react";
import FranchiseSidebar from "../Franchise/FranchiseSidebar";
import DashboardHeader from "../../Dashboard/Dashboard/DashboardHeader";
import FranchiseOrderDetail from "./FranchiseOrderDetail";

const FranchiseOrder = () => {
  return (
    <div>
      <FranchiseSidebar />
      <DashboardHeader />
      <FranchiseOrderDetail />
    </div>
  );
};

export default FranchiseOrder;
