import React from "react";
import networkimg from "./assets/network.png";
import "./Network.css";

function Network() {
  return (
    <div>
      <div
        className="about section networkimages"
        id="about"
        style={{ position: "relative", background: "#255FE8", zIndex: "99" }}
      >
        <img className="networkimg" src={networkimg} alt="" />
        <div className="about__container container" style={{ zIndex: 99 }}>
          <h3
            className="growtext"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            What are you waiting for? Join us immediately on the run to earn,
            network and transform your life.
          </h3>
        </div>
      </div>
    </div>
  );
}

export default Network;
