import React, { useState, useEffect } from "react";
import { BiSolidDownload } from "react-icons/bi";
import "./Franchise.css";
import payment from "./assets/payment.png";
import aadhar from "./assets/aadhaar-card.png";
import axios from "axios";
import * as Urls from "../../Urls";
import { Loader } from "../../Components/Loader/Loader";
import { BsCheckLg } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { FaUserEdit } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import WarningModal from "../Dashboard/WarningModal";
import HoverImage from "../Dashboard/HoverImage";
import EditProfileModal from "../../Franchise/Franchise/EditProfileModal";

const FranchiseContent = ({ franchiseId }) => {
  const token = localStorage.getItem("authToken");
  const [franchiseData, setFranchiseData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [aproveStatus, setAproveStatus] = useState("");
  const [franchiseID, setFranchiseID] = useState(null);
  const [franchiseName, setFranchiseName] = useState(null);
  const [imageHover, setImageHover] = useState(null);
  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const location = useLocation();
  // Parse the query parameters from the location search string
  const queryParams = new URLSearchParams(location.search);

  // Access the individual query parameters
  const status = queryParams.get("status");
  const searchQuery = queryParams.get("search");
  const page = queryParams.get("page");

  const fetchFranchiseProfile = async () => {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.get(
        Urls.franchiseProfile + franchiseId,
        config
      );
      if (
        response.data &&
        response.data.results &&
        response.data.results.data
      ) {
        setFranchiseData(response.data.results.data);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setIsLoading(null);
    }
  };

  const editprofilecloseModal = () => {
    setEditProfileModalOpen(false);
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  useEffect(() => {
    if (franchiseId) {
      fetchFranchiseProfile();
    }
  }, [franchiseId]);

  const handleAprove = (status, franchiseName) => {
    setAproveStatus(status);
    setFranchiseName(franchiseName);
    setFranchiseID(franchiseId);
    setShowWarningModal(true);
  };

  const handleCloseWarningModal = () => {
    setShowWarningModal(false);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
  };

  if (isLoading) {
    // Show loading spinner while API call is in progress
    return (
      <div className="loading-spinner">
        <Loader />
      </div>
    );
  }

  console.log("Admin Siede ================franchiseData", franchiseData);

  return (
    <div
      style={{
        background: "#F8F9FB",
        padding: "30px 0px",
        paddingBottom: "50px",
      }}
    >
      <div className="F-deatils">
        <div className="F-datas">
          <div className="F-left">
            <h3>{franchiseData?.username}</h3>
            <div className="F-dob">
              <p style={{ color: "#cfcfcf" }}>DOB</p>
              <p style={{ color: "#193B68" }}>
                {formatDate(franchiseData?.DateOfBirth)}
              </p>
            </div>
            <div className="F-email">
              <p style={{ color: "#cfcfcf" }}>Email</p>
              <p style={{ color: "#193B68" }}>{franchiseData?.email}</p>
            </div>
            <div className="F-address" style={{ width: "360px" }}>
              <p style={{ color: "#cfcfcf" }}>Address </p>
              <p style={{ color: "#193B68" }}>{franchiseData?.address}</p>
            </div>
          </div>
          <div className="F-center">
            <div className="F-sex">
              <p style={{ color: "#cfcfcf" }}>Sex</p>
              <p style={{ color: "#193B68" }}>{franchiseData?.gender}</p>
            </div>
            <div className="F-phone">
              <p style={{ color: "#cfcfcf" }}>Mobile </p>
              <p style={{ color: "#193B68" }}>{franchiseData?.mobile_number}</p>
            </div>
          </div>
          {status === "Pending" && (
            <div className="F-end">
              <BsCheckLg
                className="approved"
                onClick={() => {
                  handleAprove("Approved", franchiseData?.username);
                }}
              />
              <IoMdClose
                className="closed"
                onClick={() => {
                  handleAprove("Rejected", franchiseData?.username);
                }}
              />
            </div>
          )}
          {/* <div
            className="Edit_profile-btn"
            onClick={() => {
              setEditProfileModalOpen(true);
            }}
          >
            Edit Profile
          </div> */}
          {status === "Pending" && (
            <FaUserEdit
              className="Edit_profile-btn"
              onClick={() => {
                setEditProfileModalOpen(true);
              }}
            />
          )}
        </div>
      </div>
      {/* second section start */}
      <div className="parent_address_payement">
        <div className="F-address-payment-main">
          <div className="F-left_address">
            <div className="F-left-inner">
              <h3 className="F-inner-h3">Address and Other</h3>
              <div className="F-qualification">
                <p className="F-A-C">Qualification </p>
                <p style={{ color: "#193B68" }}>
                  {franchiseData?.qualification}
                </p>
              </div>
              <div className="F-panchayath">
                <p className="F-A-C">
                  {franchiseData?.panchayath_name
                    ? "Panchayath"
                    : franchiseData?.corporation_name
                    ? "Corporation"
                    : "Municipality"}
                </p>
                <p style={{ color: "#193B68" }}>
                  {franchiseData?.panchayath_name ||
                    franchiseData?.corporation_name ||
                    franchiseData?.municipality_name}
                </p>
              </div>
              <div className="F-nominee">
                <p className="F-A-C">Nominee Name</p>
                <p style={{ color: "#193B68" }}>
                  {franchiseData?.nominee_name}
                </p>
              </div>
            </div>
            <div className="F-right-inner">
              <div className="F-district">
                <p className="F-A-C">District </p>
                <p style={{ color: "#193B68" }}>
                  {franchiseData?.district_name}
                </p>
              </div>
              <div className="F-ward">
                <p className="F-A-C">Ward </p>
                <p style={{ color: "#193B68" }}>{franchiseData?.ward_number}</p>
              </div>
              <div className="F-relation">
                <p className="F-A-C">Relationship </p>
                <p style={{ color: "#193B68" }}>
                  {franchiseData?.relationship}
                </p>
              </div>
            </div>
          </div>
          <div className="F-right-payment">
            <div className="F-payment-left">
              <h3 className="F-inner-h3">Payment</h3>
              <div className="F-payment-method">
                <p className="F-A-C">Payment method </p>
                <p style={{ color: "#193B68" }}>
                  {franchiseData?.payment_type}
                </p>
              </div>
              {/* <div className="F-upi-id">
                <p className="F-A-C">UPI ID </p>
                <p style={{ color: "#193B68" }}>{franchiseData?.upi_id}</p>
              </div> */}
            </div>
            {franchiseData?.attachment && (
              <div
                className="F-payment-img"
                onClick={() => {
                  setShowImageModal(true);
                  setImageHover(franchiseData?.attachment);
                }}
              >
                <img
                  style={{
                    height: "220px",
                    width: "220px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  src={franchiseData?.attachment}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* second section end */}

      {/* third section start */}
      <div className="parent_adharcard">
        {/* new */}
        <div className="aadharcard_details">
          <div className="aadhar_main_left">
            <h3>Passbook</h3>
            <img
              onClick={() => {
                setShowImageModal(true);
                setImageHover(franchiseData?.bank_passbook_image);
              }}
              style={{
                height: "150px",
                width: "210px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              src={franchiseData?.bank_passbook_image}
              alt=""
            />
          </div>
          <div className="aadhar-main-right">
            <div className="bank-account-numbers">
              <p className="F-A-C">Bank Account Number </p>
              <p style={{ color: "#193B68" }}>
                {franchiseData?.bank_account_number}
              </p>
            </div>
            <div className="bank-account-numbers">
              <p className="F-A-C">IFSC Code </p>
              <p style={{ color: "#193B68" }}>{franchiseData?.IFSC}</p>
            </div>
          </div>
        </div>
        {/* new */}

        <div className="aadharcard_details">
          <div className="aadhar_main_left">
            <h3>Pan Card</h3>
            <img
              onClick={() => {
                setShowImageModal(true);
                setImageHover(franchiseData?.pancard_image);
              }}
              style={{
                height: "150px",
                width: "210px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              src={franchiseData?.pancard_image}
              alt=""
            />
          </div>
          <div className="aadhar-main-right">
            <div className="aadha-card-numbers">
              <p className="F-A-C">Pan Card Number </p>
              <p style={{ color: "#193B68" }}>
                {franchiseData?.pancard_number}
              </p>
            </div>
          </div>
        </div>
        <div className="aadharcard_details">
          <div className="aadhar_main_left">
            <h3>Confidentials</h3>
            <img
              style={{
                height: "150px",
                width: "210px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              src={franchiseData?.adhar_image}
              alt=""
              onClick={() => {
                setShowImageModal(true);
                setImageHover(franchiseData?.adhar_image);
              }}
            />
            {franchiseData?.adhar_imageback !== null ? (
              <img
                style={{
                  height: "150px",
                  width: "210px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                src={franchiseData?.adhar_imageback}
                alt=""
                onClick={() => {
                  setShowImageModal(true);
                  setImageHover(franchiseData?.adhar_imageback);
                }}
              />
            ) : null}
          </div>
          <div className="aadhar-main-right">
            <div className="aadha-card-numbers">
              <p className="F-A-C">Aadhar Card Number </p>
              <p style={{ color: "#193B68" }}>{franchiseData?.adhar_number}</p>
            </div>
            {/* <div className="bank-account-numbers">
              <p className="F-A-C">Bank Account Number </p>
              <p style={{ color: "#193B68" }}>
                {franchiseData?.bank_account_number}
              </p>
            </div> */}
          </div>
        </div>
      </div>
      {/* third section end */}
      <WarningModal
        isOpen={showWarningModal}
        onClose={handleCloseWarningModal}
        approveStatus={aproveStatus}
        franchiseId={franchiseID}
        franchiseName={franchiseName}
        page={page}
        search={searchQuery}
      />
      <HoverImage
        isOpen={showImageModal}
        onClose={handleCloseImageModal}
        image={imageHover}
      />
      <EditProfileModal
        isOpen={editProfileModalOpen}
        onClose={editprofilecloseModal}
        franchiseData={franchiseData}
        franchiseId={franchiseId}
        fetchFranchiseProfile={fetchFranchiseProfile}
      />
    </div>
  );
};

export default FranchiseContent;
