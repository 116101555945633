export const businessdata = [
  {
    head: "Building Network",
    description:
      "After setting up your profile and become a franchisee of your ward. The baby step is to register people from your locality onto our user-friendly application for their daily necessories.",
  },
  {
    head: "How does this work?",
    description:
      "All transactions can be tracked directly through the app, all the cash handling when the technician arrives must be done by the customer directly. This ensures a secure and hassle-free payment process for both the users and the franchisee.",
  },
  {
    head: "How do you earn?",
    description:
      "As a valued franchisee, you earn up to 20% of each transaction made through the app. It's a win-win situation, offering convenience, transparency, and financial rewards.",
  },
];
