import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Urls from "../../Urls";
import "./Order.css";
import { ProductDetails } from "./ProductDetails";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../Components/Loader/Loader";
import Pagination from "../../Components/Pagination/Pagination";

const OrderDetails = ({ searchQuery }) => {
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const [ProductList, setProductList] = useState([]);
  const [items, setItems] = useState(ProductDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedProductList, setPaginatedProductList] = useState([]);
  const [filteredProductList, setFilteredProductList] = useState([]);
  useEffect(() => {
    fetchProductList();
  }, []);

  console.log(searchQuery);

  useEffect(() => {
    fetchPaginatedProductList();
  }, [currentPage]);

  const handleNavigate = () => {
    window.location.href = "/login";
    window.localStorage.clear();
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchPaginatedProductList = async () => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.get(
        Urls.getProductList + "?page=" + currentPage,
        config
      );
      if (response?.data?.results?.status_code === 200) {
        setPaginatedProductList(response.data.results.data);
        setFilteredProductList(response.data.results.data);
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.message === "Invalid Token") {
        handleNavigate();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProductList = async () => {
    try {
      // setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const response = await axios.get(Urls.getProductList, config);
      if (response?.data?.results?.status_code === 200) {
        setProductList(response.data.results.data);
        setTotalPages(response.data.results.total_pages);
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.message === "Invalid Token") {
        handleNavigate();
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleFranchiseClick = (item) => {
    if (item.stock_status === "In Stock") {
      navigate("/productinner", { state: item });
    }
  };

  useEffect(() => {
    if (searchQuery) {
      setFilteredProductList(
        ProductList.filter((item) =>
          item.product_name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    } else {
      fetchPaginatedProductList();
    }
  }, [searchQuery]);

  // const filteredProductList = ProductList.filter((item) =>
  //   item.product_name.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  if (isLoading) {
    return (
      <div className="loading-spinner">
        <Loader />
      </div>
    );
  }

  return (
    <div
      style={{
        background: "#F8F9FB",
        padding: "30px 0px",
        paddingBottom: "50px",
      }}
    >
      <div className="order-details-parent">
        <div className="order-details">
          {filteredProductList.map((item) => (
            <div
              className="order-card"
              onClick={() => handleFranchiseClick(item)}
            >
              <div className="P-image" style={{ position: "relative" }}>
                <img
                  style={{
                    height: "134px",
                    width: "100%",
                    borderRadius: "3px",
                    objectFit: "contain",
                  }}
                  src={item.product_image}
                  alt=""
                />
                {item.stock_status === "Out of Stock" ? (
                  <p className="out_of_stock">Out of Stock</p>
                ) : null}
              </div>
              <div className="P-contents">
                <p>{item.product_name}</p>
                <h3>₹ {item.price}</h3>
              </div>
            </div>
          ))}
        </div>
        {!searchQuery && totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
